import { PropsWithChildren, useEffect, useState } from 'react';

import { checkPermission } from './checkPermission';
import { Conjunction } from './domain';
import { useMeInformationQuery } from './useMeInformationQuery';

type Props = {
    neededPermissions: string[];
    logicalConnective?: Conjunction;
};

const HasPermission = ({
    neededPermissions,
    children,
    logicalConnective = 'Disjunction',
}: PropsWithChildren<Props & { children: JSX.Element }>) => {
    const [canAccess, setCanAccess] = useState<boolean>(false);
    const { data: meInformation } = useMeInformationQuery();
    useEffect(() => {
        setCanAccess(checkPermission(meInformation?.permissions, neededPermissions, logicalConnective));
    }, [meInformation?.permissions]);

    return canAccess ? children : null;
};

export { HasPermission };
