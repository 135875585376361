import { permissions } from '@features/authorization';

type NavItemBase = {
    id: string;
    name: string;
    url?: string;
    disabled?: boolean;
    testid?: string;
};

export type NavItem = NavItemBase & {
    children?: Array<{
        title: string;
        links: NavItemBase[];
    }>;
};

export const getMenuItems = () => [
    {
        dataTestId: 'genaiUseCases',
        name: 'header.genaiUseCases',
        to: '/genai-use-cases',
        permission: permissions.genAi.allInitiatives.view,
    },
    {
        dataTestId: 'getInspired',
        name: 'header.getInspired',
        to: '/get-inspired',
        permission: permissions.getInspired.allBlogs.view,
    },
];
