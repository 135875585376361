import { useEffect, useMemo, useState } from 'react';

import { useController, useFormContext } from 'react-hook-form';
import Select, { MultiValue, OptionProps, StylesConfig, components } from 'react-select';
import makeAnimated from 'react-select/animated';

import { ReactComponent as CheckboxChecked } from '@assets/icons/checkboxChecked.svg';
import { ReactComponent as CheckboxMinus } from '@assets/icons/checkboxMinus.svg';
import { ReactComponent as CheckboxSquare } from '@assets/icons/checkboxSquare.svg';
import {
    accessibleActiveColor,
    blackColor,
    blue100Color,
    blue400Color,
    greyColor,
    primaryColor,
} from '@shared/constants';
import './custom-multiselect.css';

const animatedComponents = makeAnimated();

type Items = {
    id: string;
    name: string;
};

type ItemsWithGrouping = Items & {
    group: string | undefined;
};

type Props = {
    items: Items[] | ItemsWithGrouping[];
    name: string;
    selectedItems?: string[];
    includeCheckboxes?: boolean;
};

type CustomOptions = {
    label: string;
    value: string;
    isSelected?: boolean;
};

type OptionsWithGrouping = {
    label: string;
    options: CustomOptions[];
    isSelected?: boolean | undefined;
};

const formatSelectedOptions = (items: Items[], selectedItems?: string[]) =>
    selectedItems
        ?.map(item => {
            const selectedPartner = items.find(f => f.id === item);
            if (selectedPartner) {
                return {
                    value: selectedPartner.id,
                    label: selectedPartner.name,
                    isSelected: true,
                };
            }
            return undefined;
        })
        .filter(item => item !== undefined);

function organizeItemsByGroup(items: ItemsWithGrouping[]): Record<string, ItemsWithGrouping[]> {
    let groupKeys: string[] = [];
    const organisedList = items.reduce((acc, item) => {
        const groupKey = item.group === null || item.group === undefined ? 'ungrouped' : `${item.group}`;
        groupKeys.push(groupKey);
        if (!acc[groupKey]) {
            acc[groupKey] = [];
        }
        (acc[groupKey] || []).push(item); // Check again if needed
        return acc;
    }, {} as Record<string, ItemsWithGrouping[]>);

    //Filter grop key partners from the ungrouped lst
    groupKeys = [...new Set(groupKeys)];
    if (organisedList['ungrouped'] && groupKeys.length > 0) {
        organisedList['ungrouped'] = organisedList['ungrouped']?.filter(item => !groupKeys.includes(item.name));
    }
    return organisedList;
}

const createGroup = (groupName: string, options: ItemsWithGrouping[]): OptionsWithGrouping | CustomOptions[] => {
    if (groupName !== 'ungrouped') {
        return {
            label: groupName,
            isSelected: false,
            options: options.map(item => ({
                value: item.id,
                label: item.name,
                isSelected: false,
            })),
        };
    }

    return options.map(item => ({
        value: item.id,
        label: item.name,
        isSelected: false,
    }));
};

function formatOptions(items: Items[] | ItemsWithGrouping[]): (CustomOptions | OptionsWithGrouping)[] {
    const organizedItems = organizeItemsByGroup(items as ItemsWithGrouping[]);
    const options: (CustomOptions | OptionsWithGrouping)[] = [];
    Object.keys(organizedItems).forEach(group => {
        const results = createGroup(group, organizedItems[group]!);
        if (Array.isArray(results)) {
            options.push(...results);
        } else {
            options.push(results);
        }
    });

    options.sort((optionA, optionB) => {
        const labelA = (optionA as any).label?.toLowerCase() ?? '';
        const labelB = (optionB as any).label?.toLowerCase() ?? '';
        return labelA.localeCompare(labelB);
    });

    return options;
}

const MultiSelectForm = ({ name, items, selectedItems, includeCheckboxes }: Props) => {
    const [selectedOption, setSelectedOption] = useState<CustomOptions[]>();
    const { control } = useFormContext();
    const { field } = useController({
        name: name,
        control,
    });

    useEffect(() => {
        setSelectedOption(formatSelectedOptions(items, selectedItems) as CustomOptions[]);

        field.onChange(selectedItems);
    }, [selectedItems]);

    const onChangeHandler = (selectedValues: MultiValue<CustomOptions>) => {
        selectedValues.map(x => (x.isSelected = true));
        const formatSelectedValues = selectedValues.map(value => value?.value);
        field.onChange(formatSelectedValues);
        setSelectedOption(selectedValues as CustomOptions[]);
    };

    const onGroupChangeHandler = (data: OptionsWithGrouping) => {
        const lastState = data.isSelected;
        if (lastState === true || lastState === undefined) {
            data.isSelected = false;
            data.options.forEach(x => {
                x.isSelected = false;
            });
        } else {
            data.isSelected = true;
            data.options.forEach(x => {
                x.isSelected = true;
            });
        }

        const finalSelectedOptions = data.isSelected
            ? selectedOption?.concat(data.options) ?? data.options
            : selectedOption?.filter(item => !data.options.some(x => x.value === item.value));

        const formatSelectedValues = finalSelectedOptions?.map(value => value?.value);
        field.onChange(formatSelectedValues);
        setSelectedOption(finalSelectedOptions as CustomOptions[]);
    };

    const GroupHeading = (props: any) => {
        const group = props.data;
        group.isSelected = false;
        if (group?.options && selectedOption) {
            const noOptions = group.options.length;
            if (selectedOption.filter((x: CustomOptions) => x.label.includes(group.label)).length === noOptions) {
                group.isSelected = true;
            } else if (selectedOption.filter((x: CustomOptions) => x.label.includes(group.label)).length > 0) {
                group.isSelected = undefined;
            }
        }

        return (
            <div className="inline-flex items-center ">
                <components.GroupHeading {...props}>
                    <div
                        style={{ cursor: 'pointer', display: 'inline-flex', textTransform: 'none' }}
                        onClick={() => onGroupChangeHandler(props.data)}
                    >
                        {props.data.isSelected && (
                            <CheckboxChecked className="checkbox-icon" fill={accessibleActiveColor} />
                        )}
                        {props.data.isSelected === false && (
                            <CheckboxSquare className="checkbox-icon" fill={greyColor} />
                        )}
                        {props.data.isSelected === undefined && (
                            <CheckboxMinus className="checkbox-icon" fill={accessibleActiveColor} />
                        )}
                        <span className="pl-1"> {props.children}</span>
                    </div>
                </components.GroupHeading>
            </div>
        );
    };

    const Option = (props: OptionProps<CustomOptions>) => {
        const onClickHandler = (props: OptionProps<CustomOptions>, isSelected: boolean) => {
            const option = props.data;
            option.isSelected = isSelected;
            const group = (props.options as OptionsWithGrouping[]).find(
                x => option.label.includes(x.label ?? '') && x.options
            );
            if (group) {
                const noOptions = group.options.length;

                if (group.options.filter(x => x.isSelected === isSelected).length === noOptions) {
                    group.isSelected = isSelected;
                } else {
                    group.isSelected = undefined;
                }
            }
        };
        return (
            <div className="react-select-option-container items-center">
                <components.Option {...props}>
                    {props.isSelected && (
                        <CheckboxChecked
                            className="checkbox-icon"
                            fill={accessibleActiveColor}
                            onClick={() => onClickHandler(props, !props.isSelected)}
                        />
                    )}
                    {!props.isSelected && (
                        <CheckboxSquare
                            className="checkbox-icon"
                            fill={greyColor}
                            onClick={() => onClickHandler(props, !props.isSelected)}
                        />
                    )}
                    <span className="pl-1"> {props.children}</span>
                </components.Option>
            </div>
        );
    };

    const formattedOptions = useMemo(() => formatOptions(items), [items]);

    const colourStyles: StylesConfig<CustomOptions, true> = {
        option: styles => {
            return {
                ...styles,
                backgroundColor: undefined,
                color: undefined,
            };
        },
    };

    return (
        <Select
            {...field}
            styles={colourStyles}
            options={formattedOptions}
            id={`${name}Select`}
            menuPosition="fixed"
            value={selectedOption}
            components={includeCheckboxes ? { GroupHeading, Option } : animatedComponents}
            isMulti
            className="react-select-container custom-multiselect-container"
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={onChangeHandler}
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: accessibleActiveColor,
                    primary25: blue100Color,
                    neutral10: blue100Color,
                    neutral20: blue400Color,
                    neutral30: primaryColor,
                    danger: blackColor,
                    dangerLight: blue400Color,
                },
            })}
        />
    );
};

export { MultiSelectForm };
