import { useTranslation } from 'react-i18next';

import { ReactComponent as ErrorImage } from '@assets/images/contentError.svg';

const ContentError = () => {
    const { t } = useTranslation();

    return (
        <div className="flex h-full w-full flex-col items-center justify-center gap-4">
            <ErrorImage data-testid="error-content-image" />
            <span className="text-xs font-light">{t('errors.content')}</span>
        </div>
    );
};

export { ContentError };
