type Props = {
    active: boolean;
    children: string;
};

const SummaryTab = ({ children, active }: Props) => {
    return (
        <div className=" text-lg mb-4 cursor-pointer  pr-4 ">
            {active ? (
                <div className=" border-b-2 border-blue-850 px-2 text-blue-850">{children}</div>
            ) : (
                <div className="border-b px-2">{children}</div>
            )}
        </div>
    );
};

export { SummaryTab };
