import { LogLevel } from '@azure/msal-browser';

import { isEdge, isFirefox, isIE, isSafari } from '@shared/constants';

export type AuthSettings = {
    clientId: string;
    authority: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    scopes: string[];
};

export type ProductCatalogSettings = {
    productCatalogUrl: string;
};

export type ServiceNowSettings = {
    serviceNowUrl: string;
    serviceNowSupportUrl: string;
};

export type ApplicationInsightsSettings = {
    applicationInsightsConnectionString: string;
};

export type AppSettings = {
    cacheTime: number;
};

export type Settings = AuthSettings &
    AppSettings &
    ServiceNowSettings &
    ProductCatalogSettings &
    ApplicationInsightsSettings;

export const msalSettings = (settings: AuthSettings) => {
    return {
        auth: {
            clientId: settings.clientId || import.meta.env.VITE_CLIENT_ID!,
            authority: settings.authority || import.meta.env.VITE_AUTHORITY!,
            redirectUri: settings.redirectUri || import.meta.env.VITE_REDIRECT_URI!,
            postLogoutRedirectUri: settings.postLogoutRedirectUri || import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI!,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE || isEdge || isFirefox || isSafari,
        },
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    };
};

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
