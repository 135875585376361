import classNames from 'classnames';

import { CellProps } from '../domain';

const ActionsCell = <T extends object>({ actions, context }: CellProps<T>) => {
    if (!actions || actions.length === 0) {
        return <></>;
    }

    return (
        <div className="text-right xl:max-h-6">
            {actions
                .filter(({ hide, rowAccessorKey }) => {
                    const id: string | number = context.row.getValue(rowAccessorKey);
                    return !hide || !hide(id);
                })
                .map(({ icon, onClick, rowAccessorKey, active }, i) => {
                    const id: string | number = context.row.getValue(rowAccessorKey);
                    const isActive = active && active(id);

                    return (
                        <button
                            className={classNames('mr-2 inline-block rounded-full', {
                                active: isActive,
                            })}
                            type="button"
                            onClick={() => onClick(id)}
                            key={i}
                        >
                            {icon}
                        </button>
                    );
                })}
        </div>
    );
};

export { ActionsCell };
