import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Spinner } from '../spinner';

import { Button } from './Button';

import { ReactComponent as CancelIcon } from '@assets/icons/cancelAction.svg';
import { namespaces } from '@shared/constants';

type ActionButtonProps = {
    onClickAction: () => void | Promise<void>;
    state?: 'idle' | 'loading';
};

export const CancelButton = ({ onClickAction, state }: ActionButtonProps) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    return (
        <Button
            className={classNames(
                'flex w-full items-center justify-center bg-blue-900  py-[7px] text-center text-white transition-all',
                { 'opacity-60': state === 'idle' }
            )}
            onClick={onClickAction}
            disabled={state === 'idle'}
            icon={state === 'loading' ? undefined : CancelIcon}
        >
            {state === 'loading' && <Spinner size={2}></Spinner>}
            {t('coInnovationFunds.validationFundsForm.cancel')}
        </Button>
    );
};
