import { z } from 'zod';

import { ValueType } from '@shared/formatting';

export const getSections = (newRequestUrl?: string) => ({
    summary: {
        name: 'coInnovationFunds.navigation.summary',
        subtitle: 'coInnovationFunds.summary.subtitle',
        url: '/co-innovation-funds',
    },
    fundsManagement: {
        name: 'coInnovationFunds.navigation.fundsManagement',
        subtitle: 'coInnovationFunds.fundsManagement.subtitle',
        url: '/co-innovation-funds/funds-management',
    },
    detail: {
        name: 'coInnovationFunds.navigation.detail',
        subtitle: 'coInnovationFunds.detail.subtitle',
        url: '/co-innovation-funds/details',
    },
    priorities: {
        name: 'coInnovationFunds.navigation.priorities',
        subtitle: 'coInnovationFunds.priorities.subtitle',
        url: '/co-innovation-funds/priorities',
    },
    requests: { name: 'coInnovationFunds.navigation.requests', subtitle: '', url: '/co-innovation-funds/requests' },
    newRequest: { name: 'coInnovationFunds.navigation.newRequest', subtitle: '', url: newRequestUrl },
});

export const getHorizonNames = () => ({
    H1: 'Horizon 1',
    H2: 'Horizon 2',
    H3: 'Horizon 3',
    Unknown: 'Unknown',
});

export const getGlobalTeamNames = () => ({
    TechStream: 'Tech Stream',
    FunctionalTechStream: 'Functional Tech Streams',
    OCIO: 'O-CIO',
    RegionMarkets: 'Region & Markets',
    Unknown: 'Unknown',
});

export enum HorizonTypes {
    Unknown = -1,
    H1 = 0,
    H2 = 1,
    H3 = 2,
}

export enum GlobalTeamTypes {
    Unknown = -1,
    TechStream = 0,
    FunctionalTechStream = 1,
    OCIO = 2,
    RegionMarkets = 3,
}

export enum PhaseTypes {
    Planning = 0,
    Executing = 1,
    Closing = 2,
}

export enum SummaryType {
    Money = 1,
}

export const toValueType = (summaryType: SummaryType): ValueType => {
    return summaryType as unknown as ValueType;
};

export const getTranslationKeyForUnitsOf = (summaryType: SummaryType): string => {
    switch (summaryType) {
        case SummaryType.Money:
            return 'CHF';
        default:
            throw new Error('Unknown SummaryType');
    }
};

export const detailByGlobalTeam = z.array(
    z.object({
        teamName: z.string().min(1),
        import: z.number().min(0),
        percent: z.number().min(0),
    })
);

export const detailByItTeam = z.array(
    z.object({
        teamName: z.string().min(1),
        import: z.number().min(1),
        percent: z.number().min(1),
    })
);

export type MultivalueChart = {
    value: number;
    text: string;
    tooltip: string;
    color: string;
    legend: string;
};

export type MultivalueChartWithPercent = MultivalueChart & {
    percent: number;
};

export type DetailByGlobalTeam = z.infer<typeof detailByGlobalTeam>;
