import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChangeRequestDetailFooter } from './ChangeRequestDetailFooter';
import { fundStatuses } from './constants';
import { FundChangeRequestDetailDialog } from './FundChangeRequestDetailDialog';
import FundDetailComment from './FundDetailComment';
import { FundDetailDialog } from './FundDetailDialog';
import { FundDetailFooter } from './FundDetailFooter';
import { FundsDetailHeader } from './FundDetailHeader';
import { FundDetailSection } from './FundDetailSection';
import FundStatusFlow from './FundDetailStatusFlow';
import { FundKpis } from './FundKpis';
import { SelectedFundState } from './Funds';
import { useGetFundByIdQuery } from './useGetFundByIdQuery';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { namespaces } from '@shared/constants';

const canViewFundDetailPermissions = [permissions.coInnovationFunds.fundsManagement.view];
type DialogTypes =
    | 'cancel'
    | 'details'
    | 'approve'
    | 'reject'
    | 'changeRequest'
    | 'approveCR'
    | 'rejectCR'
    | 'cancelCR';

export const FundDetail = () => {
    const { fundId } = useParams<{ fundId?: string }>();
    const { data } = useGetFundByIdQuery(fundId ?? '');
    const [selectedFundState, setSelectedFundState] = useState<SelectedFundState>();
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const [initialPage, setInitialPage] = useState();
    const sections = [
        {
            title: t('coInnovationFunds.fundsManagement.partner'),
            value: data?.partner?.name ?? 'N/A',
            titleTestId: 'partner-title',
            valueTestId: 'partner-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.providedFunds'),
            value: `${data?.provided ?? 'N/A'} CHF`,
            titleTestId: 'providedFunds-title',
            valueTestId: 'providedFunds-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.carryForward'),
            value: `${data?.carryForward ?? 'N/A'} CHF`,
            titleTestId: 'carryForward-title',
            valueTestId: 'carryForward-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.expired'),
            value: `${data?.expired ?? 'N/A'} CHF`,
            titleTestId: 'expired-title',
            valueTestId: 'expired-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.beginDate'),
            value: data?.beginDate ?? 'N/A',
            titleTestId: 'beginDate-title',
            valueTestId: 'beginDate-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.consumptionExpirationDate'),
            value: data?.consumptionExpirationDate ?? 'N/A',
            titleTestId: 'consumptionDeadline-title',
            valueTestId: 'consumptionDeadline-value',
        },
    ];

    const handleActionClick = (actionType: DialogTypes) => {
        setSelectedFundState(previousState => {
            const id = previousState?.id || data?.id || '';
            const types = previousState?.types.includes(actionType)
                ? [...previousState.types]
                : [...(previousState?.types || []), actionType];

            return { id, types };
        });
    };

    const onFundValidationClose = useCallback(
        () =>
            setSelectedFundState(previousState => ({
                id: data?.id || '',
                types: previousState?.types?.filter(type => type !== 'approve' && type !== 'reject') || [],
            })),
        [setSelectedFundState, data?.id]
    );

    const onFundCRValidationClose = useCallback(
        () =>
            setSelectedFundState(previousState => ({
                id: data?.id || '',
                types: previousState?.types?.filter(type => type !== 'approveCR' && type !== 'rejectCR') || [],
            })),
        [setSelectedFundState, data?.id]
    );

    useEffect(() => {
        const savedPagination = sessionStorage.getItem('fundTablePagination');
        if (savedPagination) {
            const paginationObject = JSON.parse(savedPagination);
            const pageNumber = paginationObject.page;
            setInitialPage(pageNumber);
        }
    }, []);

    return (
        <HasPermission neededPermissions={canViewFundDetailPermissions}>
            <div className="mb-20  flex w-full flex-col" data-testid="fund-detail">
                <FundsDetailHeader
                    navigateBackPath={`/co-innovation-funds/funds-management?page=${initialPage}`}
                    submissionDate={data?.submissionDate ?? ''}
                />
                <div className="p-4">
                    <FundKpis sections={sections} />
                </div>
                <div className="flex flex-grow flex-col gap-2 overflow-hidden md:flex-row md:gap-4">
                    {data && (
                        <FundDetailSection title={t('coInnovationFunds.fundsManagement.statusFlow')}>
                            <FundStatusFlow data={data} />
                        </FundDetailSection>
                    )}
                    <FundDetailSection title={t('coInnovationFunds.fundsManagement.comments')}>
                        <div className=" max-h-fit overflow-auto">
                            {data?.submissionComment && (
                                <FundDetailComment
                                    key={data.submissionDate}
                                    name={data.submittedByContact.name}
                                    text={data.submissionComment.text}
                                    statusChange={data.statusId}
                                />
                            )}
                            {data?.validationComment && data.validatedByContact && (
                                <FundDetailComment
                                    key={data.validationDate}
                                    name={data.validatedByContact.name}
                                    text={data.validationComment.text}
                                    statusChange={data.statusId}
                                />
                            )}

                            {data?.changeRequests?.map(x => (
                                <div key={x.submissionDate}>
                                    {x.comment && (
                                        <FundDetailComment
                                            key={x.submissionDate}
                                            name={x.submittedByContact?.name}
                                            text={x.comment}
                                            statusChange={data.statusId}
                                        />
                                    )}
                                    {x.submissionComment && (
                                        <FundDetailComment
                                            key={x.creationDate}
                                            name={x.submittedByContact?.name}
                                            text={x.submissionComment}
                                            statusChange={data.statusId}
                                        />
                                    )}
                                    {x.validationComment && (
                                        <FundDetailComment
                                            key={x.validationDate}
                                            name={x.submittedByContact?.name}
                                            text={x.validationComment}
                                            statusChange={data.statusId}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </FundDetailSection>
                </div>
                {data?.statusId === fundStatuses.pending ? (
                    <>
                        <FundDetailFooter statusId={data?.statusId} handleActionClick={handleActionClick} />
                        <FundDetailDialog
                            selectedFundState={selectedFundState}
                            setSelectedFundState={setSelectedFundState}
                            data={data}
                            onClose={onFundValidationClose}
                        />
                    </>
                ) : null}

                {data?.statusId === fundStatuses.changeRequestPending ? (
                    <>
                        <ChangeRequestDetailFooter statusId={data?.statusId} handleActionClick={handleActionClick} />
                        <FundChangeRequestDetailDialog
                            selectedFundState={selectedFundState}
                            setSelectedFundState={setSelectedFundState}
                            data={data}
                            onClose={onFundCRValidationClose}
                        />
                    </>
                ) : null}
            </div>
        </HasPermission>
    );
};
