import { useTranslation } from 'react-i18next';

import { useGetPartnerPrioritiesQuery } from './useGetPartnerPrioritiesQuery';

import { namespaces } from '@shared/constants';

type Props = {
    partnerId: string;
};

export const PartnerDetails = ({ partnerId }: Props) => {
    const data = useGetPartnerPrioritiesQuery(partnerId);
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    return (
        <article className="flex max-w-4xl flex-col">
            <h1 className="mb-4 text-2xl font-bold">{t('coInnovationFunds.priorities.details.sponsorsSubtitle')}</h1>
            {data ? (
                <div className="flex flex-col">
                    {data.sponsors.map(x => (
                        <span key={x.name}>
                            <span className="font-bold">{x.title}</span> : {x.name}
                        </span>
                    ))}
                </div>
            ) : null}
            <h1 className="my-4 text-2xl font-bold">{t('coInnovationFunds.priorities.details.contractSubtitle')}</h1>
            {data ? (
                <ul className="ml-6 list-disc">
                    {data.contractDetails.map(x => (
                        <li key={x}>{x}</li>
                    ))}
                </ul>
            ) : null}
            <h1 className="my-4 text-2xl font-bold">
                {t('coInnovationFunds.priorities.details.priorityAreasSubtitle')}
            </h1>
            {data ? (
                <ul className="ml-6 list-disc">
                    {data.priorityAreas.map(x => (
                        <li key={x}>{x}</li>
                    ))}
                </ul>
            ) : null}
        </article>
    );
};
