import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';

type Props = {
    id?: number | undefined;
    name?: string | undefined;
    onRemove?: (id?: number) => void;
};

const EventLabel = ({ id, name, onRemove }: Props) => {
    return (
        <div className="mr-2 mb-2 inline-flex items-center justify-center rounded-2xl border border-blue-100 bg-blue-50 px-3 py-1">
            <span className="text-sm font-normal">{name}</span>

            {onRemove ? (
                <button className="inline-flex items-center" onClick={() => onRemove(id)}>
                    <i className="ml-2 inline-block">
                        <CloseIcon className="h-3 w-3 text-blue-900" />
                    </i>
                </button>
            ) : null}
        </div>
    );
};

export { EventLabel };
