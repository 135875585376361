import { ReactElement } from 'react';

import classNames from 'classnames';

type Props = {
    testid: string;
    name: string;
    index: number;
    openTab: number;
    icon?: ReactElement;
    setOpenTab: (index: number) => void;
};

const Tab = ({ testid, name, icon, index, openTab, setOpenTab }: Props) => (
    <li
        className={classNames('-mb-px mr-2 text-center text-black last:mr-0', {
            'border-b border-accessible-active text-accessible-active': openTab === index,
        })}
    >
        <a
            className={'block bg-white px-5 py-3 text-xs leading-normal'}
            onClick={event => {
                event.preventDefault();
                setOpenTab(index);
            }}
            data-testid={testid}
            data-toggle="tab"
            href={`#link${index}`}
            role="tab"
        >
            <div className="flex justify-between">
                <span className="self-center">{icon}</span>
                <span className="px-3">{name}</span>
            </div>
        </a>
    </li>
);

export { Tab };
