import { ReactElement, ReactNode } from 'react';

type Props = {
    element: {
        name: string;
        content: ReactNode;
        icon?: ReactElement;
    };
    openTab: number;
};

export const TabContent = ({ element, openTab }: Props) => (
    <div className={'h-full w-full'} id={`link${openTab}`}>
        {element.content}
    </div>
);
