import { NavItem } from './domain';

import { CMSMainMenuResponse } from '@shared/CMS';

export const getNavItems = (cmsData?: CMSMainMenuResponse | null): NavItem[] => {
    const navItems: NavItem[] = [];

    const innovationServices = cmsData?.innovationServices;
    if (innovationServices && innovationServices.subItems && innovationServices.subItems.length > 0) {
        navItems.push({
            url: '/',
            name: innovationServices.title,
            id: 'innovationServicesLink',
            testid: 'navigation-innovation-services',
            children: innovationServices.subItems.map(({ links, title }, indexParent) => ({
                title,
                links: links.map((link, index) => ({
                    ...link,
                    id: `innovationServicesSubitem-${2 * indexParent + index}`,
                    testid: `innovationServicesSubitem-${2 * indexParent + index}`,
                })),
            })),
        });
    }

    return navItems;
};
