import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { useResizeMediaQuery } from '@features/layout';
import { black800Color, blackColor, blue50Color, textFonts } from '@shared/constants';
import { FormatNumberOptions, formatNumber } from '@shared/formatting';

type Props = {
    data: ChartData<'bar', number[], string>;
    formatNumberOptions: FormatNumberOptions;
    extraDataObject: Record<string, { percentage?: number; valueReplacement?: string }>;
    isDownloading?: boolean;
};

const BarChart = ({ data, formatNumberOptions, extraDataObject, isDownloading }: Props) => {
    const { isMobile } = useResizeMediaQuery();

    return (
        <Bar
            options={{
                plugins: {
                    tooltip: {
                        xAlign: 'center',
                        displayColors: false,
                        bodyColor: blackColor,
                        titleColor: black800Color,
                        backgroundColor: blue50Color,
                        titleFont: {
                            size: 13,
                        },
                        bodyFont: {
                            size: 10,
                        },
                        callbacks: {
                            title: function (items) {
                                return items[0]?.dataset?.label;
                            },
                            label: function (tooltipItem) {
                                const dataset = tooltipItem.dataset;
                                const data = dataset.data[0];
                                const label = dataset.label;

                                const extraData = label ? extraDataObject[label] : undefined;
                                const percentage = extraData?.percentage ? `(${extraData.percentage}%)` : '';

                                let value = extraData?.valueReplacement;

                                if (!value) {
                                    value = data ? formatNumber(+data, formatNumberOptions) : '';
                                }

                                return `${value} ${percentage}`;
                            },
                        },
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    },
                },
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                        display: false,
                        border: {
                            display: false,
                        },
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            padding: isMobile ? 5 : 20,
                            align: 'end',
                            color: black800Color,
                            stepSize: 1000000,
                            font: {
                                size: 13,
                                family: textFonts,
                            },
                            callback: value => {
                                return formatNumber(+value, formatNumberOptions);
                            },
                        },
                    },
                },
                aspectRatio: 0.5,
                animation: !isDownloading ? undefined : false,
            }}
            data={data}
        />
    );
};

export { BarChart };
