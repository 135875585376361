import { useCallback, useEffect, useState } from 'react';

import { PageType } from '../shared';
import { ChangeRequestValidationDialog } from '../summary/funds/ChangeRequestValidationDialog';
import { FundValidationDialog } from '../summary/funds/FundValidationDialog';

import { convertFormStateToApiParam } from './convertFormStateToApiParam';
import { GetFundsParam } from './domain';
import { FundCancelDialog } from './FundCancelDialog';
import { FundChangeRequestCancelDialog } from './FundChangeRequestCancelDialog';
import { FundChangeRequestDialog } from './FundChangeRequestDialog';
import { FundFilterDialog } from './FundFilterDialog';
import { FundSingleDialog } from './FundSingleDialog';
import { FundTable } from './FundTable';
import { useGetFundsQuery } from './useGetFundsQuery';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { defaultPageSize } from '@shared/constants/paging';
import { useStore } from '@store';

export type DialogTypes =
    | 'cancel'
    | 'details'
    | 'approve'
    | 'reject'
    | 'changeRequest'
    | 'approveCR'
    | 'rejectCR'
    | 'cancelCR';

const canCancelFundPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];

const canValidateFundPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

const canChangeRequestPermissions = [
    permissions.coInnovationFunds.partnerRequests.create,
    permissions.coInnovationFunds.allRequests.create,
];

const canValidateChangeRequestPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

export type SelectedFundState = {
    id: string;
    types: DialogTypes[];
};

export const Funds = () => {
    const getDefaultPagination = () => {
        try {
            const storedPagination = sessionStorage.getItem('fundTablePagination');
            return storedPagination ? JSON.parse(storedPagination) : { page: 1 };
        } catch (error) {
            return { page: 1 };
        }
    };

    const [listParams, setListParams] = useState<GetFundsParam>({
        page: getDefaultPagination().page,
        pageSize: defaultPageSize,
        sorting: { id: '', desc: false },
    });

    const { setPage, filter } = useStore(state => state.coInnovationFunds);
    const { data, isFetching } = useGetFundsQuery(listParams, convertFormStateToApiParam(filter));
    const [selectedFundState, setSelectedFundState] = useState<SelectedFundState>();
    const selectedFund = data?.items.find(({ id }) => id === selectedFundState?.id);
    // const { t } = useTranslation(namespaces.features.coInnovationFunds);

    // const totalProvided = formatNumber(data?.totalProvided ?? 0);
    // const totalAvailable = formatNumber(data?.totalAvailable ?? 0);
    // const availablePercentage = formatNumber(
    //     ((data?.totalAvailable ?? 0) / (data?.totalProvided === 0 ? 1 : data?.totalProvided ?? 1)) * 100
    // );
    // const totalExpired = formatNumber(data?.totalExpired ?? 0);
    // const expiredPercentage = formatNumber(
    //     ((data?.totalExpired ?? 0) / (data?.totalProvided === 0 ? 1 : data?.totalProvided ?? 1)) * 100
    // );

    useEffect(() => {
        setPage(PageType.Fund);
    }, []);

    const onFundValidationClose = useCallback(
        () =>
            setSelectedFundState(previousState => ({
                id: selectedFund?.id || '',
                types: previousState?.types?.filter(type => type !== 'approve' && type !== 'reject') || [],
            })),
        [setSelectedFundState, selectedFund?.id]
    );

    const onCRValidationClose = useCallback(
        () =>
            setSelectedFundState(previousState => ({
                id: selectedFund?.id || '',
                types: previousState?.types?.filter(type => type !== 'approveCR' && type !== 'rejectCR') || [],
            })),
        [setSelectedFundState, selectedFund?.id]
    );

    // const sections = [
    //     {
    //         title: t('coInnovationFunds.fundsManagement.totalProvided'),
    //         value: `${totalProvided} CHF`,
    //         titleTestId: 'totalProvided-title',
    //         valueTestId: 'totalProvided-value',
    //     },
    //     {
    //         title: t('coInnovationFunds.fundsManagement.totalAvailable'),
    //         value: `${totalAvailable} CHF`,
    //         subValue: `${availablePercentage}% ${t('coInnovationFunds.summary.allocated.allocatedPercentage')}`,
    //         titleTestId: 'totalAllocated-title',
    //         valueTestId: 'totalAllocated-value',
    //     },
    //     {
    //         title: t('coInnovationFunds.fundsManagement.totalExpired'),
    //         value: `${totalExpired} CHF`,
    //         subValue: `${expiredPercentage}% ${t('coInnovationFunds.summary.expired.expiredPercentage')}`,
    //         titleTestId: 'totalExpired-title',
    //         valueTestId: 'totalExpired-value',
    //     },
    // ];

    return (
        <div className="w-full">
            {/* Fund KPIs are commented out for calculation clarification */}
            {/* <div className="px-10 pt-2">
                <FundKpis sections={sections} />
            </div> */}

            <div className="flex flex-grow flex-row">
                <FundTable
                    data={data}
                    isFetching={isFetching}
                    listParams={listParams}
                    setListParams={setListParams}
                    setSelectedFundState={setSelectedFundState}
                />

                <FundFilterDialog />

                <FundSingleDialog
                    selectedFundId={selectedFundState?.types.includes('details') ? selectedFundState.id : undefined}
                    setSelectedFundState={setSelectedFundState}
                    submissionDate={selectedFund?.submissionDate}
                    statusId={selectedFund?.statusId}
                />

                {selectedFundState?.types.includes('cancel') && (
                    <HasPermission neededPermissions={canCancelFundPermissions}>
                        <FundCancelDialog setSelectedFundState={setSelectedFundState} selectedFund={selectedFund} />
                    </HasPermission>
                )}

                {!!selectedFundState?.types.find(type => ['approve', 'reject'].includes(type)) && (
                    <HasPermission neededPermissions={canValidateFundPermissions}>
                        <FundValidationDialog
                            isOpen={!!selectedFundState?.types.find(type => ['approve', 'reject'].includes(type))}
                            id={selectedFundState.id}
                            modalType={selectedFundState?.types.includes('approve') ? 'approve' : 'reject'}
                            onClose={onFundValidationClose}
                        />
                    </HasPermission>
                )}

                {selectedFundState?.types.includes('changeRequest') && (
                    <HasPermission neededPermissions={canChangeRequestPermissions}>
                        <FundChangeRequestDialog
                            setSelectedFundState={setSelectedFundState}
                            selectedFund={selectedFund}
                        />
                    </HasPermission>
                )}

                {selectedFundState?.types.includes('cancelCR') && (
                    <HasPermission neededPermissions={canCancelFundPermissions}>
                        <FundChangeRequestCancelDialog
                            setSelectedFundState={setSelectedFundState}
                            selectedFund={selectedFund}
                        />
                    </HasPermission>
                )}

                {!!selectedFundState?.types.find(type => ['approveCR', 'rejectCR'].includes(type)) && (
                    <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                        <ChangeRequestValidationDialog
                            isOpen={!!selectedFundState?.types.find(type => ['approveCR', 'rejectCR'].includes(type))}
                            id={selectedFundState.id}
                            modalType={selectedFundState?.types.includes('approveCR') ? 'approve' : 'reject'}
                            onClose={onCRValidationClose}
                            selectedFund={selectedFund}
                        />
                    </HasPermission>
                )}
            </div>
        </div>
    );
};
