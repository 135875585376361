import { z } from 'zod';

export type PaginationModel = {
    page: number;
    pageSize: number;
};

export const cmsFormatUrlParser = z.object({
    url: z.string(),
});

export const cmsFormatsParser = z.object({
    small: cmsFormatUrlParser,
    medium: cmsFormatUrlParser,
    large: cmsFormatUrlParser,
});

export const cmsImageParser = z.object({
    id: z.number(),
    mime: z.string(),
    url: z.string(),
    formats: cmsFormatsParser,
});

export const cmsLinkParser = z.object({
    id: z.number(),
    name: z.string(),
    url: z.string(),
    disabled: z.boolean(),
});

export const cmsCardParser = z.object({
    id: z.number(),
    title: z.string(),
    content: z.string(),
    disabled: z.boolean(),
    links: z.array(cmsLinkParser),
});

export const cmsSubMenuItemParser = z.object({
    title: z.string(),
    links: z.array(cmsLinkParser),
});

const cmsMenuItemParser = z.object({
    title: z.string(),
    subItems: z.array(cmsSubMenuItemParser),
});

export const cmsMainMenuParser = z.object({
    innovationServices: cmsMenuItemParser.optional(),
});

export const IdNameParser = z.object({
    id: z.number().min(0),
    name: z.string(),
});

export const IdNameArrayParser = z.array(IdNameParser);

export const IdNameListResponseParser = z.object({
    total: z.number().min(0).optional(),
    items: IdNameArrayParser,
});

export type CMSSubMenuItem = z.infer<typeof cmsSubMenuItemParser>;

export type CMSMainMenuResponse = z.infer<typeof cmsMainMenuParser>;

export type IdNameItem = z.infer<typeof IdNameParser>;

export type IdNameListResponse = z.infer<typeof IdNameListResponseParser>;
