import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import produce from 'immer';

import { AuthSettings } from '@shared/settings';
import { useStore } from '@store';

const EVENTS_THAT_REQUIRES_SET_ACTIVE_ACCOUNT = [
    EventType.LOGIN_SUCCESS,
    EventType.SSO_SILENT_SUCCESS,
    EventType.ACQUIRE_TOKEN_SUCCESS,
];

export const setup = (msalInstance: PublicClientApplication, authSettings: AuthSettings) => {
    useStore.setState(state =>
        produce(state, draft => {
            draft.auth.authRequest.scopes = authSettings.scopes;
        })
    );

    const activeAccount = msalInstance.getActiveAccount();

    if (!activeAccount && msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0] ?? null);
    }

    useStore.setState(state =>
        produce(state, draft => {
            draft.auth.account = activeAccount;
        })
    );

    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((message: EventMessage) => {
        const payload = message.payload as AuthenticationResult;
        if (EVENTS_THAT_REQUIRES_SET_ACTIVE_ACCOUNT.includes(message.eventType) && payload.account) {
            const account = payload.account;
            msalInstance.setActiveAccount(account);

            useStore.setState(state =>
                produce(state, draft => {
                    draft.auth.account = payload.account!;
                    draft.auth.accessToken = payload.accessToken;
                })
            );
        }
    });
};
