import { ByPartnerTab } from './tabs/ByPartner/ByPartnerTab';
import { SummaryTab } from './tabs/SummaryTab';

const Funds = () => {
    return (
        <article className="h-full w-full ">
            <div className="flex">
                <SummaryTab active={true}>By Partners in CHF</SummaryTab>
            </div>
            <ByPartnerTab />
        </article>
    );
};

export { Funds };
