import { Chart, LegendItem } from 'chart.js';

const createLegendList = (id: string, elementListId: string) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        legendContainer?.appendChild(listContainer);
    }
    listContainer.id = elementListId;

    return listContainer;
};

const createLegendIcon = (item: LegendItem): HTMLDivElement => {
    const boxDiv = document.createElement('div');
    boxDiv.style.background = item.fillStyle ? item.fillStyle?.toString() : '';
    boxDiv.style.borderColor = item.strokeStyle ? item.strokeStyle.toString() : '';
    boxDiv.style.borderWidth = `${item.lineWidth}px'`;
    boxDiv.className = 'h-4 w-4 mr-3 rounded-full';
    return boxDiv;
};

const createLegendText = (item: LegendItem): HTMLParagraphElement => {
    const textContainer = document.createElement('p');
    textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
    textContainer.className = 'text-xs mr-4 w-[170px]';
    const text = document.createTextNode(item.text);
    textContainer.appendChild(text);
    return textContainer;
};

const handleFilterOnClick = (chart: Chart, label: LegendItem) => {
    const { type } = chart.config;
    if (type === 'doughnut' && label.index !== undefined) {
        chart.toggleDataVisibility(label.index);
    } else {
        const datasetIndex = label.datasetIndex;
        datasetIndex !== undefined && chart.setDatasetVisibility(datasetIndex, !chart.isDatasetVisible(datasetIndex));
    }
};

export { createLegendList, createLegendIcon, createLegendText, handleFilterOnClick };
