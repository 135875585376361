import { useMutation } from '@tanstack/react-query';

import { validateChangeRequest } from '@features/coInnovationFunds/funds/api';
import { CoInnovationChangeRequestValidationParam } from '@features/coInnovationFunds/funds/domain';

type Params = CoInnovationChangeRequestValidationParam & {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export const useCrValidationMutation = ({ onError, onSuccess, ...paramForApi }: Params) =>
    useMutation(async () => validateChangeRequest(paramForApi), {
        onSuccess,
        onError: onError ? (error: Error) => onError(error) : undefined,
    });
