import {
    CoInnovationFilter,
    CoInnovationFundYearsListResponseParser,
    coInnovationFundYearsListResponseParser,
    coInnovationMasterDataWithIntIdentifier,
    CoInnovationMasterDataWithIntIdentifier,
    coInnovationMasterDataWithUuidIdentifier,
    CoInnovationMasterDataWithUuidIdentifier,
} from './domain';

import { agent, queryStringComposer } from '@shared/api';
import { PagerProps } from '@shared/ui';
import { SortingProps } from '@shared/ui/sorting';

const apiBaseRoutes = {
    horizonsAll: '/api/horizons/all',
    teamsAll: '/api/teams/all',
    globalTeamsAll: '/api/globalteams/all',
    partnersAll: '/api/partners/all',
    yearsAll: '/api/years/all',
    phasesAll: '/api/Phases/all',
};

const getCoInnovationFundsWithIntIdentifier = async (url: string) => {
    const response = await agent.get<CoInnovationMasterDataWithIntIdentifier>(url);
    return response ? coInnovationMasterDataWithIntIdentifier.parseAsync(response) : null;
};

export const getHorizons = () => getCoInnovationFundsWithIntIdentifier(apiBaseRoutes.horizonsAll);

export const getGlobalTeams = () => getCoInnovationFundsWithIntIdentifier(apiBaseRoutes.globalTeamsAll);

const getCoInnovationFundsWithUuidIdentifier = async (url: string) => {
    const response = await agent.get<CoInnovationMasterDataWithUuidIdentifier>(url);
    return response ? coInnovationMasterDataWithUuidIdentifier.parseAsync(response) : null;
};

const getCoInnovationFundListResponseYears = async (url: string) => {
    const response = await agent.get<CoInnovationFundYearsListResponseParser>(url);
    return response ? coInnovationFundYearsListResponseParser.parseAsync(response) : null;
};

export const getTeams = () => getCoInnovationFundsWithUuidIdentifier(apiBaseRoutes.teamsAll);

export const getPartners = (excludeParents = false) => {
    let url = apiBaseRoutes.partnersAll;
    if (excludeParents) {
        url += '?excludeParents=true';
    }
    return getCoInnovationFundsWithUuidIdentifier(url);
};

export const getYears = () => getCoInnovationFundListResponseYears(apiBaseRoutes.yearsAll);

export const getPhases = () => getCoInnovationFundsWithIntIdentifier(apiBaseRoutes.phasesAll);

export const coInnovationFundsQueryParameterName = {
    HorizonTypes: 'horizons',
    Partners: 'partners',
    Years: 'years',
    Teams: 'teams',
    GlobalTeamTypes: 'globalTeams',
    Phases: 'phases',
    ProjectName: 'projectName',
};

export const createFilterQueryString = ({
    filter,
    sorting,
    pagination,
    isDownloading,
}: {
    filter?: CoInnovationFilter;
    sorting?: SortingProps;
    pagination?: PagerProps;
    isDownloading?: boolean;
}): string => {
    const composer = queryStringComposer();
    if (filter) {
        composer
            .addQueryStringValueFor(coInnovationFundsQueryParameterName.HorizonTypes, filter.horizons)
            .addQueryStringValueFor(coInnovationFundsQueryParameterName.Partners, filter.partners)
            .addQueryStringValueFor(coInnovationFundsQueryParameterName.Years, filter.years)
            .addQueryStringValueFor(coInnovationFundsQueryParameterName.Teams, filter.teams)
            .addQueryStringValueFor(coInnovationFundsQueryParameterName.GlobalTeamTypes, filter.globalTeams)
            .addQueryStringValueFor(coInnovationFundsQueryParameterName.Phases, filter.phases)
            .addQueryStringValueFor(coInnovationFundsQueryParameterName.ProjectName, [filter.projectName])
            .addQueryStringSortingFor(sorting)
            .addQueryStringPaginationFor(pagination)
            .addQueryStringValueFor('isDownloading', isDownloading ? [isDownloading] : undefined);
    }

    return composer.compose();
};
