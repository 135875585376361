import produce from 'immer';
import { StateCreator } from 'zustand';

export type Slice = {
    productCatalog: {
        url: string;
        setUrl: (url: string) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    productCatalog: {
        url: '',
        setUrl: (url: string) =>
            set(state =>
                produce(state, draft => {
                    draft.productCatalog.url = url;
                })
            ),
    },
});
