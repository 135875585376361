import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
    getByGlobalTeam,
    getTranslationKeyForUnitsOf,
    SummaryType,
    MultivalueChart,
    anyFilter,
    detailByGlobalTeam,
} from '../../shared';
import { useFilter } from '../../shared/filtering/useFilter';

import { formatDetailByGlobalTeamData } from './chartConfiguration';

import { retrieveFromCache, saveToCache } from '@shared/caching';
import { namespaces } from '@shared/constants';

const queryKey = 'detailByGlobalTeam';

export const useDetailByGlobalTeamQuery = () => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { filter } = useFilter();

    const query = useQuery<MultivalueChart[] | null>(
        [queryKey, filter],
        async () => {
            const response = await getByGlobalTeam(filter);

            if (!anyFilter(filter)) {
                saveToCache(queryKey, response);
            }

            return response != null
                ? formatDetailByGlobalTeamData(
                      response,
                      SummaryType.Money,
                      t(getTranslationKeyForUnitsOf(SummaryType.Money))
                  )
                : null;
        },
        {
            placeholderData: () => {
                if (!anyFilter(filter)) {
                    const cacheResponse = retrieveFromCache(queryKey, detailByGlobalTeam.parse);

                    if (cacheResponse) {
                        return formatDetailByGlobalTeamData(
                            cacheResponse,
                            SummaryType.Money,
                            t(getTranslationKeyForUnitsOf(SummaryType.Money))
                        );
                    }
                }

                return undefined;
            },
        }
    );

    return { query, filter };
};
