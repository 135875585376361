import { PropsWithChildren, useEffect, useRef } from 'react';

import classnames from 'classnames';
import { createPortal } from 'react-dom';

import { blurActiveElementIn } from '../../generic-functions';

type Props = {
    showDialog: boolean;
    size?: 'small' | 'large' | 'medium';
    dialogPosition?: 'right' | 'center';
    setShowDialog: (show: boolean) => void;
    disableBackgroundClick?: boolean;
};

const getPortalRoot = () => {
    let portalRoot = document.getElementById('appDialog');

    if (!portalRoot) {
        portalRoot = document.createElement('div');
        portalRoot.setAttribute('id', 'appDialog');
        document.body.appendChild(portalRoot);
    }

    return portalRoot;
};

const DialogContainer = ({
    size,
    children,
    setShowDialog,
    showDialog,
    dialogPosition = 'center',
    disableBackgroundClick,
}: PropsWithChildren<Props>) => {
    const portalRoot = useRef<HTMLElement>(getPortalRoot());

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent): void => {
            if (event.key === 'Escape') {
                setShowDialog(false);
            }
        };

        document.body.addEventListener('keyup', handleKeyPress);
        return () => {
            document.body.removeEventListener('keyup', handleKeyPress);
            document.body.className = 'overflow-auto';
            blurActiveElementIn(document);
        };
    }, [setShowDialog]);

    useEffect(() => {
        document.body.className = showDialog ? 'overflow-hidden' : 'overflow-auto';
    }, [showDialog]);

    return createPortal(
        <div
            className={classnames('fixed inset-0 z-[101] bg-gray-500 bg-opacity-[.85]', {
                hidden: !showDialog,
            })}
            onClick={disableBackgroundClick ? undefined : () => setShowDialog(false)}
        >
            <dialog
                className={classnames('absolute h-full max-w-full shadow-xl md:rounded', {
                    'md:w-[600px]': !size || size === 'large',
                    'md:w-[454px]': size === 'medium',
                    'md:w-[390px]': size === 'small',
                    'right-0 m-0 h-full w-[600px]': dialogPosition === 'right',
                    'inset-0 w-full md:h-max': dialogPosition === 'center',
                })}
                onCancel={event => event.preventDefault()}
                role="dialog"
                open={showDialog}
                aria-hidden={!showDialog}
            >
                <div className="flex h-full max-h-screen w-full flex-col" onClick={event => event.stopPropagation()}>
                    {children}
                </div>
            </dialog>
        </div>,
        portalRoot.current
    );
};

export { DialogContainer };
