import { purpleColor, blue900Color, tealColor, yellowColor, greyColor } from '@shared/constants';

export const createChartColors = () => [blue900Color, purpleColor, tealColor, yellowColor, greyColor];

export type LegendOptions = {
    display: boolean;
    position?: 'bottom';
    align?: 'start';
    labels?: {
        usePointStyle: boolean;
    };
};

export type HtmlLegend = {
    containerID: string;
};
