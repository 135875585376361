interface ColumnProps {
    label: string;
    value: React.ReactNode;
    icon?: React.ReactNode;
    onValueClick?: React.MouseEventHandler<HTMLSpanElement>;
    onIconClick?: React.MouseEventHandler<HTMLSpanElement>;
    className?: string;
    labelClassName?: string;
    valueClassName?: string;
}

export const Column: React.FC<ColumnProps> = ({
    label,
    value,
    icon,
    onValueClick,
    onIconClick,
    className = '',
    labelClassName = '',
    valueClassName = '',
}) => {
    return (
        <div className={`flex gap-1 ${className}`}>
            <span className={`${labelClassName}`}>{label}</span>
            <span className={`${valueClassName}`} onClick={onValueClick}>
                {value}
            </span>
            {icon && (
                <span className="justify-self-end" onClick={onIconClick}>
                    {icon}
                </span>
            )}
        </div>
    );
};
