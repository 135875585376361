import { Suspense } from 'react';

import { useTranslation } from 'react-i18next';

import { Buttons, CoInnovationFilter, FilterContent } from '../shared';

import { FundFilterForm } from './FundFilterForm';

import { namespaces } from '@shared/constants';
import { DialogBody, DialogContainer, DialogFooter, DialogHeader, Spinner } from '@shared/ui';
import { useStore } from '@store';

export const FundFilterDialog = () => {
    const { t: fundsManagementLogT } = useTranslation(namespaces.features.coInnovationFunds);

    const { setShowFilterDialog, showFilterDialog, setFilter } = useStore(state => state.coInnovationFunds);
    const applyFilterCallback = (data: CoInnovationFilter) => {
        setFilter(data);
        setShowFilterDialog(false);
    };

    if (!showFilterDialog) {
        return null;
    }

    return (
        <DialogContainer setShowDialog={setShowFilterDialog} showDialog={showFilterDialog}>
            <DialogHeader onCloseButtonClick={() => setShowFilterDialog(false)}>
                {fundsManagementLogT('coInnovationFunds.fundsManagement.filters.title')}
            </DialogHeader>

            <DialogBody>
                <Suspense fallback={<Spinner />}>
                    <FilterContent applyFilterCallback={applyFilterCallback} formName="fundsFilter">
                        <FundFilterForm />
                    </FilterContent>
                </Suspense>
            </DialogBody>

            <DialogFooter>
                <Buttons formName="fundsFilter" />
            </DialogFooter>
        </DialogContainer>
    );
};
