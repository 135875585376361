import { CellComponent, CellProps, CellType } from './domain';
import { DateCell, TextCell, CurrencyCell, ActionsCell } from './renderers';
import { LinkCell } from './renderers/LinkCell';
import { NumberCell } from './renderers/NumberCell';
import { PercentageCell } from './renderers/PercentageCell';

export const CellAlign: Record<CellType, 'right' | 'left'> = {
    number: 'right',
    text: 'left',
    currency: 'right',
    date: 'right',
    percentage: 'left',
    id: 'left',
    actions: 'right',
    link: 'left',
};

export const CellTypes: Record<CellType, CellComponent> = {
    number: <T extends object>({ value, context, meta }: CellProps<T>) => NumberCell({ value, context, meta }),
    text: <T extends object>({ value, context, meta }: CellProps<T>) => TextCell({ value, context, meta }),
    currency: <T extends object>({ value, context, meta }: CellProps<T>) => CurrencyCell({ value, context, meta }),
    date: <T extends object>({ value, context, meta, warningMessage, alignContent }: CellProps<T>) =>
        DateCell({ value, context, meta, warningMessage, alignContent }),
    percentage: <T extends object>({ value, context, meta }: CellProps<T>) => PercentageCell({ value, context, meta }),
    actions: <T extends object>({ value, context, meta, actions }: CellProps<T>) =>
        ActionsCell({ value, context, meta, actions }),
    link: <T extends object>({ value, context, meta, warningMessage, alignContent }: CellProps<T>) =>
        LinkCell({ value, context, meta, warningMessage, alignContent }),
    id: () => <></>,
};
