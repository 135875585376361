import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { anyFilter, getTranslationKeyForUnitsOf, MultivalueChartWithPercent, SummaryType } from '../../shared';
import { useFilter } from '../../shared/filtering/useFilter';

import { getByItTeam } from './api';
import { formatDetailByItTeamData } from './chartConfiguration';
import { coInnovationFundsDetailsByItTeamDashboardSectionParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';
import { namespaces } from '@shared/constants';
import { ValueType } from '@shared/formatting';

const queryKey = 'detailByItTeam';

export const useDetailByItTeamQuery = () => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { filter, isDownloading } = useFilter();

    const query = useQuery<MultivalueChartWithPercent[] | null>(
        [queryKey, filter, { isDownloading }],
        async () => {
            const response = await getByItTeam(filter, isDownloading);
            if (!isDownloading && !anyFilter(filter)) {
                saveToCache(queryKey, response);
            }

            return response
                ? formatDetailByItTeamData(response, ValueType.Money, t(getTranslationKeyForUnitsOf(SummaryType.Money)))
                : null;
        },
        {
            placeholderData: () => {
                if (!isDownloading && !anyFilter(filter)) {
                    const cacheResponse = retrieveFromCache(
                        queryKey,
                        coInnovationFundsDetailsByItTeamDashboardSectionParser.parse
                    );

                    if (cacheResponse) {
                        return formatDetailByItTeamData(
                            cacheResponse,
                            ValueType.Money,
                            t(getTranslationKeyForUnitsOf(SummaryType.Money))
                        );
                    }
                }

                return undefined;
            },
        }
    );

    return { query, filter };
};
