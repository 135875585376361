import { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ShareIcon } from '@assets/icons/share.svg';
import { namespaces } from '@shared/constants';
import { Button } from '@shared/ui';

export type ShareReportProps = {
    className?: string;
};

const ShareReport = ({ className }: ShareReportProps) => {
    const { t } = useTranslation(namespaces.features.genai);

    const [locationCopied, setLocationCopied] = useState(false);
    const handleClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setLocationCopied(true);
        setTimeout(() => {
            alert(t('share.success'));
        }, 1);
        setTimeout(() => {
            setLocationCopied(false);
        }, 3000);
    };

    return (
        <Button
            title="Share"
            aria-label="Share"
            onClick={handleClick}
            className={classNames(
                'border-blue-accessible bg-blue-accessible flex rounded border-2 px-3 py-2 text-sm font-normal text-white',
                className
            )}
            disabled={locationCopied}
        >
            {locationCopied ? t('share.linkCopied') : t('share.button')}
            {locationCopied ? <div className="mr-1">&#10004;</div> : <ShareIcon className="ml-1" />}
        </Button>
    );
};

export { ShareReport };
