import produce from 'immer';

import { useStore } from '@store';

const getUrlSearchParam = (path: string, param: string) => {
    const search = path.split('?')[1];
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get(param);
};

const downloadResponseInterceptor = (path: string, response: Response) => {
    const isDownloadingString = getUrlSearchParam(path, 'isDownloading');

    //previously 204 with empty error message
    if (isDownloadingString === 'true' && response.status === 500) {
        throw new Error(`Downloading internal error for path ${path}`);
    }
};

const noContentResponseInterceptor = (path: string, response: Response) => {
    const errorOnNoContentString = getUrlSearchParam(path, 'errorOnNoContent');

    if (errorOnNoContentString === 'true' && response.status === 204) {
        throw new Error('Not found', {
            cause: 404,
        });
    }
};

const http = async <T>(path: string, config: RequestInit): Promise<T> => {
    const idToken = useStore.getState().auth.accessToken;

    const secureConfig = idToken
        ? produce(config, draft => {
              draft.headers = { ...draft.headers, Authorization: `Bearer ${idToken}` };
          })
        : config;

    const request = new Request(path, secureConfig);
    const response = await fetch(request);

    downloadResponseInterceptor(path, response);
    noContentResponseInterceptor(path, response);

    if (!response.ok) {
        switch (response.status) {
            case 401:
                window.location.reload();
                break;
            default:
                throw new Error(response.statusText, {
                    cause: response.status,
                });
        }
    }

    return response.json().catch(() => {});
};

export const defaultConfig: RequestInit = {
    headers: {
        'Content-Type': 'application/json',
    },
};

export type Agent = {
    get: <T>(path: string, config?: RequestInit) => Promise<T>;
    post: <T, U>(path: string, body: T, config?: RequestInit) => Promise<U>;
    put: <T, U>(path: string, body: T, config?: RequestInit) => Promise<U>;
    patch: <T, U>(path: string, body: T, config?: RequestInit) => Promise<U>;
    delete: <T>(path: string, config?: RequestInit) => Promise<T>;
};

export const agent: Agent = {
    get: async <T>(path: string, config: RequestInit = defaultConfig): Promise<T> =>
        http<T>(path, { method: 'get', ...config }),
    post: async <T, U>(path: string, body: T, config: RequestInit = defaultConfig): Promise<U> =>
        http<U>(path, { method: 'post', body: JSON.stringify(body), ...config }),
    put: async <T, U>(path: string, body: T, config: RequestInit = defaultConfig): Promise<U> =>
        http<U>(path, { method: 'put', body: JSON.stringify(body), ...config }),
    patch: async <T, U>(path: string, body: T, config: RequestInit = defaultConfig): Promise<U> =>
        http<U>(path, { method: 'PATCH', body: JSON.stringify(body), ...config }),
    delete: async <T>(path: string, config: RequestInit = defaultConfig): Promise<T> =>
        http<T>(path, { method: 'delete', ...config }),
};
