import { useQuery } from '@tanstack/react-query';

import { SummaryType, anyFilter } from '../../../shared';
import { useFilter } from '../../../shared/filtering/useFilter';

import { getMoney } from './api';
import { SummaryValues, summaryValueParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

type Props = {
    summaryType: SummaryType;
    disableSuspense?: boolean;
};

const queryKey = 'summary';

export const useSummaryQuery = ({ summaryType, disableSuspense }: Props) => {
    const { filter, isDownloading } = useFilter();

    const isWithoutFiltersAndDownload = !isDownloading && !anyFilter(filter);

    const query = useQuery<SummaryValues | null>(
        [queryKey, { summaryType, isDownloading, filter: { years: filter.years, partners: filter.partners } }],
        async () => {
            const response = await getMoney(filter, isDownloading);

            if (isWithoutFiltersAndDownload) {
                saveToCache(`${queryKey}-${summaryType}`, response);
            }

            return response;
        },
        {
            suspense: !disableSuspense,
            placeholderData: () => {
                if (isWithoutFiltersAndDownload) {
                    return retrieveFromCache(`${queryKey}-${summaryType}`, summaryValueParser.parse);
                }

                return undefined;
            },
        }
    );

    return { query, filter };
};
