import { useEffect, useState } from 'react';

const RESIZE_TIMEOUT_MS = 100;

export const useResizeMediaQuery = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        let timeoutResize: NodeJS.Timeout;

        const calculateHeaderHeight = () => {
            const header = document.getElementById('mainHeader');

            if (header) {
                document.documentElement.style.setProperty('--header-height', `${header.offsetHeight}px`);
            }
        };

        const resizeCallback = () => {
            setWindowWidth(window.innerWidth);
            calculateHeaderHeight();
        };

        const resizeObserver = new ResizeObserver(() => {
            clearTimeout(timeoutResize);
            timeoutResize = setTimeout(resizeCallback, RESIZE_TIMEOUT_MS);
        });

        resizeObserver.observe(document.body);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return { isMobile: windowWidth < 768, windowWidth };
};
