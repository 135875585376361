import { CoInnovationFilter, createFilterQueryString } from '../../shared';

import { coInnovationFundsDetailsByItTeamDashboardSectionParser, DetailByItTeamItemValue } from './domain';

import { agent } from '@shared/api';

const apiRoutes = {
    detailsByItTeam: '/api/coinnovationfunds/detail/byitteam',
};

export const getByItTeam = async (filter?: CoInnovationFilter, isDownloading = false) => {
    const queryString = createFilterQueryString({ filter, isDownloading });
    const response = await agent.get<DetailByItTeamItemValue[]>(`${apiRoutes.detailsByItTeam}${queryString}`);

    return response ? coInnovationFundsDetailsByItTeamDashboardSectionParser.parseAsync(response) : null;
};
