import { ReactNode } from 'react';

import { ReactComponent as AlertIcon } from '@assets/icons/alert.svg';

type Props = {
    title: string;
    instruction?: ReactNode | undefined;
};

const DialogError = ({ title, instruction }: Props) => {
    return (
        <div className="bg-error-background p-5 text-left">
            <h4 className="mb-5 flex items-center text-xl [&_svg]:mr-2 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:text-error">
                <AlertIcon />
                {title}
            </h4>

            {instruction ? <p className="max-w-[330px] text-sm">{instruction}</p> : null}
        </div>
    );
};

export { DialogError };
