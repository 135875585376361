import { ReactNode } from 'react';

import { ColumnMeta, VisibilityState } from '@tanstack/react-table';

import { CellType } from './cell';

export type CellAction = {
    icon?: ReactNode | undefined;
    onClick: (id: string | number) => void;
    hide?: (id: string | number) => boolean | undefined;
    rowAccessorKey: string;
    active?: (id: string | number) => boolean | undefined;
};

export type CustomColumn<T extends object> = {
    accessorKey: string;
    cellType: CellType;
    header?: string;
    visible?: boolean;
    size?: number;
    sortable?: boolean;
    meta?: ColumnMeta<T, unknown>;
    selectedColumnCallback?: (id: string) => void;
    actions?: CellAction[];
    warningMessage?: string;
    alignContent?: 'left' | 'right';
    href?: string;
    tooltip?: string;
};

export const columnVisibility = <T extends object>(columns: CustomColumn<T>[]) => {
    const result: Record<string, boolean> = {};
    columns.forEach(column => {
        if (column.visible === false) {
            result[column.accessorKey] = column.visible;
        }
    });

    return result as VisibilityState;
};
