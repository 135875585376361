import * as React from 'react';
import { useState } from 'react';

import { Row } from './row';

import { ReactComponent as ChevronDown } from '@assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from '@assets/icons/chevronUp.svg';

interface ExpandableRowProps {
    children: React.ReactNode;
    className?: string;
}

export const ExpandableRow: React.FC<ExpandableRowProps> = ({ children, className = '' }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const [firstColumn, ...expandableColumns] = React.Children.toArray(children);

    return (
        <Row className={`${className}`}>
            {React.cloneElement(firstColumn as React.ReactElement, {
                onIconClick: toggleExpand,
                icon: (firstColumn as React.ReactElement).props.icon || (isExpanded ? <ChevronUp /> : <ChevronDown />),
            })}

            {isExpanded && expandableColumns}
        </Row>
    );
};
