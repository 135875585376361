import { useQuery } from '@tanstack/react-query';

import { anyFilter } from '../../../../shared';
import { useFilter } from '../../../../shared/filtering/useFilter';

import { getFundsByPartner } from './api';
import { FundsByPartnerResponse, fundsByPartnerParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';
import { SortingProps } from '@shared/ui/sorting';

const queryKey = 'FundsByPartner';

export const useFundsByPartnerQuery = ({ id: orderBy, desc }: SortingProps) => {
    const { filter, isDownloading } = useFilter();

    const areParamsDefault = !isDownloading && !anyFilter(filter) && !orderBy && !desc;

    const query = useQuery<FundsByPartnerResponse | null>(
        [queryKey, { orderBy, desc, isDownloading }, filter.years, filter.partners],
        async () => {
            const response = await getFundsByPartner({ filter, orderBy, desc, isDownloading });

            if (areParamsDefault) {
                saveToCache(queryKey, response);
            }

            return response;
        },
        {
            suspense: isDownloading,
            placeholderData: () => {
                if (areParamsDefault) {
                    return retrieveFromCache(queryKey, fundsByPartnerParser.parse);
                }

                return undefined;
            },
        }
    );

    return { query, filter };
};
