import { CoInnovationFilter, createFilterQueryString } from '../../shared';

import { coInnovationFundsDetailsByHorizonDashboardSectionParser, DetailByHorizonItemValue } from './domain';

import { agent } from '@shared/api';

const apiRoutes = {
    detailsByHorizon: '/api/coinnovationfunds/detail/byhorizon',
};

export const getByHorizon = async (filter?: CoInnovationFilter, isDownloading = false) => {
    const queryString = createFilterQueryString({ filter, isDownloading });
    const response = await agent.get<DetailByHorizonItemValue[]>(`${apiRoutes.detailsByHorizon}${queryString}`);

    return response ? coInnovationFundsDetailsByHorizonDashboardSectionParser.parseAsync(response) : null;
};
