import { RefAttributes } from 'react';

import classNames from 'classnames';
import { LinkProps } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

type CommonProps = {
    to: string;
    active?: boolean;
    dataTestId?: string;
    disabled?: boolean;
    noPadding?: boolean;
    alignBaseLine?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

type ButtonProps = RefAttributes<HTMLButtonElement> & React.ButtonHTMLAttributes<HTMLButtonElement>;
type HashLinkProps = RefAttributes<HTMLAnchorElement> & LinkProps;

type DataTestIdProp = { 'data-testid'?: string };

type ExtendedButtonProps = ButtonProps & DataTestIdProp;
type ExtendedAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & DataTestIdProp;
type ExtendedHashLinkProps = HashLinkProps & DataTestIdProp;

type Props = CommonProps & (ExtendedAnchorProps | ExtendedButtonProps | ExtendedHashLinkProps);

const Link = ({
    to,
    active,
    onClick,
    disabled,
    children,
    className,
    noPadding,
    dataTestId,
    alignBaseLine,
    ...props
}: Props) => {
    const linkClasses = classNames(
        className,
        'inline-flex items-center border border-transparent text-blue-850 transition-all',
        'hover:no-underline',
        'lg:hover:underline',
        'active:no-underline',
        'lg:active:underline',
        'focus:border-transparent',
        'lg:focus:border-blue-850 lg:focus:underline',
        '[&_svg]:inline-block [&_svg]:ml-4 [&_svg]:h-3 [&_svg]:w-3',
        alignBaseLine ? 'align-baseline' : 'align-middle',
        {
            'p-1': !noPadding,
            underline: active,
            '!cursor-default !border-transparent !text-gray-400 !no-underline': disabled,
        }
    );

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        if (disabled) {
            e.preventDefault();
        }

        if (onClick) {
            onClick(e);
        }
    };

    const buttonSpecificProps: ExtendedButtonProps = {
        type: 'button',
        className: linkClasses,
        onClick: handleClick,
        disabled,
        'data-testid': dataTestId,
    };

    const anchorSpecificProps: ExtendedAnchorProps = {
        href: to,
        className: linkClasses,
        target: '_blank',
        rel: 'noreferrer',
        'data-testid': dataTestId,
    };

    const hashLinkSpecificProps = {
        className: linkClasses,
        to: to,
        onClick: handleClick,
        'data-testid': dataTestId,
    };

    if ('type' in props && props.type === 'button') {
        return <button {...buttonSpecificProps}>{children}</button>;
    } else if (!to.startsWith('http') || disabled) {
        return <HashLink {...hashLinkSpecificProps}>{children}</HashLink>;
    } else {
        return <a {...anchorSpecificProps}>{children}</a>;
    }
};

export { Link };
