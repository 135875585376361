import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getFundStatusTranslationKey } from './getFundStatusTranslationKey';
import { useGetFundByIdQuery } from './useGetFundByIdQuery';

import { namespaces } from '@shared/constants';
import { formatDate, formatNumber } from '@shared/formatting';
import { Contact, ContactBox } from '@shared/ui';

type Props = {
    fundId: string;
};

export const FundSingleView = ({ fundId }: Props) => {
    const { data } = useGetFundByIdQuery(fundId);
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    if (!data) {
        return <></>;
    }

    const {
        partner,
        provided,
        beginDate,
        statusId,
        submissionComment,
        validationComment,
        submittedByContact,
        validatedByContact,
        consumptionExpirationDate,
        submissionDate,
    } = data;

    return (
        <div>
            <div className="mb-2 grid grid-cols-3 md:grid-cols-9">
                <div>
                    <h3 className="mb-[6px] text-base">{`${t('coInnovationFunds.fundsManagement.status')}:`}</h3>
                </div>
                <div>
                    <div className="text-sm font-light">{t(getFundStatusTranslationKey(statusId))}</div>
                </div>
            </div>

            <div className="mb-6 grid grid-cols-1 md:grid-cols-2">
                <div>
                    <h3 className="mb-[6px] text-base">{`${t(
                        'coInnovationFunds.fundsManagement.fundsSubmited'
                    )} - ${formatDate(submissionDate)}`}</h3>

                    <ContactBox email={submittedByContact.email} name={submittedByContact.name} />
                </div>
            </div>

            <div className="mb-6 grid grid-cols-2 gap-2">
                <div className={classNames('mb-2 rounded bg-blue-50 p-4', { 'col-span-2': !validatedByContact })}>
                    <h3 className="mb-[6px] text-base">{t('coInnovationFunds.fundsManagement.providedFunds')}</h3>

                    <p className="text-sm font-light">{`${formatNumber(provided)} CHF`}</p>
                </div>

                {validatedByContact ? (
                    <div>
                        <h3 className="mb-[6px] text-base">{t('coInnovationFunds.fundsManagement.approver')}</h3>

                        <ContactBox email={validatedByContact.email} name={validatedByContact.name} />
                    </div>
                ) : null}
            </div>

            <div className="mb-6 grid grid-cols-2 gap-2">
                <div>
                    <h3 className="mb-[6px] text-base">{t('coInnovationFunds.fundsManagement.partner')}</h3>

                    <div className="text-sm font-light">{partner.name}</div>
                </div>

                <div>
                    <h3 className="mb-[6px] text-base">{t('coInnovationFunds.fundsManagement.beginDate')}</h3>

                    <div className="text-sm font-light">{formatDate(beginDate)}</div>
                </div>
            </div>

            <div className="mb-6 grid grid-cols-1 gap-2">
                <div>
                    <h3 className="mb-[6px] text-base">
                        {t('coInnovationFunds.fundsManagement.consumptionExpirationDate')}
                    </h3>

                    <div className="text-sm font-light">{formatDate(consumptionExpirationDate)}</div>
                </div>
            </div>

            {submissionComment ? (
                <div className="mb-6">
                    <div className="mb-2 flex items-center">
                        <Contact name={submittedByContact.name} photo={undefined} />
                        <span className="0 ml-4 text-sm font-light">{formatDate(submissionComment.creationDate)}</span>
                    </div>

                    <p className="text-sm font-light">{submissionComment.text}</p>
                </div>
            ) : null}

            {validationComment && validatedByContact ? (
                <div className="mb-6">
                    <div className="mb-2 flex items-center">
                        <Contact name={validatedByContact.name} photo={undefined} />
                        <span className="0 ml-4 text-sm font-light">{formatDate(validationComment.creationDate)}</span>
                    </div>

                    <p className="text-sm font-light">{validationComment.text}</p>
                </div>
            ) : null}
        </div>
    );
};
