import { ChangeEvent } from 'react';

type Props = {
    label: string;
    value: string | number;
    id: string;
    isDefaultChecked: boolean;
    onChangeCallback: (event: ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxTag = ({ label, id, value, isDefaultChecked, onChangeCallback }: Props) => (
    <div>
        <input type="checkbox" value={value} id={id} defaultChecked={isDefaultChecked} onChange={onChangeCallback} />
        <label htmlFor={id} className="tag-label selectable">
            {label}
        </label>
    </div>
);

export { CheckboxTag };
