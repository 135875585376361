import i18next, { i18n as i18nInstance } from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { languages, namespaces } from '@shared/constants';

const productionLocalsPath = '/locals/{{lng}}/{{ns}}.json';
const developmentLocalsPath = '/src/assets/locals/{{lng}}/{{ns}}.json';

const createI18n = (language: string): i18nInstance => {
    const i18n = i18next.createInstance().use(initReactI18next);

    i18n.use(HttpApi).init({
        debug: false,
        lng: language,
        fallbackLng: language,
        backend: {
            loadPath: () => (import.meta.env.PROD ? productionLocalsPath : developmentLocalsPath),
        },
        ns: namespaces.common,
    });

    return i18n;
};

export const i18n = createI18n(languages.en);
