import { Suspense, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Buttons, CoInnovationFilter, FilterContent, PageType } from '../shared';

import { ByGlobalTeamSection } from './byGlobalTeamSection';
import { ByHorizonSection } from './byHorizonSection';
import { ByItTeamSection, ByItTeamSectionHeader } from './byItTeamSection';
import { DetailFilters } from './DetailFilters';
import { ProjectsDetails } from './projectsDetails';

import { ReactComponent as DashboardIcon } from '@assets/icons/dashboardIcon.svg';
import { ReactComponent as ProjectsDetailsIcon } from '@assets/icons/projectsDetailsIcon.svg';
import { namespaces } from '@shared/constants';
import { ErrorManagement } from '@shared/errorManagement';
import { DialogBody, DialogContainer, DialogFooter, DialogHeader, Spinner, Tabs } from '@shared/ui';
import { useStore } from '@store';

const Detail = () => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { setShowFilterDialog, showFilterDialog, setFilter, setPage } = useStore(state => state.coInnovationFunds);
    useEffect(() => {
        setPage(PageType.Details);
    }, []);

    const applyFilterCallback = (data: CoInnovationFilter) => {
        setFilter(data);
        setShowFilterDialog(false);
    };

    return (
        <div className="flex flex-grow flex-row" id="detail">
            <Tabs
                elements={[
                    {
                        testid: 'dashboard',
                        name: t('coInnovationFunds.detail.dashboard.tabName'),
                        icon: <DashboardIcon />,
                        withFallbackInContent: true,
                        content: (
                            <div className="m-0 flex h-full justify-center">
                                <div className="grid-row-1 grid min-w-full grid-cols-4 gap-3" id="detail">
                                    <div className="col-span-4 row-span-1 flex  flex-col rounded border border-blue-100 md:col-span-2 lg:col-span-1">
                                        <div className="flex justify-between border-b border-blue-100 p-5 ">
                                            <h3 id="byHorizon" className="text-xl">
                                                {t('coInnovationFunds.detail.dashboard.byHorizon.title')}
                                            </h3>
                                            <div className="text-sm">
                                                {t('coInnovationFunds.detail.dashboard.allocated')}
                                            </div>
                                        </div>
                                        <Suspense fallback={<Spinner />}>
                                            <ErrorManagement>
                                                <ByHorizonSection applyFilterCallback={applyFilterCallback} />
                                            </ErrorManagement>
                                        </Suspense>
                                    </div>
                                    <div className="col-span-4 row-span-1 flex  flex-col rounded border border-blue-100  md:col-span-2 lg:col-span-1">
                                        <div className="flex justify-between border-b border-blue-100 p-5 ">
                                            <h3 id="byGlobalTeam" className="text-xl">
                                                {t('coInnovationFunds.detail.dashboard.byGlobalTeam')}
                                            </h3>
                                            <div className="text-sm">
                                                {t('coInnovationFunds.detail.dashboard.allocated')}
                                            </div>
                                        </div>

                                        <Suspense fallback={<Spinner />}>
                                            <ErrorManagement>
                                                <ByGlobalTeamSection applyFilterCallback={applyFilterCallback} />
                                            </ErrorManagement>
                                        </Suspense>
                                    </div>
                                    <div className="col-span-4 row-span-1 flex flex-col rounded border border-blue-100 md:col-span-4 lg:col-span-2">
                                        <ByItTeamSectionHeader />
                                        <Suspense fallback={<Spinner />}>
                                            <ErrorManagement isComponentError={true}>
                                                <ByItTeamSection applyFilterCallback={applyFilterCallback} />
                                            </ErrorManagement>
                                        </Suspense>
                                    </div>
                                </div>
                            </div>
                        ),
                    },
                    {
                        testid: 'projectsDetails',
                        name: t('coInnovationFunds.detail.projectsDetails.tabName'),
                        icon: <ProjectsDetailsIcon />,
                        content: <ProjectsDetails />,
                        withFallbackInContent: false,
                    },
                ]}
            />
            {showFilterDialog ? (
                <DialogContainer setShowDialog={setShowFilterDialog} showDialog={showFilterDialog}>
                    <DialogHeader onCloseButtonClick={() => setShowFilterDialog(false)}>Filters</DialogHeader>

                    <DialogBody>
                        <Suspense fallback={<Spinner />}>
                            <FilterContent applyFilterCallback={applyFilterCallback} formName={'detailFilter'}>
                                <DetailFilters />
                            </FilterContent>
                        </Suspense>
                    </DialogBody>

                    <DialogFooter>
                        <Buttons formName="detailFilter" />
                    </DialogFooter>
                </DialogContainer>
            ) : null}
        </div>
    );
};

export { Detail };
