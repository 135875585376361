import * as React from 'react';

interface ModularTableProps {
    children: React.ReactNode;
    className?: string;
}

export const ModularTable: React.FC<ModularTableProps> = ({ children, className = '' }) => {
    return (
        <div className={`w-full`}>
            <div className={`flex flex-col  ${className}`}>{children}</div>
        </div>
    );
};
