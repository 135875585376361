import { Suspense, useEffect } from 'react';

import { Buttons, CoInnovationFilter, FilterContent, PageType } from '../shared';

import { Funds } from './funds';
import { SummaryFilters } from './SummaryFilters';

import { DialogBody, DialogContainer, DialogFooter, DialogHeader, Spinner } from '@shared/ui';
import { useStore } from '@store';

const Summary = () => {
    const { setShowFilterDialog, showFilterDialog, setFilter, setPage } = useStore(state => state.coInnovationFunds);
    const applyFilterCallback = (data: CoInnovationFilter) => {
        setFilter(data);
        setShowFilterDialog(false);
    };

    useEffect(() => {
        setPage(PageType.Summary);
    }, []);

    return (
        <div id="summary">
            <Funds />

            {showFilterDialog ? (
                <DialogContainer setShowDialog={setShowFilterDialog} showDialog={showFilterDialog}>
                    <DialogHeader onCloseButtonClick={() => setShowFilterDialog(false)}>Filters</DialogHeader>

                    <DialogBody>
                        <Suspense fallback={<Spinner />}>
                            <FilterContent applyFilterCallback={applyFilterCallback} formName="summaryFilter">
                                <SummaryFilters />
                            </FilterContent>
                        </Suspense>
                    </DialogBody>

                    <DialogFooter>
                        <Buttons formName="summaryFilter" />
                    </DialogFooter>
                </DialogContainer>
            ) : null}
        </div>
    );
};

export { Summary };
