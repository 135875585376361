import { PropsWithChildren } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { ManagedErrorCodes } from '@features/errors/domain';
import { LazyErrorPage } from '@pages/lazyLoadingPages';
import { ContentError } from '@shared/ui';

type Props = {
    isComponentError?: boolean;
};

const ErrorManagement = ({ children, isComponentError = false }: PropsWithChildren<Props>) => {
    const { pathname } = useLocation();

    return (
        <ErrorBoundary
            FallbackComponent={({ error }) => {
                const cause = Number.isSafeInteger(error.cause) ? (error.cause as number) : null;
                return isComponentError ? <ContentError /> : <LazyErrorPage errorCode={cause as ManagedErrorCodes} />;
            }}
            key={pathname}
        >
            {children}
        </ErrorBoundary>
    );
};

export { ErrorManagement };
