import { Contact } from '../contact/Contact';

import { ReactComponent as MailIcon } from '@assets/icons/mail.svg';

type Props = {
    name: string;
    email: string;
    photo?: string | null;
};

function ContactBox({ email, name, photo }: Props) {
    return (
        <a role="link" className="flex items-center justify-between" href={`mailto:${email}`}>
            <Contact name={name} photo={photo} />

            <span className="text-blue-800">
                <MailIcon />
            </span>
        </a>
    );
}

export { ContactBox };
