import { useTranslation } from 'react-i18next';

type Props = {
    altText?: string;
    loading?: boolean;
};

const LoadingBar = ({ loading, altText }: Props) => {
    const { t } = useTranslation();
    if (loading === false) {
        return null;
    }
    return (
        <div className="fixed top-0 left-0 z-[110] h-2 w-full border border-gray-300 bg-gray-300">
            <div className="absolute top-0 bottom-0 w-[40%] animate-loading-bar bg-blue-800">
                <span className="sr-only">{altText ? altText : t('global.loading')}</span>
            </div>
        </div>
    );
};

export { LoadingBar };
