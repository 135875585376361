import { InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';

import { isSafari } from '@shared/constants';
import { useStore } from '@store';

type HandleLogin = {
    instance: IPublicClientApplication;
    scopes: string[];
};

export const handleLogin = ({ instance, scopes }: HandleLogin) => {
    if (isSafari) {
        instance.loginRedirect(useStore.getState().auth.authRequest);
    } else {
        instance.ssoSilent({ scopes: scopes }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.loginRedirect(useStore.getState().auth.authRequest);
            } else {
                console.warn(`Unable to adquire the token. Reason: ${error}`);
            }
        });
    }
};

type HandleLogout = {
    instance: IPublicClientApplication;
};

export const handleLogout = ({ instance }: HandleLogout) => {
    instance.logoutRedirect({
        account: instance.getActiveAccount()!,
    });
};
