import { useTranslation } from 'react-i18next';

import { namespaces } from '@shared/constants';
import { Contact } from '@shared/ui';

type CommentSectionProps = {
    name: string;
    text: string;
    statusChange?: number;
};

const statusChangeMap: { [key: number]: string } = {
    0: 'coInnovationFunds.fundsManagement.statuses.pending',
    1: 'coInnovationFunds.fundsManagement.statuses.approved',
    2: 'coInnovationFunds.fundsManagement.statuses.rejected',
    3: 'coInnovationFunds.fundsManagement.statuses.canceled',
    4: 'coInnovationFunds.fundsManagement.statuses.cRPending',
};

function FundDetailComment({ name, text, statusChange }: CommentSectionProps) {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const statusChangeKey =
        statusChange !== undefined
            ? statusChangeMap[statusChange] || 'coInnovationFunds.fundsManagement.statuses.unknown'
            : '';
    const statusChangeText = statusChange !== undefined ? t(statusChangeKey) : '';
    const displayName =
        statusChange !== undefined
            ? `${name} ${t('coInnovationFunds.fundsManagement.changedTheStatusTo')} ${statusChangeText}`
            : name;

    return (
        <div className="mb-6" role="article" aria-label={`Comment by ${name}`}>
            <div className="mb-2 flex">
                <span className="mr-1">
                    <Contact name={displayName} photo={undefined} />
                </span>
            </div>
            <p className="text-left text-sm font-light">{text}</p>
        </div>
    );
}

export default FundDetailComment;
