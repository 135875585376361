import { UseFormReturn, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SummaryType, getTranslationKeyForUnitsOf } from '../shared';

import { CoInnovationFundListItem } from './domain';

import { namespaces } from '@shared/constants';
import { Input, getFieldErrorMessage, handleFormattedNumberInput, TextArea } from '@shared/form';

export type ChangeRequestFormState = {
    provided: string;
    expired: string;
    carriedForward: string;
    submissionComment: string;
};

type Props = {
    form: UseFormReturn<ChangeRequestFormState>;
    selectedFund: CoInnovationFundListItem;
};

const FundChangeRequestForm = ({
    form: {
        control,
        formState: { errors, isSubmitted },
    },
    selectedFund,
}: Props) => {
    const { t: fundsT } = useTranslation(namespaces.features.coInnovationFunds);

    const { field: providedField } = useController({
        name: 'provided',
        control,
        defaultValue: selectedFund.provided.toString(),
    });

    const { field: expiredField } = useController({
        name: 'expired',
        control,
        defaultValue: selectedFund.expired.toString(),
    });

    const { field: carriedForwardField } = useController({
        name: 'carriedForward',
        control,
        defaultValue: selectedFund.carryForward.toString(),
    });

    const { field: submissionCommentField } = useController({
        name: 'submissionComment',
        control,
        defaultValue: '',
    });

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Input
                id={providedField.name}
                errors={isSubmitted ? getFieldErrorMessage(errors.provided) : undefined}
                {...providedField}
                value={providedField.value}
                label={`${fundsT('coInnovationFunds.addFundsForm.providedLabel')}`}
                placeholder="Amount provided"
                suffix={getTranslationKeyForUnitsOf(SummaryType.Money)}
                type="text"
                onChange={event => {
                    const value = handleFormattedNumberInput(event.target.value);
                    if (value === undefined) {
                        return;
                    }
                    event.target.value = value;
                    providedField.onChange(event);
                }}
            />

            <Input
                id={carriedForwardField.name}
                errors={isSubmitted ? getFieldErrorMessage(errors.provided) : undefined}
                {...carriedForwardField}
                value={carriedForwardField.value}
                label={`${fundsT('coInnovationFunds.addFundsForm.carryForwardLabel')}`}
                placeholder="Amount carried forward"
                suffix={getTranslationKeyForUnitsOf(SummaryType.Money)}
                type="text"
                onChange={event => {
                    const value = handleFormattedNumberInput(event.target.value);
                    if (value === undefined) {
                        return;
                    }
                    event.target.value = value;
                    carriedForwardField.onChange(event);
                }}
            />

            <Input
                id={expiredField.name}
                errors={isSubmitted ? getFieldErrorMessage(errors.provided) : undefined}
                {...expiredField}
                value={expiredField.value}
                label={`${fundsT('coInnovationFunds.addFundsForm.expiredLabel')}`}
                placeholder="Amount expired"
                suffix={getTranslationKeyForUnitsOf(SummaryType.Money)}
                type="text"
                onChange={event => {
                    const value = handleFormattedNumberInput(event.target.value);
                    if (value === undefined) {
                        return;
                    }
                    event.target.value = value;
                    expiredField.onChange(event);
                }}
            />

            <TextArea
                id={submissionCommentField.name}
                {...submissionCommentField}
                label={`${fundsT('coInnovationFunds.addFundsForm.commentsLabel')}*`}
                placeholder={fundsT('coInnovationFunds.addFundsForm.commentsLabelDescription')}
                onChange={event => {
                    submissionCommentField.onChange(event);
                }}
                rows={3}
                required={true}
            />
        </form>
    );
};

export { FundChangeRequestForm };
