import { useMsal } from '@azure/msal-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { handleLogout } from '../handlers';

import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';
import userSvgUrl from '@assets/icons/user.svg';
import { Dropdown } from '@shared/ui';

type Props = {
    photo?: string;
    name: string;
};
const Avatar = ({ photo, name }: Props) => {
    const { instance } = useMsal();
    const { t } = useTranslation();

    return (
        <>
            <div className="avatar hidden sm:inline-flex">
                <Dropdown
                    title={
                        <div
                            className="inline-flex cursor-pointer items-center py-3 text-black transition-colors"
                            data-testid="logout-user-profile"
                        >
                            <span
                                className={classNames(
                                    'flex h-8 w-8 overflow-hidden rounded-full border border-blue-850',
                                    {
                                        'items-center justify-center bg-white': !photo,
                                    }
                                )}
                            >
                                <img
                                    src={photo ? `data:image/jpg;base64,${photo}` : userSvgUrl}
                                    alt="avatar image"
                                    className={classNames({
                                        'h-full max-w-none': photo,
                                    })}
                                />
                            </span>
                        </div>
                    }
                    dropdownClassNames="w-full flex justify-end bg-transparent right-0 top-full sm:absolute"
                >
                    <div className="border-3 mr-1 flex flex-col gap-3 whitespace-nowrap bg-white p-3 text-xs shadow-xl">
                        <span className="font-normal">{name}</span>

                        <button
                            type="button"
                            className="flex text-xs"
                            onClick={() => handleLogout({ instance })}
                            data-testid="logout-button"
                        >
                            <i className="text-blue-850">
                                <LogoutIcon />
                            </i>

                            <span className="ml-2 mt-1">{t('account.logout')}</span>
                        </button>
                    </div>
                </Dropdown>
            </div>

            <button
                type="button"
                className="static inline-flex gap-2 text-xs sm:hidden"
                onClick={() => handleLogout({ instance })}
                data-testid="logout-button-small-screen"
            >
                <i className="text-blue-850">
                    <LogoutIcon />
                </i>
                <span>{t('account.logout')}</span>
            </button>
        </>
    );
};

export { Avatar };
