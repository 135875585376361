export const handleFormattedNumberInput = (value: string): string | undefined => {
    const valueParts = value.split(',');
    const wholeNumber = valueParts[0];
    const decimals = valueParts[1];
    if (value === '') {
        return '';
    }
    // have an upper limit for precision
    if (wholeNumber && wholeNumber.length > 14) {
        return undefined;
    }

    const commaCount = (value.match(/,/g) || []).length;
    if (commaCount > 1) {
        return undefined;
    }

    if (decimals) {
        // prevent more than 2 decimals
        if (decimals.length > 2) {
            return undefined;
        }
    }
    return value.replace(/[^\d,]/g, '');
};
