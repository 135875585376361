import { UseQueryResult } from '@tanstack/react-query';

import { MultivalueChartWithPercent } from './domain';

import { CoInnovationFilter } from '@features/coInnovationFunds/shared';
import { formatNumber } from '@shared/formatting';

type Props = {
    data: MultivalueChartWithPercent[];
    filter: CoInnovationFilter;
    applyFilterCallback?: (data: CoInnovationFilter) => void;
    teamsDataQuery: UseQueryResult<
        | {
              id: string;
              name: string;
          }[]
        | null
    >;
};

type BarProps = {
    data: MultivalueChartWithPercent;
    width: number;
    filter: CoInnovationFilter;
    applyFilterCallback?: (data: CoInnovationFilter) => void;
    teamsDataQuery: UseQueryResult<
        | {
              id: string;
              name: string;
          }[]
        | null
    >;
};
const MobileBarChart = ({ data, applyFilterCallback, filter, teamsDataQuery }: Props) => {
    const max = data.reduce((max, current) => {
        return current.value > max ? current.value : max;
    }, 0);
    return (
        <div>
            <div className="my-2 text-sm">#Projects Funds</div>
            {data.map(x => (
                <Bar
                    key={x.legend}
                    data={x}
                    width={(x.value / max) * 52}
                    applyFilterCallback={applyFilterCallback}
                    filter={filter}
                    teamsDataQuery={teamsDataQuery}
                ></Bar>
            ))}
        </div>
    );
};

const Bar = ({ data, width, applyFilterCallback, filter, teamsDataQuery }: BarProps) => {
    let padding = 100 / width;
    if (width < 5) {
        padding = 20;
    }
    return (
        <div
            className=" mb-3 w-full text-sm"
            onClick={() => onClick(data.legend.split('-')[0]!, filter, teamsDataQuery, applyFilterCallback)}
        >
            <div className=" pb-1 text-blue-800">{data.legend.split('-')[0]}</div>
            <div className="flex-shrink-3 mb-2 flex flex-row gap-1">
                <div className=" flex min-w-[30px] justify-center rounded-sm bg-[rgba(177,79,197,0.9)] pt-[2px] text-white">
                    {data.legend.split('-')[1]}
                </div>

                <div style={{ width: `${width}%` }} className="flex  flex-row rounded-sm bg-blue-100">
                    <div className="absolute ml-[3px] mt-[6px] bg-[#0000ff80] px-[3px] text-2xs font-bold text-white">
                        {' '}
                        {data.text}
                    </div>
                    {data.percent === 0 ? (
                        <div className=" flex-nowrap rounded-sm bg-blue-100 pl-1 pt-[2px] text-gray-600"></div>
                    ) : (
                        <div
                            style={{ width: `${data.percent}%` }}
                            className=" rounded-sm bg-blue-900 pl-1 pt-[2px] text-white"
                        ></div>
                    )}
                </div>
                <div className="padding" style={{ width: `${padding}px` }}></div>
                <div className="pt-[2px]">{`${formatNumber(data.value)} CHF`} </div>
            </div>
        </div>
    );
};

const onClick = (
    selectedTeam: string,
    filter: CoInnovationFilter,
    teamsDataQuery: UseQueryResult<
        | {
              id: string;
              name: string;
          }[]
        | null
    >,
    applyFilterCallback?: (data: CoInnovationFilter) => void
) => {
    if (applyFilterCallback) {
        const filterId = getTeamFilterId(selectedTeam, teamsDataQuery);
        if (filterId !== null) {
            filter = { ...filter, teams: [filterId] };
            applyFilterCallback(filter);
        }
    }
};

const getTeamFilterId = (
    teamName: string,
    teamsDataQuery: UseQueryResult<
        | {
              id: string;
              name: string;
          }[]
        | null
    >
): string | null => {
    return teamsDataQuery.data?.find(x => x.name === teamName)?.id ?? null;
};
export { MobileBarChart };
