import { StrictMode } from 'react';

import { PublicClientApplication } from '@azure/msal-browser';
import { createRoot } from 'react-dom/client';

import { App } from './App';

import { setup as msalSetup } from '@features/authentication';
import { startup as applicationStartup, setupAppInsights } from '@features/startup';
import { getAppSettings, msalSettings } from '@shared/settings';

import '@features/internationalization/i18n';
import '@shared/settings/env';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

applicationStartup();

const appSettings = await getAppSettings();

const appInsightsPlugin = setupAppInsights(appSettings.applicationInsightsConnectionString);

const msalInstance = new PublicClientApplication(msalSettings(appSettings));

msalSetup(msalInstance, appSettings);

createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
        <App publicClientApplication={msalInstance} settings={appSettings} appInsightsPlugin={appInsightsPlugin} />
    </StrictMode>
);
