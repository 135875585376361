import {
    Event,
    eventParser,
    GetEventsParam,
    eventPageParser,
    EventPageResponse,
    EventListResponse,
    eventListResponseParser,
} from './domain';

import { agent, queryStringComposer } from '@shared/api';
import { IdNameListResponse, IdNameListResponseParser } from '@shared/CMS';

const apiRoutes = {
    scheduledEventList: '/api/scheduledEventList',
    scheduledEventPage: '/api/scheduledEventPage',
    scheduledEvent: '/api/schduledevent/',
};

export const getEvents = async ({
    name,
    typeIds,
    page,
    pageSize,
    endDate,
    startDate,
    categoryIds,
    location,
    organizer,
    languageIds,
    formatIds,
}: GetEventsParam) => {
    const queryParams = queryStringComposer()
        .addQueryStringValueFor('typeIds', typeIds)
        .addQueryStringValueFor('categoryIds', categoryIds)
        .addQueryStringValueFor('location', location ? [location] : undefined)
        .addQueryStringValueFor('organizer', organizer ? [organizer] : undefined)
        .addQueryStringValueFor('languageIds', languageIds)
        .addQueryStringValueFor('formatIds', formatIds)
        .addQueryStringValueFor('endDate', endDate ? [endDate] : undefined)
        .addQueryStringValueFor('startDate', startDate ? [startDate] : undefined)
        .addQueryStringValueFor('name', name ? [name] : undefined)
        .addQueryStringValueFor('page', [page])
        .addQueryStringValueFor('PageSize', [pageSize])
        .compose();

    const response = await agent.get<EventListResponse>(`${apiRoutes.scheduledEventList}${queryParams}`);

    return eventListResponseParser.parseAsync(response);
};

export const getEventPage = async () => {
    const response = await agent.get<EventPageResponse>(apiRoutes.scheduledEventPage);
    return eventPageParser.parseAsync(response);
};

export const getSingleEvent = async (id: number) => {
    const response = await agent.get<Event>(`/api/scheduledEvent/${id}`);

    return eventParser.parseAsync(response);
};

export const getEventCategories = async () => {
    const response = await agent.get<IdNameListResponse>(`/api/categories`);
    return IdNameListResponseParser.parseAsync(response);
};

export const getEventTypes = async () => {
    const response = await agent.get<IdNameListResponse>(`/api/eventTypes`);
    return IdNameListResponseParser.parseAsync(response);
};

export const getEventLocations = async () => {
    const response = await agent.get<IdNameListResponse>(`/api/locations`);
    return IdNameListResponseParser.parseAsync(response);
};

export const getEventOrganizers = async () => {
    const response = await agent.get<IdNameListResponse>(`/api/organizers`);
    return IdNameListResponseParser.parseAsync(response);
};
