import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { anyFilter, CoInnovationFilter, getTranslationKeyForUnitsOf, SummaryType, toValueType } from '../../shared';

import { getChartData, getChartOptions, plugins } from './chartConfiguration';
import { MobileBarChart } from './MobileBarChart';
import { useDetailByItTeamQuery } from './useDetailByItTeamQuery';

import { useTeamsDataQuery } from '@features/coInnovationFunds/shared/filtering/useFilterMasterDataQuery';
import { useResizeMediaQuery } from '@features/layout';
import { namespaces } from '@shared/constants';
import { FormatNumberOptions } from '@shared/formatting';
import { NoDataContent, useDownloadContext } from '@shared/ui';

const summaryType = SummaryType.Money;

const formatNumberOptions: FormatNumberOptions = {
    simplify: true,
    unitText: getTranslationKeyForUnitsOf(summaryType),
    valueType: toValueType(summaryType),
};

const BY_IT_CHART_ELEMENT_PADDING = 35;

const calculateByItTeamChartHeight = (elementsLength: number) => BY_IT_CHART_ELEMENT_PADDING * elementsLength + 50;

type Props = {
    applyFilterCallback?: (data: CoInnovationFilter) => void;
    reportTemplate?: boolean;
};

const ByItTeamSection = ({ applyFilterCallback, reportTemplate }: Props) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const {
        query: { data: detailByItTeamData },
        filter,
    } = useDetailByItTeamQuery();
    const chartHeight = calculateByItTeamChartHeight(detailByItTeamData?.length || 0);
    const isFiltered = anyFilter(filter);
    const isDownloading = !!useDownloadContext();

    const providedLabel = t('coInnovationFunds.detail.dashboard.byItTeam.provided.title');
    const providedDescription = t('coInnovationFunds.detail.dashboard.byItTeam.provided.description');
    const consumedLabel = t('coInnovationFunds.detail.dashboard.byItTeam.consumed.title');
    const consumedDescription = t('coInnovationFunds.detail.dashboard.byItTeam.consumed.description');
    const { isMobile } = useResizeMediaQuery();
    const teamsDataQuery = useTeamsDataQuery(true);

    return (
        <div className="mb-16 h-full p-5" id="chartByItTeamContainer">
            {detailByItTeamData ? (
                <div>
                    <div>
                        {isMobile || reportTemplate ? (
                            <>
                                <div>
                                    <div className="flex flex-1 items-center ">
                                        <div
                                            className="align-self m-0 inline-block h-[9px] w-[9px] rounded-2xl bg-blue-100 p-0"
                                            data-testid="legend-allocated-color"
                                        ></div>
                                        <div
                                            className="align-self pl-3 pr-6 text-sm font-[400]"
                                            data-testid="legend-allocated-text"
                                        >
                                            {t('coInnovationFunds.detail.dashboard.byItTeam.legendAllocated')}
                                        </div>
                                        <div
                                            className="align-self m-0 inline-block h-[9px] w-[9px] rounded-2xl bg-blue-900 p-0"
                                            data-testid="legend-consumed-color"
                                        ></div>
                                        <div
                                            className="align-self pl-3 pr-6 text-sm font-[400]"
                                            data-testid="legend-consumed-text"
                                        >
                                            {t('coInnovationFunds.detail.dashboard.byItTeam.legendConsumed')}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <MobileBarChart
                                        data={detailByItTeamData}
                                        applyFilterCallback={applyFilterCallback}
                                        filter={filter}
                                        teamsDataQuery={teamsDataQuery}
                                    ></MobileBarChart>
                                </div>
                            </>
                        ) : (
                            <Bar
                                key={chartHeight}
                                id="chartByItTeam"
                                height={chartHeight}
                                data={getChartData(detailByItTeamData)}
                                options={getChartOptions(
                                    detailByItTeamData,
                                    isDownloading,
                                    Math.max(...detailByItTeamData.map(x => x.value)) * 1.6
                                )}
                                plugins={[
                                    plugins(
                                        detailByItTeamData,
                                        formatNumberOptions,
                                        t('coInnovationFunds.detail.dashboard.allocatedAbbreviation'),
                                        filter,
                                        teamsDataQuery,
                                        applyFilterCallback
                                    ),
                                ]}
                                updateMode="resize"
                                className="relative"
                            />
                        )}
                    </div>
                    <div>
                        {isDownloading ? (
                            <div className="col-span-2 mx-16 bg-gray-100 p-4 text-2xs">
                                <p>
                                    <strong>{providedLabel}:</strong> {providedDescription}
                                </p>
                                <p>
                                    <strong>{consumedLabel}:</strong> {consumedDescription}
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <NoDataContent isFiltered={isFiltered} />
            )}
        </div>
    );
};

export { ByItTeamSection };
