import { useEffect, useState } from 'react';

import { PartnerDetails } from './ParnterDetails';
import { PartnersTable } from './PartnersTable';
import { PrioritiesMobileTable } from './PrioritiesMobileTable';
import { useGetPartnersQuery } from './useGetPartnersQuery';

import { useResizeMediaQuery } from '@features/layout';

export const PrioritiesPage = () => {
    const data = useGetPartnersQuery();
    const [selectedPartner, setSelectedPartner] = useState<string | null>(null);
    const { isMobile } = useResizeMediaQuery();

    useEffect(() => {
        if (data && data.length > 0) {
            setSelectedPartner(data[0]!.id);
        }
    }, [data]);

    return (
        <div className="flex flex-grow flex-row" id="priorities">
            {!isMobile ? (
                <div className="m-8 flex flex-row gap-12">
                    <div>
                        {!data ? null : (
                            <PartnersTable
                                partners={data}
                                selectedPartner={selectedPartner}
                                setSelectedPartner={setSelectedPartner}
                            />
                        )}
                    </div>
                    <div>{selectedPartner ? <PartnerDetails partnerId={selectedPartner} /> : null}</div>
                </div>
            ) : (
                <PrioritiesMobileTable
                    partners={data}
                    selectedPartner={selectedPartner}
                    setSelectedPartner={setSelectedPartner}
                />
            )}
        </div>
    );
};
