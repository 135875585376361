import { useId } from 'react';

import { Doughnut } from 'react-chartjs-2';

import { CoInnovationFilter, anyFilter } from '../../shared';
import { getChartData, getChartOptions } from '../charts';

import { legendOptions, htmlLegend, htmlLegendPlugin } from './chartConfiguration';
import { useDetailByGlobalTeamQuery } from './useDetailByGlobalTeamQuery';

import { NoDataContent } from '@shared/ui';
import { useDownloadContext } from '@shared/ui/download';

type Props = {
    applyFilterCallback: (data: CoInnovationFilter) => void;
};
const ByGlobalTeamSection = ({ applyFilterCallback }: Props) => {
    const {
        query: { data: detailByGlobalTeamData },
        filter,
    } = useDetailByGlobalTeamQuery();
    const isFiltered = anyFilter(filter);
    const isDownloading = !!useDownloadContext();

    const uniqueId = useId();
    const legendId = `chartByGlobalTeamLegend${uniqueId}`;
    const containerId = `chartByGlobalTeamList${uniqueId}`;
    return (
        <div className="h-[calc(100%-67px)] p-5">
            {detailByGlobalTeamData ? (
                <div id="chartByGlobalTeamContainer" className="flex h-full flex-col items-center justify-center">
                    <Doughnut
                        data={getChartData(detailByGlobalTeamData)}
                        options={getChartOptions({
                            htmlLegend,
                            legend: legendOptions,
                            data: detailByGlobalTeamData,
                            showDataLabels: isDownloading,
                            disableAnimation: isDownloading,
                        })}
                        id="chartByGlobalTeam"
                        className="mx-auto my-0"
                        plugins={[htmlLegendPlugin(legendId, containerId, applyFilterCallback, filter)]}
                        redraw={true}
                    />
                    <div id={legendId} />
                </div>
            ) : (
                <NoDataContent isFiltered={isFiltered} />
            )}
        </div>
    );
};

export { ByGlobalTeamSection };
