import { useQuery } from '@tanstack/react-query';

import { GetMeInformationResponse } from './domain';

import { agent } from '@shared/api';
import { useStore } from '@store';

export const useMeInformationQuery = () => {
    const localAccountId = useStore(state => state.auth.account?.localAccountId);
    const accessToken = useStore(state => state.auth.accessToken);

    return useQuery<GetMeInformationResponse>(
        ['permissions', { localAccountId }],
        async () => {
            return await agent.get<GetMeInformationResponse>('/me');
        },
        {
            enabled: !!localAccountId && !!accessToken,
        }
    );
};
