import { ReactComponent as InfoIcon } from '@assets/icons/info.svg';

type Props = {
    errors?: string[];
};

const InputErrors = ({ errors }: Props) => {
    if (!errors || errors.length === 0) {
        return null;
    }

    return (
        <div className="text-2xs text-error">
            {errors.map((error, index) => (
                <div key={index} className={'flex items-center [&_svg]:mr-2 [&_svg]:inline-block [&_svg]:w-4'}>
                    <InfoIcon title="info-icon" />
                    {error}
                </div>
            ))}
        </div>
    );
};

export { InputErrors };
