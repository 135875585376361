import { useEffect, useState } from 'react';

import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { matchPath, useLocation } from 'react-router-dom';

import { getRouteData } from '../../router/getRouteData';
import { routeDataObject } from '../../router/routeDataObject';

import { useStore } from '@store';

const getPageName = (isAuthenticated: boolean, pathname: string) => {
    if (!isAuthenticated) {
        return 'Public landing page';
    }

    const routeData = getRouteData(pathname, routeDataObject);

    if (routeData?.name && routeData.name === routeDataObject.landing.name) {
        return `Private ${routeData.name}`;
    }

    const blog = routeDataObject.blog;
    const singleBlog = blog.routes.single;
    if (routeData?.name && routeData.name === singleBlog.name) {
        const match = matchPath(`${blog.path}/${singleBlog.path}`, pathname);

        if (match) {
            const { blogId } = match.params;
            return `${routeData.name} ${blogId}`;
        }
    }

    return routeData?.name || 'unknown';
};

export const usePageViewTracker = () => {
    const { inProgress } = useMsal();
    const { pathname } = useLocation();
    const appInsights = useAppInsightsContext();
    const isAuthenticated = useIsAuthenticated();
    const account = useStore(state => state.auth.account);
    const sessionId = account?.idTokenClaims?.sid;

    const [pageInformation, setPageInformation] = useState({
        pathname,
        pageName: getPageName(isAuthenticated, pathname),
        sessionId,
    });

    const trackPageView = useTrackEvent(appInsights, 'PageView', pageInformation, true);

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            setPageInformation({
                pathname,
                pageName: getPageName(isAuthenticated, pathname),
                sessionId,
            });
        }
    }, [inProgress, pathname, isAuthenticated, sessionId]);

    useEffect(() => {
        trackPageView(pageInformation);
    }, [pageInformation, trackPageView]);
};
