import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CircleQuarter } from '@assets/icons/circleQuarter.svg';

type Props = {
    showText?: boolean;
    size?: number;
};

const Spinner = ({ showText, size = 12 }: Props) => {
    const { t } = useTranslation();

    const doubleSizeStyle = `${size * 2 * 0.25}rem`;
    const sizeStyle = `${size * 0.25}rem`;

    return (
        <div className="flex h-full w-full flex-col items-center justify-center" data-testid="spinnerWrapper">
            <div
                style={{
                    height: doubleSizeStyle,
                    width: doubleSizeStyle,
                }}
                className={'h- relative flex items-center justify-center rounded-full border-8 border-blue-50'}
                data-testid="spinner-circle"
            >
                <CircleQuarter
                    style={{
                        height: sizeStyle,
                        width: sizeStyle,
                    }}
                    className={'absolute bottom-[-8px] right-[-8px] origin-top-left animate-spin'}
                />
            </div>

            <span className={classNames(showText ? 'mt-5 text-2xs text-blue-900' : 'sr-only')}>
                {t('global.loading')}
            </span>
        </div>
    );
};

export { Spinner };
