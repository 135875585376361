export const namespaces = {
    features: {
        blog: 'features.blog',
        report: 'features.report',
        landing: 'features.landing',
        coInnovationFunds: 'features.coInnovationFunds',
        innovationEcosystem: 'features.innovationEcosystem',
        events: 'features.events',
        genai: 'features.genaiUseCases',
    },
    common: 'common',
};

export const languages = {
    es: 'es',
    en: 'en',
};
