import { useMutation } from '@tanstack/react-query';

import { cancelCR } from './api';

type Params = {
    fundId: string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export const useCrCancelMutation = ({ fundId, onSuccess, onError }: Params) =>
    useMutation(async () => cancelCR(fundId), {
        onSuccess,
        onError: onError ? (error: Error) => onError(error) : undefined,
    });
