const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const safari = ua.indexOf('Safari');
export const isIE = msie > 0 || msie11 > 0;
export const isEdge = msedge > 0;
export const isFirefox = firefox > 0;
export const isSafari = safari > 0;
