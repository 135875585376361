import { PropsWithChildren } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { CoInnovationFilter, FilterForms } from './domain';

import { useStore } from '@store';

type Props = {
    applyFilterCallback: (data: CoInnovationFilter) => void;
    formName: FilterForms;
};

const FilterContent = ({ applyFilterCallback, formName, children }: PropsWithChildren<Props>) => {
    const { filter } = useStore(state => state.coInnovationFunds);
    const methods = useForm<CoInnovationFilter>({
        defaultValues: {
            globalTeams: filter?.globalTeams ?? [],
            horizons: filter?.horizons ?? [],
            partners: filter.partners ?? [],
            teams: filter.teams ?? [],
            years: filter.years ?? [],
            phases: filter.phases ?? [],
            projectName: filter.projectName ?? '',
            from: filter.from ?? undefined,
            to: filter.to ?? undefined,
            statuses: filter.statuses ?? [],
            approvers: filter.approvers ?? [],
        },
    });

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit((data: CoInnovationFilter) => applyFilterCallback(data))}
                id={formName}
                role={'form'}
            >
                <div className="grid gap-3">{children}</div>
            </form>
        </FormProvider>
    );
};

export { FilterContent };
