import { formatNumber } from '../../../../../formatting';
import { CellProps } from '../domain';

import { CellBase } from './base';

const CurrencyCell = <T extends object>({ value, context, meta }: CellProps<T>) => (
    <div className="flex flex-col">
        <CellBase
            text={formatNumber(Number(value))}
            context={context}
            meta={meta}
            alignContent="right"
            multiLineData={value === undefined ? undefined : 'CHF'}
        />
    </div>
);

export { CurrencyCell };
