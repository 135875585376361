import { PropsWithChildren } from 'react';

import classNames from 'classnames';

type Props = {
    noPadding?: boolean;
};

const DialogFooter = ({ children, noPadding }: PropsWithChildren<Props>) => {
    return (
        <footer
            className={classNames('border-t border-blue-100 text-right', {
                'p-5': !noPadding,
            })}
        >
            {children}
        </footer>
    );
};

export { DialogFooter };
