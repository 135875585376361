import { useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getProjectList } from './api';
import { DetailProjects, coInnovationFundsDetailProjectsParser } from './domain';

import { anyFilter } from '@features/coInnovationFunds/shared';
import { retrieveFromCache, saveToCache } from '@shared/caching';
import { PagerProps } from '@shared/ui';
import { SortingProps } from '@shared/ui/sorting';
import { useStore } from '@store';

type Props = {
    paginationReset?: () => void;
};

const queryKey = 'projectsDetails';

export const useProjectsDetailsQuery = ({
    skip,
    take,
    id: orderBy,
    desc,
    paginationReset,
}: PagerProps & SortingProps & Props) => {
    const initialRun = useRef(true);
    const filter = useStore(state => state.coInnovationFunds.filter);
    const [param, setParam] = useState({ filter, skip, take });

    useEffect(() => {
        if (!initialRun.current) {
            setParam(previousParam => {
                if (paginationReset && previousParam.skip !== 0) {
                    paginationReset();
                }

                return { ...previousParam, filter: { ...filter }, skip: 0 };
            });
        }
    }, [filter, paginationReset]);

    useEffect(() => {
        if (!initialRun.current) {
            setParam(previousParam => ({ ...previousParam, skip, take }));
        } else {
            initialRun.current = false;
        }
    }, [skip, take]);

    useEffect(() => {
        return () => {
            initialRun.current = true;
        };
    }, []);

    const page = param.skip / param.take;

    const isCacheEnabled = page === 0 && !anyFilter(param.filter) && !orderBy && !desc;

    const query = useQuery<DetailProjects | null>(
        [queryKey, { page, filter: param.filter, orderBy, desc }],
        async () => {
            const response = await getProjectList(skip, take, filter, orderBy, desc);
            if (isCacheEnabled) {
                saveToCache(queryKey, response);
            }
            return response;
        },
        {
            keepPreviousData: true,
            suspense: false,
            placeholderData: () => {
                if (isCacheEnabled) {
                    return retrieveFromCache(queryKey, coInnovationFundsDetailProjectsParser.parse);
                }
                return undefined;
            },
        }
    );

    return { query, filter };
};
