import { lazy } from 'react';

const LazyBlogPage = lazy(() => import('@pages/BlogHomePage'));
const LazyErrorPage = lazy(() => import('@pages/ErrorPage'));
const LazyEventsPage = lazy(() => import('@pages/EventsPage'));
const LazyDashboardPage = lazy(() => import('@pages/DashboardPage'));
const LazyGenAiPage = lazy(() => import('@pages/GenAiPage'));
const LazySingleBlogPage = lazy(() => import('@pages/SingleBlogPage'));
const LazyPublicLandingPage = lazy(() => import('@pages/PublicLandingPage'));
const LazyPrivateLandingPage = lazy(() => import('@pages/PrivateLandingPage'));
const LazyInnovationEcosystemPage = lazy(() => import('@pages/InnovationEcosystemPage'));

export {
    LazyBlogPage,
    LazyErrorPage,
    LazyEventsPage,
    LazyDashboardPage,
    LazyGenAiPage,
    LazySingleBlogPage,
    LazyPublicLandingPage,
    LazyPrivateLandingPage,
    LazyInnovationEcosystemPage,
};
