import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SummaryType, anyFilter, getTranslationKeyForUnitsOf, toValueType } from '../../../shared';

import { useSummaryQuery } from './useSummaryQuery';

import { useResizeMediaQuery } from '@features/layout';
import { blue900Color, tealColor, purpleColor, namespaces, primaryColor } from '@shared/constants';
import { FormatNumberOptions, formatNumber } from '@shared/formatting';
import { NoDataContent, BarChart, BarChartLabel, useDownloadContext, Spinner } from '@shared/ui';

type Props = {
    summaryType: SummaryType;
};

const FundsCharts = ({ summaryType }: Props) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const returnLabelWhenDownloading = (label: { id: string; content: string }, isDownloading: boolean) =>
        isDownloading ? undefined : label;
    const {
        query: { data: summaryData, isLoading },
        filter,
    } = useSummaryQuery({ summaryType, disableSuspense: true });
    const isDownloading = !!useDownloadContext();
    const { windowWidth } = useResizeMediaQuery();

    const formatNumberOptions: FormatNumberOptions = {
        simplify: true,
        minimumFractionDigits: 0,
        valueType: toValueType(summaryType),
        unitText: t(getTranslationKeyForUnitsOf(SummaryType.Money)),
    };

    const isFiltered = anyFilter(filter);

    const consumedLabel = t('coInnovationFunds.summary.consumed.title');
    const allocatedLabel = t('coInnovationFunds.summary.allocated.title');
    const providedLabel = t('coInnovationFunds.summary.provided.title');
    const availableLabel = t('coInnovationFunds.summary.available.title');
    const consumedDescription = t('coInnovationFunds.summary.consumed.chartDescription');
    const allocatedDescription = t('coInnovationFunds.summary.allocated.chartDescription');
    const availableDescription = t('coInnovationFunds.summary.available.chartDescription');
    const providedDescription = t('coInnovationFunds.summary.provided.description');

    const borderColor = isDownloading ? tealColor : blue900Color;
    const chartData = summaryData
        ? {
              labels: [t('coInnovationFunds.title')],
              responsive: true,
              datasets: [
                  {
                      label: consumedLabel,
                      data: [summaryData.consumed],
                      backgroundColor: blue900Color,
                      barThickness: 60,
                  },
                  {
                      label: allocatedLabel,
                      data: [summaryData.remaining],
                      backgroundColor: tealColor,
                      barThickness: 60,
                      borderColor,
                      borderWidth: 2,
                  },
                  {
                      label: providedLabel,
                      data: [summaryData.provided - summaryData.allocated],
                      backgroundColor: purpleColor,
                      barThickness: 60,
                  },
              ],
          }
        : null;

    const hasAllData = chartData && summaryData;

    return (
        <div
            className={classNames('relative flex w-full flex-col md:h-full md:justify-center', {
                'rounded border border-blue-100': !isDownloading,
            })}
        >
            {isLoading ? (
                <div className="min-h-9 flex h-full w-full justify-center place-self-center p-2">
                    <Spinner />
                </div>
            ) : hasAllData ? (
                <div
                    className={classNames('', {
                        'grid grid-cols-2 gap-x-6 p-2 md:gap-x-20': !isDownloading,
                        'flex items-center': isDownloading,
                    })}
                >
                    <div
                        className={classNames('flex items-center', {
                            'mr-8 w-1/3': isDownloading,
                        })}
                    >
                        <BarChart
                            key={windowWidth}
                            data={chartData}
                            isDownloading={isDownloading}
                            formatNumberOptions={formatNumberOptions}
                            extraDataObject={{
                                [consumedLabel]: { percentage: summaryData.consumedPercentageOfAllocated },
                                [allocatedLabel]: {
                                    percentage: summaryData.allocatedPercentageOfAllocatedPlusAvailable,
                                    valueReplacement: formatNumber(summaryData.allocated, formatNumberOptions),
                                },
                                [providedLabel]: {
                                    valueReplacement: formatNumber(summaryData.provided, formatNumberOptions),
                                },
                            }}
                        />
                    </div>

                    <div
                        className={classNames({
                            'flex flex-col justify-center py-2': !isDownloading,
                            'grid flex-1 grid-cols-2 gap-x-5 [grid-auto-rows:min-content]': isDownloading,
                        })}
                    >
                        <BarChartLabel
                            label={providedLabel}
                            value={formatNumber(summaryData.provided, formatNumberOptions)}
                            color={purpleColor}
                            tooltip={returnLabelWhenDownloading(
                                {
                                    id: 'providedTooltip',
                                    content: providedDescription,
                                },
                                isDownloading
                            )}
                        />

                        <BarChartLabel
                            label={allocatedLabel}
                            value={formatNumber(summaryData.allocated, formatNumberOptions)}
                            percentage={summaryData.allocatedPercentageOfAllocatedPlusAvailable}
                            color={tealColor}
                            tooltip={returnLabelWhenDownloading(
                                {
                                    id: 'allocatedTooltip',
                                    content: allocatedDescription,
                                },
                                isDownloading
                            )}
                        />

                        <BarChartLabel
                            label={consumedLabel}
                            value={formatNumber(summaryData.consumed, formatNumberOptions)}
                            percentage={summaryData.consumedPercentageOfAllocated}
                            color={blue900Color}
                            tooltip={returnLabelWhenDownloading(
                                {
                                    id: 'consumedTooltip',
                                    content: consumedDescription,
                                },
                                isDownloading
                            )}
                        />

                        <BarChartLabel
                            label={availableLabel}
                            value={formatNumber(summaryData.available, formatNumberOptions)}
                            tooltip={returnLabelWhenDownloading(
                                {
                                    id: 'availableTooltip',
                                    content: availableDescription,
                                },
                                isDownloading
                            )}
                            textColor={primaryColor}
                        />

                        {isDownloading ? (
                            <div className="col-span-2 mr-10 bg-gray-100 p-4 text-2xs">
                                <p>
                                    <strong>{providedLabel}:</strong> {providedDescription}
                                </p>

                                <p>
                                    <strong>{allocatedLabel}:</strong> {allocatedDescription}
                                </p>

                                <p>
                                    <strong>{consumedLabel}:</strong> {consumedDescription}
                                </p>

                                <p>
                                    <strong>{availableLabel}:</strong> {availableDescription}
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <NoDataContent isFiltered={isFiltered} />
            )}
        </div>
    );
};

export { FundsCharts };
