import { ReactNode, forwardRef } from 'react';

import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { useController, useFormContext } from 'react-hook-form';

import { InputErrors } from '../inputErrors';
import { Label } from '../label';

import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import './DatePickerControl.scss';

type CustomInputProps = {
    value?: string;
    onClick?: () => void;
    onChange?: () => void;
    error?: boolean;
    id?: string;
    name?: string;
};

const CustomDatePickerInput = forwardRef<HTMLInputElement, CustomInputProps>(
    ({ value, onClick, onChange, error, id, name }, ref) => (
        <>
            <input
                value={value}
                onClick={onClick}
                onChange={onChange}
                ref={ref}
                placeholder="dd/mm/yyyy"
                id={id}
                name={name}
                autoComplete="off"
            />
            <span
                className={classNames('inline-flex items-center border-l  px-[6px] ', {
                    'border-blue-900 text-blue-900': !error,
                    'border-error text-error': error,
                })}
                onClick={onClick}
            >
                <CalendarIcon />
            </span>
        </>
    )
);
CustomDatePickerInput.displayName = 'CustomDatePickerInput';

type Props = {
    name: string;
    id: string;
    dateFormat: string;
    selected: Date | null;
    label?: ReactNode | undefined;
    containerClassName?: string;
    errors?: string[];
};

const StyledDatePickerControl = ({ id, dateFormat, label, name, containerClassName, errors }: Props) => {
    const { control } = useFormContext();
    const { field } = useController({
        name: name,
        control,
    });
    return (
        <div
            className={classNames(
                'customDatePicker',
                { selected: !!field.value, error: !!errors },
                containerClassName ? containerClassName : 'mb-[30px]'
            )}
        >
            <Label id={id}>{label}</Label>

            <DatePicker
                popperClassName="customDatePickerWidth"
                showPopperArrow={false}
                dateFormat={dateFormat}
                id={id}
                selected={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
                useWeekdaysShort={true}
                popperPlacement="bottom"
                customInput={<CustomDatePickerInput error={!!errors} id={id} name={name} />}
            />

            <InputErrors errors={errors} />
        </div>
    );
};

export { StyledDatePickerControl };
