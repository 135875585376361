import { useQuery } from '@tanstack/react-query';

import { getActiveCrByFundId } from '@features/coInnovationFunds/funds/api';
import { changeRequestParser } from '@features/coInnovationFunds/funds/domain';
import { retrieveFromCache, saveToCache } from '@shared/caching';

const queryKey = 'changeRequest';

export const useGetActveCrByFundIdQuery = (id: string) => {
    return useQuery([queryKey, id], async () => getActiveCrByFundId(id), {
        onSuccess: data => saveToCache(`${queryKey}-${id}`, data),
        placeholderData: () => retrieveFromCache(`${queryKey}-${id}`, changeRequestParser.parse),
    });
};
