import { Dispatch, SetStateAction } from 'react';

import { FundValidationDialog } from '../summary/funds/FundValidationDialog';

import { CoInnovationFund } from './domain';
import { FundCancelDialog } from './FundCancelDialog';
import { SelectedFundState } from './Funds';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';

const canCancelFundPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];
const canValidateFundPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

type FundDialogsProps = {
    selectedFundState: SelectedFundState | undefined;
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
    data: CoInnovationFund | undefined;
    onClose: () => void;
};

export const FundDetailDialog = ({ setSelectedFundState, selectedFundState, data, onClose }: FundDialogsProps) => {
    return (
        <>
            <HasPermission neededPermissions={canCancelFundPermissions}>
                <FundCancelDialog
                    setSelectedFundState={setSelectedFundState} // Ensure setSelectedFundState is passed down correctly
                    selectedFund={selectedFundState?.types.includes('cancel') ? data : undefined}
                />
            </HasPermission>
            <HasPermission neededPermissions={canValidateFundPermissions}>
                <FundValidationDialog
                    isOpen={!!selectedFundState?.types.find(type => ['approve', 'reject'].includes(type))}
                    id={selectedFundState?.id || ''}
                    modalType={selectedFundState?.types.includes('approve') ? 'approve' : 'reject'}
                    onClose={onClose}
                />
            </HasPermission>
        </>
    );
};
