import { z } from 'zod';

import { MultivalueChart, SummaryType, toValueType } from '../../shared';
import { createChartColors } from '../charts';

import { accessibleActiveColor, blackColor, whiteColor } from '@shared/constants';
import { formatNumber, FormatNumberOptions } from '@shared/formatting';

const HORIZON_KEY = 'H';
const IDEAL = 'ideal';

const horizonDeviationAlarmTextColor = whiteColor;
const horizonDeviationOkTextColor = whiteColor;
const horizonDeviationWarningTextColor = blackColor;
const horizonDeviationAlarmColor = '#D50037';
const horizonDeviationOkColor = accessibleActiveColor;
const horizonDeviationWarningColor = '#FFC600';

export type MultivalueChartWithLegendColor = MultivalueChart & {
    thresholdDeviationStatus: string;
    thresholdDeviationValue: number;
};

export const chartBackgroundColor: Record<string, string> = {
    Ok: horizonDeviationOkColor,
    Warning: horizonDeviationWarningColor,
    Alarm: horizonDeviationAlarmColor,
};

export const chartTextColor: Record<string, string> = {
    Ok: horizonDeviationOkTextColor,
    Warning: horizonDeviationWarningTextColor,
    Alarm: horizonDeviationAlarmTextColor,
};

const thresholdDeviationValues = ['Ok', 'Alarm', 'Warning'];

export const coInnovationFundsDetailsByHorizonDashboardSectionParser = z.array(
    z.object({
        horizonName: z.string().min(1),
        percent: z.number().min(0).max(100),
        thresholdPercent: z.number().min(0).max(100),
        thresholdDeviationValue: z.number().min(-100).max(100),
        thresholdDeviationStatus: z.string().refine(r => {
            return thresholdDeviationValues.includes(r);
        }),
        totalAllocated: z.number().min(0),
    })
);

export type DetailByHorizonItemValue = z.infer<typeof coInnovationFundsDetailsByHorizonDashboardSectionParser>;

export const formatData = (
    detailByHorizonData: DetailByHorizonItemValue,
    summaryType: SummaryType,
    unitText: string
) => {
    const colors = createChartColors();
    return detailByHorizonData?.map((detailByHorizon, i) => {
        const horizonText = detailByHorizon.horizonName.replace(HORIZON_KEY, 'Horizon ');
        const HorizonIdeal = `(${detailByHorizon.thresholdPercent}% ${IDEAL})`;
        const formatNumberOptions: FormatNumberOptions = {
            valueType: toValueType(summaryType),
            simplify: false,
            unitText,
        };

        return {
            text: `${detailByHorizon.percent}%`,
            tooltip: `${formatNumber(detailByHorizon.totalAllocated, formatNumberOptions)} (${
                detailByHorizon.percent
            })%`,
            value: detailByHorizon.percent,
            color: colors[i] as string,
            legend: `${horizonText};${detailByHorizon.percent}% ${HorizonIdeal}`,
            thresholdDeviationStatus: detailByHorizon.thresholdDeviationStatus,
            thresholdDeviationValue: detailByHorizon.thresholdDeviationValue,
        };
    });
};
