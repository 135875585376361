import create, { StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSlice as createAuthSlice, Slice as AuthSlice } from '@features/authentication/slice';
import {
    createSlice as createCoInnovationFundSlice,
    Slice as CoInnovationFundSlice,
} from '@features/coInnovationFunds/shared/slice';
import { createSlice as createProductCatalogSlice, Slice as ProductCatalogSlice } from '@features/productCatalog/slice';
import { createSlice as createGenaiSlice, Slice as GenaiSlice } from '@features/reportGenaiUseCases/filtering/slice';
import { createSlice as createServiceNowSlice, Slice as ServiceNowSlice } from '@features/serviceNow/slice';

export type StoreState = AuthSlice & ServiceNowSlice & ProductCatalogSlice & CoInnovationFundSlice & GenaiSlice;

export type StoreSlice<T> = (set: StoreApi<T>['setState'], get: StoreApi<T>['getState']) => T;

export const useStore = create<StoreState>()(
    devtools((...all) => ({
        ...createAuthSlice(...all),
        ...createServiceNowSlice(...all),
        ...createProductCatalogSlice(...all),
        ...createCoInnovationFundSlice(...all),
        ...createGenaiSlice(...all),
    }))
);
