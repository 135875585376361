import { z } from 'zod';

import { PaginationModel } from '@shared/CMS';
import { emptyUUID } from '@shared/constants';
import { SortingProps } from '@shared/ui/sorting';

export const summaryPartnersParser = z.array(
    z.object({
        partnerId: z
            .string()
            .uuid()
            .refine(validation => validation !== emptyUUID),
        name: z.string().min(1),
        allocated: z.number().gte(0),
        consumed: z.number().gte(0),
        provided: z.number().gte(0),
        carryForward: z.number().gte(0),
        available: z.number(),
        expired: z.number().gte(0),
        consumedExpirationDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    })
);

export const summaryPartnersHistory = z.object({
    year: z.number().max(new Date().getFullYear()),
    projNo: z.number().min(0),
    h1: z.number().optional(),
    h2: z.number().optional(),
    h3: z.number().optional(),
    consumed: z.number().gte(0),
});

export const summaryPartnersHistoryParser = z.array(summaryPartnersHistory);

export type SummaryPartners = z.infer<typeof summaryPartnersParser>;

export type SummaryPartnersHistory = z.infer<typeof summaryPartnersHistoryParser>;

export type FundsPartnerHistory = {
    year: number;
    consumed: number;
    projNo: number;
    [key: string]: number;
};

const fundsSummaryItemBaseParser = z.object({
    provided: z.number().gte(0),
    carryForward: z.number().gte(0),
    allocated: z.number().gte(0),
    consumed: z.number().gte(0),
    available: z.number(), //currently for some partners the available is smaller than 0
    expired: z.number().gte(0),
    consumedExpirationDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
});

const fundsSummarySubItemParser = fundsSummaryItemBaseParser.extend({
    beginDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
});

const fundsSummaryItemParser = fundsSummaryItemBaseParser.extend({
    partnerId: z
        .string()
        .uuid()
        .refine(validation => validation !== emptyUUID),
    name: z.string().min(1),
    details: z.array(fundsSummarySubItemParser),
});

const fundsByPartnerSubItemParser = z.object({
    partnerId: z
        .string()
        .uuid()
        .refine(validation => validation !== emptyUUID),
    name: z.string().min(1),
    provided: z.number().gte(0),
    allocated: z.number().gte(0),
    consumed: z.number().gte(0),
    available: z.number(),
    expired: z.number().gte(0),
});

const fundsByPartnerItemParser = z.object({
    partnerId: z
        .string()
        .uuid()
        .refine(validation => validation !== emptyUUID),
    name: z.string().min(1),
    provided: z.number().gte(0),
    allocated: z.number().gte(0),
    consumed: z.number().gte(0),
    available: z.number(),
    expired: z.number().gte(0),
    subData: z.array(fundsByPartnerSubItemParser).optional(),
    subRow: z.boolean().optional(),
});

export const fundsSummaryParser = z.array(fundsSummaryItemParser);
export const fundsByPartnerParser = z.array(fundsByPartnerItemParser);

export const coInnovationFundListItemParser = z.object({
    id: z
        .string()
        .uuid()
        .refine(validation => validation !== emptyUUID),
    partner: z.object({
        id: z
            .string()
            .uuid()
            .refine(validation => validation !== emptyUUID),
        name: z.string().min(1),
    }),
    provided: z.number().gte(0),
    carryForward: z.number().min(0).gte(0),
    expired: z.number().gte(0),
    beginDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    consumptionExpirationDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    statusId: z.number(),
    submissionDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    allocated: z.number().optional(),
    consumed: z.number().optional(),
    available: z.number().optional(),
});

export const coInnovationFundListResponseParser = z.object({
    total: z.number().min(0),
    totalProvided: z.number().min(0),
    totalAllocated: z.number().min(0),
    totalConsumed: z.number().min(0),
    totalExpired: z.number().min(0),
    items: z.array(coInnovationFundListItemParser),
});

export type GetFundsParam = PaginationModel & {
    partnerIds?: string[];
    validatorIds?: string[];
    statusIds?: number[];
    sorting: SortingProps;
    years?: number[];
};

export type FundsSummaryResponse = z.infer<typeof fundsSummaryParser>;
export type FundsByPartnerResponse = z.infer<typeof fundsByPartnerParser>;
export type ByFundResponse = z.infer<typeof coInnovationFundListResponseParser>;
