import { flexRender, Row } from '@tanstack/react-table';
import classNames from 'classnames';

import { NoDataContent } from '../noData';
import { Spinner } from '../spinner';

import { CustomColumn } from './column';
import classes from './table.module.scss';

type Props<T extends object> = {
    rows: Row<T>[];
    isLoading: boolean;
    columns: CustomColumn<T>[];
    selectedRowCallback?: (id: string) => void;
    columnCallbacks: Record<string, (id: string) => void>;
    isFiltered?: boolean;
    expectedBodyHeightClass?: string;
};

const TableBody = <T extends object>({
    isLoading,
    columns,
    rows,
    selectedRowCallback,
    columnCallbacks,
    isFiltered,
    expectedBodyHeightClass,
}: Props<T>) => {
    const columnsNumber = columns.filter(f => f.visible !== false).length;
    if (isLoading || rows.length === 0) {
        return (
            <tbody>
                <tr className={classNames(expectedBodyHeightClass ? expectedBodyHeightClass : 'h-[200px]')}>
                    <td className="text-center" colSpan={columnsNumber}>
                        {isLoading ? <Spinner /> : <NoDataContent isFiltered={isFiltered} />}
                    </td>
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            {rows.map(function (row, index) {
                const idName = columns.find(f => f.cellType === 'id')?.accessorKey;
                const id = row
                    .getAllCells()
                    .find(cell => cell.column.id === idName)
                    ?.getValue<string>();

                const rowAriaLabel = `Fund row details {idName}: ${id}`;

                return (
                    <tr
                        key={row.id}
                        onClick={() => selectedRowCallback && id && selectedRowCallback(id)}
                        aria-label={rowAriaLabel}
                        data-testid="project-row"
                    >
                        {row.getVisibleCells().map(cell => {
                            const callback = columnCallbacks[cell.column.id];
                            const cellAriaLabel = `Column ${cell.column.id}`;
                            const isSubRow = 'subRow' in row.original;
                            const original = rows[index + 1]?.original;
                            const isSelected = original && 'subRow' in original;
                            return (
                                <td
                                    key={cell.id}
                                    onClick={id && callback ? () => callback(id) : undefined}
                                    className={classNames({
                                        [classes.button || '']: !!(id && callback),
                                        ['bg-gray-50' || '']: isSubRow,
                                        ['bg-gray-200' || '']: isSelected && !isSubRow,
                                    })}
                                    aria-label={id && callback ? cellAriaLabel : undefined}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

export { TableBody };
