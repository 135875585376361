import { ReportDetail, Reports, reportDetailValidator } from './domain';

import { agent, queryStringComposer } from '@shared/api';

const apiRoutes = {
    getReportList: '/api/reportList',
};

export interface GetReportListParams {
    tags?: string[];
    itStream?: string[];
    leads?: string[];
    partners?: string[];
    marketsAndRegions?: string[];
    status?: string[];
}

export const getReportList = async (params: GetReportListParams): Promise<Reports | null> => {
    const queryParams = queryStringComposer()
        .addQueryStringValueFor('Tags', params.tags)
        .addQueryStringValueFor('ITStream', params.itStream)
        .addQueryStringValueFor('Leads', params.leads)
        .addQueryStringValueFor('Partners', params.partners)
        .addQueryStringValueFor('MarketsAndRegions', params.marketsAndRegions)
        .addQueryStringValueFor('Status', params.status)
        .compose();
    const response = await agent.get<Reports>(`${apiRoutes.getReportList}${queryParams}`);
    return response ? response : null;
};

export const getReportDetail = async (id: string) => {
    const response = await agent.get<ReportDetail>(`/api/reportdetail?Id=${id}`);

    return reportDetailValidator.parseAsync(response);
};
