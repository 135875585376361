import { Suspense } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { DownloadEvent } from './DownloadEvent';
import { EventLabel } from './EventLabel';
import { ShareEvent } from './ShareEvent';
import { useSingleEventQuery } from './useSingleEvent';

import { ReactComponent as ArrowLeft } from '@assets/icons/arrowLeft.svg';
import { namespaces } from '@shared/constants';
import { formatUTCPlusOne } from '@shared/formatting';
import { Button } from '@shared/ui';

const SingleEvent = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const navigate = useNavigate();

    const { data } = useSingleEventQuery(Number(eventId));
    const { t } = useTranslation(namespaces.features.events);

    if (!data) {
        return <></>;
    }

    const {
        endDate,
        location,
        organizer,
        startDate,
        contact,
        format,
        categories,
        teamsLink,
        venue,
        description,
        language,
        eventType,
        title,
        resourceList,
    } = data;

    return (
        <Suspense>
            <div className="container flex flex-grow animate-fade-in flex-row pt-header-height" id="single-event">
                <article className="flex w-full flex-grow animate-fade-in flex-col">
                    <Button
                        onClick={() => navigate(`/events`)}
                        className="btn-link-outline-style pt-8 pb-8"
                        aria-label="Go back"
                    >
                        <ArrowLeft className="stroke-current" />
                    </Button>

                    <h2 className="mb-3 flex flex-1 pt-2 text-xl font-light md:text-3xl">{title}</h2>

                    <div className="flex w-full gap-8 pt-8 pb-8" id="event-details-id">
                        <div className="flex flex-grow flex-col gap-6">
                            <div>
                                <h3 className="mb-3 text-xl font-bold">{t('details.description')}</h3>

                                <p className="text-sm font-light">{description}</p>
                            </div>
                            <div>
                                <h3 className="mb-3 text-xl font-bold">{t('details.organizer')}</h3>

                                <p className="text-sm font-light">{organizer}</p>
                            </div>
                            <div>
                                <h3 className="mb-3 text-xl font-bold">{t('details.contact')}</h3>

                                <p className="text-sm font-light">{contact}</p>
                            </div>

                            {/* <h3 className="mb-3 text-xl font-bold">{t('details.web')}</h3> */}
                            <div>
                                <h3 className="mb-3 text-xl font-bold">{t('details.format')}</h3>

                                <p className="text-sm font-light">{format}</p>
                            </div>

                            <div>
                                <h3 className="mb-3 text-xl font-bold">{t('details.venue')}</h3>

                                <p className="text-sm font-light">{venue}</p>
                            </div>

                            <div>
                                <h3 className="mb-3 text-xl font-bold">{t('details.teamsLink')}</h3>

                                <p className="text-sm font-light">{teamsLink}</p>
                            </div>
                        </div>

                        <div className="flex w-[260px] flex-shrink-0 flex-col gap-[6px]">
                            <div className="rounded-lg border border-blue-100 p-4">
                                <h3 className="mb-3 text-xl font-bold">{t('details.date')}</h3>

                                <div className="flex flex-col text-sm font-light">
                                    <div className="flex gap-2">
                                        <h3 className="mb-2 w-[45px] text-base font-bold">{t('details.from')}: </h3>
                                        <p className="flex-1 text-base">{formatUTCPlusOne(new Date(startDate))}</p>
                                    </div>

                                    <div className="flex gap-2">
                                        <h3 className="mb-2 flex w-[45px] justify-end text-base font-bold">
                                            {t('details.to')}:{' '}
                                        </h3>
                                        <p className="flex-1 text-base">{formatUTCPlusOne(new Date(endDate))}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-lg border border-blue-100 p-4">
                                <h3 className="mb-3 text-xl font-bold">{t('details.location')}</h3>
                                <p className="text-base font-light">{location}</p>
                            </div>
                            <div className="rounded-lg border border-blue-100 p-4">
                                <h3 className="mb-3 text-xl font-bold">{t('details.language')}</h3>
                                <p className="text-base font-light">{language?.name}</p>
                            </div>
                            <div className="rounded-lg border border-blue-100 p-4">
                                <h3 className="mb-3 text-xl font-bold">{t('details.eventType')}</h3>
                                <p className="text-base font-light">{eventType?.name}</p>
                            </div>
                            <div className="rounded-lg border border-blue-100 p-4">
                                <h3 className="mb-3 text-xl font-bold">{t('details.category')}</h3>

                                <div className="flex">
                                    {categories &&
                                        categories.map(({ name, id }) => <EventLabel key={id} id={id} name={name} />)}
                                </div>
                            </div>

                            <div className="rounded-lg border border-blue-100 p-4">
                                <h3 className="mb-3 text-xl font-bold">{t('details.resourceList')}</h3>

                                <div className="flex">
                                    {resourceList?.map(({ name, url, id }) => (
                                        <a key={id} className="text-blue-accessible underline" href={url}>
                                            {name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full justify-end gap-2 pt-5 pb-8">
                        <ShareEvent />
                        <DownloadEvent
                            eventDetails={{
                                title: title,
                                description: description,
                                startDate: startDate,
                                endDate: endDate,
                                location: location || '',
                                meetingUrl: teamsLink || '',
                            }}
                        />
                    </div>
                </article>
            </div>
        </Suspense>
    );
};

export { SingleEvent };
