import { formatPercentage } from '../../../../../formatting';
import { CellProps } from '../domain';

import { CellBase } from './base';

const PercentageCell = <T extends object>({ value, context, meta }: CellProps<T>) => {
    return <CellBase text={formatPercentage(value)} context={context} meta={meta} alignContent="right" />;
};

export { PercentageCell };
