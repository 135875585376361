import { useQuery } from '@tanstack/react-query';

import { getSingleEvent } from './api';
import { eventParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const eventQueryKey = 'event';

export const useSingleEventQuery = (id: number) => {
    return useQuery([eventQueryKey, id], async () => getSingleEvent(id), {
        onSuccess: data => saveToCache(`${eventQueryKey}-${id}`, data),
        placeholderData: () => retrieveFromCache(`${eventQueryKey}-${id}`, eventParser.parse),
    });
};
