import produce from 'immer';
import { StateCreator } from 'zustand';

import { GenaiFilter, emptyFilterValue } from './domain';

export type Slice = {
    genai: {
        filter: GenaiFilter;
        setFilter: (filter: GenaiFilter) => void;
        showGenaiFilterDialog: boolean;
        setShowGenaiFilterDialog: (shouldShow: boolean) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    genai: {
        filter: emptyFilterValue,
        setFilter: (filter: GenaiFilter) =>
            set(state =>
                produce(state, draft => {
                    draft.genai.filter = filter;
                })
            ),
        showGenaiFilterDialog: false,
        setShowGenaiFilterDialog: (shouldShow: boolean) =>
            set(state =>
                produce(state, draft => {
                    draft.genai.showGenaiFilterDialog = shouldShow;
                })
            ),
    },
});
