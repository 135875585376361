import { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { CoInnovationFundChangeRequestParam, CoInnovationFundListItem } from './domain';
import { ChangeRequestFormState, FundChangeRequestForm } from './FundChangeRequestForm';
import { SelectedFundState } from './Funds';
import { useChangeRequestMutation } from './useChangeRequestMutation';

import { namespaces } from '@shared/constants';
import {
    Link,
    Button,
    Spinner,
    DialogBody,
    DialogError,
    DialogFooter,
    DialogHeader,
    DialogContainer,
} from '@shared/ui';
import { useStore } from '@store';

type Props = {
    selectedFund?: CoInnovationFundListItem;
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
};

const numberToDisplayString = (value: number | undefined): string => {
    return value?.toString().replace('.', ',') ?? '0';
};

const displayStringToNumberValidString = (value: string | undefined): string => {
    return value?.replace(',', '.') ?? '0';
};

const convertFormStateToParams = (
    params: Partial<ChangeRequestFormState>,
    defaultFormState: any
): CoInnovationFundChangeRequestParam => {
    const fundId = defaultFormState.fundId;
    const provided = displayStringToNumberValidString(params.provided);
    const expired = displayStringToNumberValidString(params.expired);
    const carriedForward = displayStringToNumberValidString(params.carriedForward);
    const comment = params.submissionComment || '';

    return {
        fundId,
        provided,
        expired,
        carriedForward,
        comment,
    };
};

export const FundChangeRequestDialog = ({ setSelectedFundState, selectedFund }: Props) => {
    const { t } = useTranslation();
    const { t: fundsManagementT } = useTranslation(namespaces.features.coInnovationFunds);

    const supportUrl = useStore(state => state.serviceNow.supportUrl);

    const [backendError, setBackendError] = useState(false);
    const queryClient = useQueryClient();

    const defaultFormState = {
        fundId: selectedFund?.id ?? '',
        provided: numberToDisplayString(selectedFund?.provided),
        expired: numberToDisplayString(selectedFund?.expired),
        carriedForward: numberToDisplayString(selectedFund?.carryForward),
        submissionComment: '',
    };

    const form = useForm<ChangeRequestFormState>({
        mode: 'onChange',
        defaultValues: defaultFormState,
    });

    const { watch, reset, handleSubmit } = form;

    useEffect(() => {
        reset(defaultFormState);
    }, [reset, selectedFund]);

    const watchedFields = watch();
    const isFieldChanged =
        watchedFields.provided !== defaultFormState.provided ||
        watchedFields.expired !== defaultFormState.expired ||
        watchedFields.carriedForward !== defaultFormState.carriedForward;
    const isFormValid = watchedFields.submissionComment.trim() !== '' && isFieldChanged;

    const { mutate: mutateChangeRequest, isLoading } = useChangeRequestMutation({
        changeRequest: convertFormStateToParams(watchedFields, defaultFormState),
        onSuccess: () => {
            reset(defaultFormState);
            setBackendError(false);
            queryClient.invalidateQueries(['summary']);
            queryClient.invalidateQueries(['summaryPartners']);
            queryClient.invalidateQueries(['summaryPartnersHistory']);
            queryClient.invalidateQueries(['fundsList']);
            queryClient.invalidateQueries(['fund']);
            hideDialog();
        },
        onError: () => setBackendError(true),
    });

    const onSubmit = useCallback(() => {
        if (isFormValid) {
            mutateChangeRequest();
        }
    }, [mutateChangeRequest, isFormValid]);

    const hideDialog = useCallback(() => {
        setSelectedFundState(previousState => ({
            id: selectedFund?.id || '',
            types: previousState?.types?.filter(type => type !== 'changeRequest') || [],
        }));
    }, [setSelectedFundState, selectedFund?.id]);

    if (!selectedFund) {
        return null;
    }

    return (
        <DialogContainer setShowDialog={hideDialog} showDialog={!!selectedFund} size="medium" disableBackgroundClick>
            <DialogHeader onCloseButtonClick={hideDialog}>
                {fundsManagementT('coInnovationFunds.fundsManagement.changeRequest.title')}
            </DialogHeader>

            <DialogBody>
                <DialogBody>
                    {isLoading || !selectedFund ? (
                        <Spinner />
                    ) : (
                        <Suspense fallback={<Spinner />}>
                            <FundChangeRequestForm selectedFund={selectedFund} form={form} />
                        </Suspense>
                    )}
                </DialogBody>
            </DialogBody>

            <DialogFooter noPadding>
                <div className="grid gap-3 p-5 md:block [&_button]:py-[8.5px] [&_button]:text-xs [&_button[type='submit']]:py-[8.5px] [&_button[type='submit']]:text-xs">
                    <Button
                        variant="primary"
                        className="flex-grow md:mr-2"
                        type="submit"
                        disabled={!isFormValid || isLoading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {fundsManagementT('coInnovationFunds.fundsManagement.changeRequest.proceed')}
                    </Button>
                    <Button
                        variant="secondary"
                        className="mr-4 flex-grow"
                        type="reset"
                        disabled={isLoading}
                        onClick={() => {
                            reset(defaultFormState);
                        }}
                    >
                        {t('forms.resetAll')}
                    </Button>
                </div>

                {backendError && !isLoading ? (
                    <DialogError
                        title={t('errors.addFunds.title')}
                        instruction={
                            <Trans
                                t={t}
                                i18nKey="errors.instruction"
                                components={{
                                    anchor: (
                                        <Link
                                            to={supportUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            noPadding
                                            alignBaseLine
                                        />
                                    ),
                                }}
                            />
                        }
                    />
                ) : null}
            </DialogFooter>
        </DialogContainer>
    );
};
