import { AccountInfo } from '@azure/msal-browser';
import produce from 'immer';
import { StateCreator } from 'zustand';

type AuthRequest = { scopes: string[] };

export type Slice = {
    auth: {
        account: AccountInfo | null;
        accessToken: string;
        authRequest: AuthRequest;
        dispatchActiveAccount: (account: AccountInfo) => void;
        dispatchAccessToken: (token: string) => void;
        dispatchAuthRequest: (scopes: string[]) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    auth: {
        account: null,
        accessToken: '',
        authRequest: { scopes: [] },
        dispatchActiveAccount: (accountInfo: AccountInfo) =>
            set(
                produce((draft: Slice) => {
                    draft.auth.account = accountInfo;
                })
            ),
        dispatchAccessToken: (token: string) =>
            set(state =>
                produce(state, draft => {
                    draft.auth.accessToken = token;
                })
            ),
        dispatchAuthRequest: (scopes: string[]) => {
            set(state =>
                produce(state, draft => {
                    draft.auth.authRequest = { scopes };
                })
            );
        },
    },
});
