// FundDetailFooter.js or FundDetailFooter.tsx if you're using TypeScript

import { useTranslation } from 'react-i18next';

import { fundStatuses } from './constants';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { namespaces } from '@shared/constants';
import { Button } from '@shared/ui';

const canValidateChangeRequestPermissions = [permissions.coInnovationFunds.fundsManagement.validation];
const canCancelFundPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];

type ChangeRequestDetailFooterProps = {
    statusId?: number;
    handleActionClick: (actionType: 'cancelCR' | 'approveCR' | 'rejectCR') => void;
};

export const ChangeRequestDetailFooter = ({ statusId, handleActionClick }: ChangeRequestDetailFooterProps) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    if (statusId !== fundStatuses.changeRequestPending) {
        return null;
    }

    return (
        <footer className="mt-auto flex justify-end space-x-2 p-4" aria-label="Fund Change Request Details Actions">
            <HasPermission neededPermissions={canCancelFundPermissions}>
                <Button
                    type="button"
                    variant="secondary"
                    onClick={() => handleActionClick('cancelCR')}
                    aria-label="Cancel CR"
                >
                    {t('coInnovationFunds.validationFundsForm.cancelChangeRequest')}
                </Button>
            </HasPermission>
            <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                <Button
                    variant="link"
                    type="button"
                    onClick={() => handleActionClick('rejectCR')}
                    aria-label="Approve CR"
                >
                    {t('coInnovationFunds.validationFundsForm.rejectChangeRequest')}
                </Button>
            </HasPermission>
            <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                <Button
                    type="button"
                    variant="secondary"
                    onClick={() => handleActionClick('approveCR')}
                    aria-label="Approve Fund"
                >
                    {t('coInnovationFunds.validationFundsForm.approveChangeRequest')}
                </Button>
            </HasPermission>
        </footer>
    );
};
