export const fundStatuses = {
    pending: 0,
    approved: 1,
    rejected: 2,
    canceled: 3,
    changeRequestPending: 4,
};

export const fundStatusLabels = {
    pending: 'Pending',
    approved: 'Ppproved',
    rejected: 'Rejected',
    canceled: 'Canceled',
    changeRequestPending: 'Change pending',
};
