import { ValueType } from './domain';

type OrderOfMagnitudeTypes = 'thousand' | 'none';

type OrderOfMagnitude = {
    length: number;
    magnitudeText: string;
};

type OrderOfMagnitudeValue = {
    value: number;
    text: string;
};

const OrderOfMagnitudes: Record<OrderOfMagnitudeTypes, OrderOfMagnitude> = {
    thousand: {
        length: 1000,
        magnitudeText: 'K',
    },
    none: {
        length: 1,
        magnitudeText: '',
    },
};

const applySelectedOrderOfMagnitudeTo = (value: number, orderOfMagnitude: OrderOfMagnitude): OrderOfMagnitudeValue => {
    return {
        value: value / orderOfMagnitude.length,
        text: orderOfMagnitude.magnitudeText,
    };
};

const defaultOrderOfMagnitudeValue = (value: number): OrderOfMagnitudeValue => {
    return applySelectedOrderOfMagnitudeTo(value, OrderOfMagnitudes.none);
};

const calculateOrderOfMagnitudeValue = (value: number) => {
    if (OrderOfMagnitudes.thousand && Math.abs(value) >= OrderOfMagnitudes.thousand.length) {
        return applySelectedOrderOfMagnitudeTo(value, OrderOfMagnitudes.thousand);
    }

    return defaultOrderOfMagnitudeValue(value);
};

const calculateTypeUnit = (valueType: ValueType, unitText: string): string => {
    return valueType === ValueType.Money ? unitText : '';
};

const applyPatternFor = (customUnit: string, formattedValue: string, orderOfMagnitudeValueText: string): string => {
    return `${formattedValue}${orderOfMagnitudeValueText} ${customUnit}`.trim();
};

export type FormatNumberOptions = {
    valueType: ValueType;
    simplify: boolean;
    unitText: string;
    minimumFractionDigits?: number;
};

const defaultFormatNumberOptions: FormatNumberOptions = {
    valueType: ValueType.Money,
    simplify: true,
    unitText: '',
    minimumFractionDigits: 2,
};

export const formatNumber = (value: number, options: FormatNumberOptions | undefined = undefined) => {
    const currentOptions = { ...defaultFormatNumberOptions, ...options };

    let orderOfMagnitudeValue: OrderOfMagnitudeValue = defaultOrderOfMagnitudeValue(value);
    if (currentOptions.simplify) {
        orderOfMagnitudeValue = calculateOrderOfMagnitudeValue(value);
    }

    const customUnit = calculateTypeUnit(currentOptions.valueType, currentOptions.unitText);

    const roundedValue = Math.round(orderOfMagnitudeValue.value * 100) / 100;
    let formattedValue = Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: currentOptions.minimumFractionDigits,
    })
        .format(roundedValue)
        .split('.')
        .map(split => split.replaceAll(',', '.'))
        .join(',');

    if (formattedValue === 'NaN') {
        formattedValue = 'N/A';
    }

    return applyPatternFor(customUnit, formattedValue, orderOfMagnitudeValue.text);
};
