import { useTranslation } from 'react-i18next';

import { CellProps } from '../domain';

import { CellBase } from './base';

const LinkCell = <T extends object>({ value, context, meta, warningMessage, alignContent }: CellProps<T>) => {
    const { t } = useTranslation();

    const href: string | undefined = meta?.getCellProps(context).href;

    return (
        <a href={href}>
            <CellBase
                text={t(value)}
                context={context}
                meta={meta}
                warningMessage={warningMessage}
                alignContent={alignContent}
            />
        </a>
    );
};

export { LinkCell };
