import { useMemo } from 'react';

import { ColumnDef, ColumnSort, getCoreRowModel, PaginationState, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';

import { defaultPageSize } from '../../constants';

import { CellTypes, columnVisibility, CustomColumn } from './column';
import classes from './table.module.scss';
import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import { TableHead } from './TableHead';

import { noop } from '@shared/generic-functions';

export type TableProps<TData extends object> = {
    data?: TData[];
    columns: CustomColumn<TData>[];
    totalItems: number | undefined;
    caption: string;
    isLoading: boolean;
    tableClassNames?: string;
    isCaptionVisible?: boolean;
    pagination?: PaginationState;
    paginationCallback?: (pagination: PaginationState) => void;
    selectedRowCallback?: (id: string) => void;
    sorting?: ColumnSort;
    sortingCallback?: (sorting: ColumnSort) => void;
    isFiltered?: boolean;
    isOuterBorderless?: boolean;
    expectedBodyHeightClass?: string;
    pageSize?: number;
};

const Table = <TData extends object>({
    data,
    columns,
    totalItems = 0,
    selectedRowCallback,
    tableClassNames,
    isCaptionVisible,
    caption,
    pagination,
    paginationCallback,
    sorting,
    sortingCallback,
    isLoading,
    isFiltered,
    isOuterBorderless,
    expectedBodyHeightClass,
    pageSize,
}: TableProps<TData>) => {
    const tableColumns: ColumnDef<TData>[] = useMemo(
        () =>
            columns.map(column => ({
                ...column,
                size: column.size || undefined,
                cell: props =>
                    CellTypes[column.cellType]({
                        value: props.getValue<string>(),
                        context: props,
                        meta: column.meta,
                        actions: column.actions,
                        warningMessage: column.warningMessage,
                        alignContent: column.alignContent,
                    }),
            })),
        [columns]
    );
    const columnCallbacks = columns.reduce<Record<string, { (id: string): void }>>((accumulator, currentValue) => {
        if (currentValue.selectedColumnCallback) {
            accumulator[currentValue.accessorKey] = currentValue.selectedColumnCallback;
        }
        return accumulator;
    }, {});

    const { getRowModel, getHeaderGroups } = useReactTable({
        data: data || [],
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        pageCount: Math.ceil(totalItems / (pageSize ?? defaultPageSize)),
        state: {
            sorting: sorting ? [sorting] : [],
            pagination: pagination,
            columnVisibility: columnVisibility(columns),
        },
        manualPagination: true,
        manualSorting: true,
    });

    const rows = getRowModel().rows;

    return (
        <div className={tableClassNames}>
            <table
                className={classNames(isOuterBorderless ? classes.tableOuterBorderless : classes.table, 'mb-5 w-full')}
                role="table"
                aria-label="Funds Summary"
            >
                <caption
                    role="caption"
                    className={classNames({
                        'sr-only': !isCaptionVisible,
                    })}
                >
                    {caption}
                </caption>

                <TableHead
                    columns={columns}
                    sorting={sorting}
                    groups={getHeaderGroups()}
                    sortingCallback={sortingCallback ?? noop}
                />

                <TableBody
                    rows={rows}
                    columns={columns}
                    isLoading={isLoading}
                    columnCallbacks={columnCallbacks}
                    selectedRowCallback={selectedRowCallback}
                    isFiltered={isFiltered}
                    expectedBodyHeightClass={expectedBodyHeightClass}
                />
            </table>

            {pagination && paginationCallback ? (
                <TableFooter
                    pagination={pagination}
                    totalItems={totalItems}
                    paginationCallback={paginationCallback}
                ></TableFooter>
            ) : null}
        </div>
    );
};

export { Table };
