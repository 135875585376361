import { useTranslation } from 'react-i18next';

import { namespaces } from '@shared/constants';
import { formatNumber } from '@shared/formatting/formatNumber';

type Props = {
    aggregatedData: {
        currency: string;
        totalAllocated: number;
        totalConsumed: number;
        totalProjects: number;
    };
};

const ProjectsKpis = ({ aggregatedData }: Props) => {
    const { t: coInnovationT } = useTranslation(namespaces.features.coInnovationFunds);
    const { currency, totalAllocated, totalConsumed, totalProjects } = aggregatedData;
    const sections = [
        {
            title: coInnovationT('coInnovationFunds.detail.projectsDetails.kpi.totalProjects'),
            value: totalProjects,
            titleTestId: 'totalProjects-title',
            valueTestId: 'totalProjects-value',
        },
        {
            title: coInnovationT('coInnovationFunds.detail.projectsDetails.kpi.totalAllocated'),
            value: `${formatNumber(totalAllocated)} ${currency}`,
            titleTestId: 'totalAllocated-title',
            valueTestId: 'totalAllocated-value',
        },
        {
            title: coInnovationT('coInnovationFunds.detail.projectsDetails.kpi.totalConsumed'),
            value: `${formatNumber(totalConsumed)} ${currency}`,
            titleTestId: 'totalConsumed-title',
            valueTestId: 'totalConsumed-value',
        },
    ];

    return (
        <div className="mb-4 flex w-full flex-col gap-3 md:flex-row md:justify-between" data-testid="projectsKpis">
            {sections.map((section, index) => (
                <div
                    key={index}
                    className="flex flex-1 justify-between rounded-lg border-2 border-blue-100 py-4 px-5 text-left"
                >
                    <div>
                        <p data-testid={section.titleTestId}>{section.title}</p>
                        <p data-testid={section.valueTestId} className="mt-1 text-left text-xl font-bold text-blue-500">
                            {section.value}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProjectsKpis;
