import { z } from 'zod';

export const reportFilterParser = z.object({
    typologies: z.array(
        z.object({
            key: z.string(),
            value: z.string(),
        })
    ),
    tags: z.array(
        z.object({
            key: z.string(),
            value: z.string(),
        })
    ),
    itStreams: z.array(
        z.object({
            key: z.string(),
            value: z.string(),
        })
    ),
    leads: z.array(z.string()),
    partners: z.array(
        z.object({
            key: z.string(),
            value: z.string(),
        })
    ),
    marketsAndRegions: z.array(
        z.object({
            key: z.string(),
            value: z.string(),
        })
    ),
    status: z.array(z.string()),
});

export type KeyValuePair = {
    key: string;
    value: string;
};

export type GenaiFilterResponse = {
    tags: KeyValuePair[];
    itStreams: KeyValuePair[];
    leads: string[];
    partners: KeyValuePair[];
    marketsAndRegions: KeyValuePair[];
    status: string[];
};

export type GenaiFilter = {
    tags: string[];
    itStreams: string[];
    leads: string[];
    partners: string[];
    marketsAndRegions: string[];
    status: string[];
};

export const emptyFilterValue: GenaiFilter = {
    tags: [],
    itStreams: [],
    leads: [],
    partners: [],
    marketsAndRegions: [],
    status: [],
};

export const emptyFilterResponse: GenaiFilterResponse = {
    tags: [{ key: '', value: '' }],
    itStreams: [{ key: '', value: '' }],
    leads: [],
    partners: [{ key: '', value: '' }],
    marketsAndRegions: [{ key: '', value: '' }],
    status: [],
};
