import { UseQueryResult } from '@tanstack/react-query';
import { ChartData, ChartOptions, Plugin, Chart } from 'chart.js';

import { DetailByItTeamItemValue, MultivalueChartWithPercent } from './domain';

import { CoInnovationFilter } from '@features/coInnovationFunds/shared/filtering/domain';
import { blackColor, blue100Color, blue900Color, primaryColor, textFonts, whiteColor } from '@shared/constants';
import { formatNumber, FormatNumberOptions, ValueType } from '@shared/formatting';

const MAX_BAR_TEXT_X_POSITION = 260;

const DATALABEL_BACKGROUND_COLOR = 'rgba(0, 0, 255, 0.5)';
const PROJECT_COUNT_BACKGROUND_COLOR = 'rgba(177,79,197,0.9)';

const chartStyleConfiguration = {
    barThickness: 20,
    tickFontSize: 12,
    datalabelFontSize: 9,
    canvasTextFontSize: 13,
    spacingChartText: 12,
} as const;

const calculateDatasetPercentageQuantity = (totalQuantity: number, percentage: number) =>
    totalQuantity - (totalQuantity * percentage) / 100;

export const getChartData = (itTeamData: MultivalueChartWithPercent[]): ChartData<'bar'> => ({
    labels: ['', ...itTeamData.map(data => data.legend)],
    datasets: [
        {
            data: [0, ...itTeamData.map(() => 0)],
            backgroundColor: PROJECT_COUNT_BACKGROUND_COLOR,
            borderColor: PROJECT_COUNT_BACKGROUND_COLOR,
            barThickness: chartStyleConfiguration.barThickness,
            minBarLength: 35,
        },
        {
            data: [0, ...itTeamData.map(() => 0)],
            backgroundColor: whiteColor,
            borderColor: blue900Color,
            barThickness: chartStyleConfiguration.barThickness,
            minBarLength: 5,
        },
        {
            data: [
                0,
                ...itTeamData.map(element => calculateDatasetPercentageQuantity(element.value, 100 - element.percent)),
            ],
            backgroundColor: blue900Color,
            borderColor: blue900Color,
            barThickness: chartStyleConfiguration.barThickness,
        },
        {
            data: [0, ...itTeamData.map(element => calculateDatasetPercentageQuantity(element.value, element.percent))],
            backgroundColor: blue100Color,
            borderColor: blue100Color,
            barThickness: chartStyleConfiguration.barThickness,
        },
    ],
});

export const getChartOptions = (
    itTeamData: MultivalueChartWithPercent[],
    disableAnimation = true,
    maxYValue: number
): ChartOptions<'bar'> => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        animation: !disableAnimation && undefined,
        layout: {
            padding: { right: 45 },
        },
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false,
            },
            datalabels: {
                backgroundColor: context => {
                    if (context.datasetIndex % 2 !== 0) {
                        return null;
                    }
                    if (context.datasetIndex === 0 && context.dataIndex === 0) {
                        return whiteColor;
                    }
                    if (context.datasetIndex === 0 || context.dataIndex === 0) {
                        return null;
                    }

                    return DATALABEL_BACKGROUND_COLOR;
                },
                borderRadius: 2,
                color: context => {
                    return context.dataIndex === 0 ? blackColor : whiteColor;
                },
                font: context => {
                    return {
                        weight: 'bold',
                        size:
                            context.datasetIndex === 0
                                ? context.dataIndex === 0
                                    ? 11
                                    : chartStyleConfiguration.tickFontSize
                                : chartStyleConfiguration.datalabelFontSize,
                    };
                },
                formatter: (_, context) => {
                    if (context.datasetIndex % 2 !== 0) {
                        return '';
                    }

                    if (context.dataIndex === 0 && context.datasetIndex === 0) {
                        return '#Projects  Funds';
                    }

                    if (context.dataIndex === 0) {
                        return '';
                    }

                    const element = itTeamData[context.dataIndex - 1];

                    if (context.datasetIndex === 0) {
                        return element?.legend.split('-')[1];
                    }

                    return element?.text;
                },
                align: context => {
                    if (context.dataIndex === 0) {
                        return 50;
                    }
                    return 5;
                },
                anchor: 'start',
                padding: context => {
                    if (context.datasetIndex === 0) {
                        if (context.dataIndex === 0) {
                            return { bottom: -10, top: 20, right: 0, left: -5 };
                        }
                        return { bottom: 0, top: 0, right: 0, left: 13 };
                    }
                    return { bottom: -1, top: 2, right: 2, left: 2 };
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                display: false,
                type: 'linear',
                max: maxYValue,
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    color: primaryColor,
                    font: {
                        size: chartStyleConfiguration.tickFontSize,
                        family: textFonts,
                    },
                    showLabelBackdrop: false,
                    callback: function (_, index) {
                        if (index === 0) {
                            return null;
                        }
                        return this.getLabelForValue(index).split('-')[0];
                    },
                },
            },
        },
        indexAxis: 'y',
    };
};

export const plugins: (
    itTeamData: MultivalueChartWithPercent[],
    formatNumberOptions: FormatNumberOptions,
    allocatedTranslation: string,
    filter: CoInnovationFilter,
    teamsDataQuery: UseQueryResult<
        | {
              id: string;
              name: string;
          }[]
        | null
    >,
    applyFilterCallback?: (data: CoInnovationFilter) => void
) => Plugin = (
    itTeamData: MultivalueChartWithPercent[],
    formatNumberOptions: FormatNumberOptions,
    allocatedTranslation: string,
    filter: CoInnovationFilter,
    teamsDataQuery: UseQueryResult<
        | {
              id: string;
              name: string;
          }[]
        | null
    >,
    applyFilterCallback?: (data: CoInnovationFilter) => void
) => {
    const quantities = [0, ...itTeamData.map(element => element.value)];

    return {
        id: 'text',
        beforeDraw: function (chart: Chart<'bar'>) {
            const ctx = chart.ctx;

            setCanvasOnHover(chart);

            setCanvasOnclick(chart, filter, teamsDataQuery, applyFilterCallback);

            ctx.save();
            ctx.restore();

            ctx.fillStyle = blackColor;
            ctx.font = `${chartStyleConfiguration.canvasTextFontSize}px ${textFonts}`;

            ctx.textBaseline = 'middle';
            ctx.textAlign = 'start';

            chart.data.datasets.forEach((_, index) => {
                if (index !== 3) {
                    return;
                }

                const meta = chart.getDatasetMeta(index);
                meta.data.forEach((bar, index) => {
                    const value = quantities[index];
                    const text =
                        value === undefined || value === 0
                            ? ''
                            : `${formatNumber(value, formatNumberOptions)} ${allocatedTranslation}`;

                    ctx.fillText(
                        text,
                        bar.x < MAX_BAR_TEXT_X_POSITION
                            ? MAX_BAR_TEXT_X_POSITION + chartStyleConfiguration.spacingChartText
                            : bar.x + chartStyleConfiguration.spacingChartText,
                        bar.y
                    );
                });
            });
            ctx.save();
        },
    };
};

export const formatDetailByItTeamData = (
    detailByItTeamData: DetailByItTeamItemValue,
    valueType: ValueType,
    unitText: string
) => {
    const colors = [blue900Color, blue100Color];

    const formatNumberOptions: FormatNumberOptions = {
        simplify: false,
        valueType: valueType,
        unitText: unitText,
    };

    return detailByItTeamData.map((detailByItTeamData, index) => ({
        text: `${detailByItTeamData.consumedPercent}%`,
        percent: detailByItTeamData.consumedPercent,
        tooltip: `${formatNumber(detailByItTeamData.allocated, formatNumberOptions)} (${
            detailByItTeamData.consumedPercent
        }%)`,
        value: detailByItTeamData.allocated,
        color: colors[index] ?? blue900Color,
        legend: `${detailByItTeamData.teamName}-${detailByItTeamData.projectCount}`,
    }));
};

const getTeamFilterId = (
    teamName: string,
    teamsDataQuery: UseQueryResult<
        | {
              id: string;
              name: string;
          }[]
        | null
    >
): string | null => {
    return teamsDataQuery.data?.find(x => x.name === teamName)?.id ?? null;
};

const setCanvasOnHover = (chart: Chart<'bar'>) => {
    chart.ctx.canvas.onmousemove = e => {
        if (e.offsetX < 200 && e.offsetX > 25 && e.offsetY > 50) {
            chart.ctx.canvas.style.cursor = 'pointer';
        } else {
            chart.ctx.canvas.style.cursor = 'auto';
        }
    };
};

const setCanvasOnclick = (
    chart: Chart<'bar'>,
    filter: CoInnovationFilter,
    teamsDataQuery: UseQueryResult<
        | {
              id: string;
              name: string;
          }[]
        | null
    >,
    applyFilterCallback?: (data: CoInnovationFilter) => void
) => {
    chart.ctx.canvas.onclick = e => {
        const dataYClicked = chart.scales['y']?.getValueForPixel(e.offsetY);
        if (dataYClicked === undefined) {
            return;
        }
        const selectedTeam = chart.scales['y']?.ticks[dataYClicked - 1]?.label;
        if (selectedTeam !== undefined) {
            if (applyFilterCallback) {
                const filterId = getTeamFilterId(selectedTeam.toString(), teamsDataQuery);
                if (filterId !== null) {
                    filter = { ...filter, teams: [filterId] };
                    applyFilterCallback(filter);
                }
            }
        }
    };
};
