import { useQuery } from '@tanstack/react-query';

import { getMainMenu } from './api';
import { cmsMainMenuParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';
import { useStore } from '@store';

const queryKey = 'mainMenu';

export const useMainMenuQuery = () => {
    const localAccountId = useStore(state => state.auth.account?.localAccountId);
    const accessToken = useStore(state => state.auth.accessToken);

    return useQuery(
        [queryKey, { localAccountId }],
        async () => {
            const response = await getMainMenu();
            saveToCache(`${queryKey}-${localAccountId}`, response);
            return response;
        },
        {
            enabled: !!localAccountId && !!accessToken,
            placeholderData: () => retrieveFromCache(`${queryKey}-${localAccountId}`, cmsMainMenuParser.parse),
        }
    );
};
