import { useTranslation } from 'react-i18next';

import { namespaces } from '@shared/constants';

type Props = {
    translationKey: string;
};

const ReportDetailTag = ({ translationKey }: Props) => {
    const { t } = useTranslation(namespaces.features.genai);
    const translation = t(translationKey);

    return (
        <div className="tag-label" data-testid="reportTagLabel">
            <span className="text-xs">{translation}</span>
        </div>
    );
};

export { ReportDetailTag };
