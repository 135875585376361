import { Dispatch, SetStateAction } from 'react';

type Props = {
    partners: { name: string; id: string }[];
    selectedPartner: string | null;
    setSelectedPartner: Dispatch<SetStateAction<string | null>>;
};

export const PartnersTable = ({ partners, selectedPartner, setSelectedPartner }: Props) => {
    return (
        <div className="flex flex-col rounded-lg border-2 border-blue-100">
            {partners.map(x => (
                <div
                    className="cursor-pointer border border-blue-100"
                    key={x.id}
                    onClick={() => setSelectedPartner(x.id)}
                >
                    {x.id == selectedPartner ? (
                        <div className="bg-blue-100 py-3 pl-2 pr-32"> {x.name}</div>
                    ) : (
                        <div className="py-3 pl-2 pr-32"> {x.name}</div>
                    )}
                </div>
            ))}
        </div>
    );
};
