import produce from 'immer';
import { StateCreator } from 'zustand';

import { CoInnovationFilter, PageType, emptyFilterValue } from './filtering/domain';

export type Slice = {
    coInnovationFunds: {
        filter: CoInnovationFilter;
        page: PageType;
        setPage: (newPage: PageType) => void;
        setFilter: (filter: CoInnovationFilter) => void;
        isDirtyFilter: boolean;
        showFilterInReport: boolean;
        setShowFilterInReport: (showFilterInReport: boolean) => void;
        setIsDirtyFilter: (isDirty: boolean) => void;
        partiallySetFilter: (filter: Partial<CoInnovationFilter>) => void;
        showFilterDialog: boolean;
        setShowFilterDialog: (show: boolean) => void;
        reset: () => void;
        showAddFundsDialog: boolean;
        setShowAddFundsDialog: (show: boolean) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    coInnovationFunds: {
        filter: emptyFilterValue,
        page: PageType.Summary,
        setPage: (newPage: PageType) =>
            set(state =>
                produce(state, draft => {
                    draft.coInnovationFunds.page = newPage;
                })
            ),
        setFilter: (filter: CoInnovationFilter) =>
            set(state =>
                produce(state, draft => {
                    draft.coInnovationFunds.filter = filter;
                })
            ),
        isDirtyFilter: false,
        showFilterInReport: false,
        setShowFilterInReport: (showFilterInReport: boolean) =>
            set(state =>
                produce(state, draft => {
                    draft.coInnovationFunds.showFilterInReport = showFilterInReport;
                })
            ),
        setIsDirtyFilter: (isDirty: boolean) =>
            set(state =>
                produce(state, draft => {
                    draft.coInnovationFunds.isDirtyFilter = isDirty;
                })
            ),
        partiallySetFilter: (filter: Partial<CoInnovationFilter>) =>
            set(state =>
                produce(state, draft => {
                    draft.coInnovationFunds.filter = { ...state.coInnovationFunds.filter, ...filter };
                })
            ),
        showFilterDialog: false,
        setShowFilterDialog: (show: boolean) =>
            set(state =>
                produce(state, draft => {
                    draft.coInnovationFunds.showFilterDialog = show;
                })
            ),
        reset: () =>
            set(state =>
                produce(state, draft => {
                    draft.coInnovationFunds.showFilterDialog = false;
                    draft.coInnovationFunds.filter = emptyFilterValue;
                })
            ),
        showAddFundsDialog: false,
        setShowAddFundsDialog: show =>
            set(state =>
                produce(state, draft => {
                    draft.coInnovationFunds.showAddFundsDialog = show;
                })
            ),
    },
});
