import { CacheItem } from './domain';

export const retrieveFromCache = <T>(key: string, parse?: (data: unknown) => T) => {
    const cacheString = localStorage.getItem(key);

    try {
        if (cacheString) {
            const cache: CacheItem<T> = JSON.parse(cacheString);
            const data = cache.data;

            const timeDiff = Math.abs(new Date().getTime() - cache.time);
            const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

            if (dayDiff > 2) {
                localStorage.removeItem(key);
                return undefined;
            }

            if (parse) {
                parse(data);
            }

            return data;
        }

        return undefined;
    } catch (error) {
        localStorage.removeItem(key);
        return undefined;
    }
};
