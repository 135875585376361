import { DetailByGlobalTeam, detailByGlobalTeam } from './domain';
import { CoInnovationFilter, createFilterQueryString } from './filtering';

import { agent } from '@shared/api';

const apiBaseRoutes = {
    detailsByGlobalTeam: '/api/coinnovationfunds/detail/byglobalteam',
};

export const getByGlobalTeam = async (filter?: CoInnovationFilter) => {
    const queryString = createFilterQueryString({ filter });
    const response = await agent.get<DetailByGlobalTeam[]>(`${apiBaseRoutes.detailsByGlobalTeam}${queryString}`);

    return response ? detailByGlobalTeam.parseAsync(response) : null;
};
