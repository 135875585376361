export const permissions = {
    coInnovationFunds: {
        myRequests: {
            view: 'CoInnovationFunds.MyRequests.View',
            create: 'CoInnovationFunds.MyRequests.Create',
        },
        myDemands: {
            view: 'CoInnovationFunds.MyDemands.View',
        },
        myProjects: {
            view: 'CoInnovationFunds.MyProjects.View',
        },
        myReports: {
            view: 'CoInnovationFunds.MyReports.View',
        },
        partnerRequests: {
            view: 'CoInnovationFunds.PartnerRequests.View',
            create: 'CoInnovationFunds.PartnerRequests.Create',
        },
        partnerDemands: {
            view: 'CoInnovationFunds.PartnerDemands.View',
        },
        partnerProjects: {
            view: 'CoInnovationFunds.PartnerProjects.View',
        },
        partnerReports: {
            view: 'CoInnovationFunds.PartnerReports.View',
        },
        allRequests: {
            view: 'CoInnovationFunds.AllRequests.View',
            create: 'CoInnovationFunds.AllRequests.Create',
        },
        allDemands: {
            view: 'CoInnovationFunds.AllDemands.View',
        },
        allProjects: {
            view: 'CoInnovationFunds.AllProjects.View',
        },
        allReports: {
            view: 'CoInnovationFunds.AllReports.View',
        },
        fundsManagement: {
            create: 'CoInnovationFunds.AllFundsManagement.Create',
            view: 'CoInnovationFunds.AllFundsManagement.View',
            cancel: 'CoInnovationFunds.AllFundsManagement.Cancel',
            validation: 'CoInnovationFunds.AllFundsManagement.ApproveReject',
        },
    },
    genAi: {
        allInitiatives: {
            view: 'GenAI.AllInitiatives.View',
        },
    },
    getInspired: {
        allBlogs: {
            view: 'GetInspired.AllBlogs.View',
        },
    },
} as const;
