import { ReactElement, ReactNode, Suspense } from 'react';

import { Spinner } from '../spinner';

import { TabContent } from './TabContent';

type Props = {
    elements: {
        name: string;
        content: ReactNode;
        icon?: ReactElement;
        withFallbackInContent?: boolean;
    }[];
    openTab: number;
};

const TabContentWrapper = ({ elements, openTab }: Props) => (
    <div className="tab-content tab-space flex flex-1">
        {elements.map((element, index) =>
            openTab === index ? (
                element.withFallbackInContent ? (
                    <Suspense fallback={<Spinner key={index} />} key={index}>
                        <TabContent element={element} openTab={index} key={element.name + openTab} />
                    </Suspense>
                ) : (
                    <TabContent element={element} openTab={index} key={element.name + openTab} />
                )
            ) : null
        )}
    </div>
);

export { TabContentWrapper };
