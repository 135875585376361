import { DetailedHTMLProps, ReactNode, TextareaHTMLAttributes, forwardRef } from 'react';

import classNames from 'classnames';

import { InputErrors } from '../inputErrors';
import { Label } from '../label';

type Props = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
    containerClassName?: string;
    label?: ReactNode | undefined;
    suffix?: ReactNode | string;
    errors?: string[];
    disableSuffixPadding?: boolean;
};

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
    ({ containerClassName, label, className, suffix, errors, disableSuffixPadding, value, ...rest }, ref) => {
        return (
            <div className={classNames(containerClassName ? containerClassName : 'mb-[30px]')}>
                <Label id={rest.id}>{label}</Label>

                <div
                    className={classNames('flex rounded border', {
                        'border-error': !!errors,
                        'border-gray-200': !errors,
                    })}
                >
                    <textarea
                        value={value}
                        className={classNames(
                            className,
                            'block w-full grow rounded px-[6px] py-[6.5px] text-xs outline-none',
                            {
                                'text-gray-900': !errors,
                                'text-error': !!errors,
                            },
                            // remove the arrows from type="number"
                            '[&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:[-webkit-appearance:none]',
                            '[&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:[-webkit-appearance:none]',
                            // place holder
                            '[&::-webkit-input-placeholder]:text-gray-900',
                            '[&:-moz-placeholder]:text-gray-900 [&:-moz-placeholder]:opacity-100',
                            '[&::-moz-placeholder]:text-gray-900 [&::-moz-placeholder]:opacity-100',
                            '[&:-ms-input-placeholder]:text-gray-900',
                            '[&::-ms-input-placeholder]:text-gray-900',
                            '[&::placeholder]:text-gray-900'
                        )}
                        {...rest}
                        ref={ref}
                    />

                    {suffix ? (
                        <span className={classNames('inline-flex items-center', { 'p-[6px]': !disableSuffixPadding })}>
                            {suffix}
                        </span>
                    ) : null}
                </div>

                <InputErrors errors={errors} />
            </div>
        );
    }
);
TextArea.displayName = 'Input';

export { TextArea };
