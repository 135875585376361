import { useState, useEffect, useCallback } from 'react';

import { PaginationState } from '@tanstack/react-table';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PageJumper, Pager } from '../pagination';

type Props = {
    pagination: PaginationState;
    totalItems: number;
    paginationCallback: (pagination: PaginationState) => void;
    paginationDetail?: boolean;
};

const Pagination = ({ pagination, totalItems, paginationCallback, paginationDetail }: Props) => {
    const location = useLocation();
    const { t: commonT } = useTranslation();
    const pageCount = Math.ceil(totalItems / pagination.pageSize);
    const firstItemIndex = pagination.pageIndex * pagination.pageSize + 1;
    const lastItemIndex = Math.min((pagination.pageIndex + 1) * pagination.pageSize, totalItems);

    const getPageFromUrl = () => {
        if (typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            return page ? parseInt(page, 10) - 1 : pagination.pageIndex;
        }
        return pagination.pageIndex;
    };

    const [currentPagination, setCurrentPagination] = useState({
        ...pagination,
        pageIndex: getPageFromUrl(),
    });

    useEffect(() => {
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
    }, [currentPagination.pageIndex]);

    const onPageChangeCallback = useCallback(
        (page: number) => {
            const newPagination = { pageIndex: page - 1, pageSize: pagination.pageSize };
            setCurrentPagination(newPagination);
            paginationCallback(newPagination);
        },
        [paginationCallback, pagination.pageSize]
    );

    useEffect(() => {
        const handlePopState = () => {
            const newPagination = { pageIndex: 0, pageSize: pagination.pageSize };
            setCurrentPagination(newPagination);
            paginationCallback(newPagination);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [pagination.pageSize, paginationCallback]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const url = new URL(window.location.href);
            url.searchParams.set('page', (currentPagination.pageIndex + 1).toString());
            window.history.replaceState({}, '', url);
        }
    }, [currentPagination.pageIndex, location]);

    return (
        <div className="flex items-center text-xs">
            {!paginationDetail && (
                <span className="mr-3 flex whitespace-nowrap text-blue-800" data-testid="paginationSummary-entries">
                    {totalItems === 0
                        ? commonT('paging.noEntries')
                        : commonT('paging.pagingEntries', {
                              first: firstItemIndex,
                              last: lastItemIndex,
                              total: totalItems.toString(),
                          })}
                </span>
            )}

            <div className="mt-4 flex-1 justify-center text-center">
                <Pager
                    pageCount={pageCount}
                    currentPage={currentPagination.pageIndex + 1}
                    onChange={onPageChangeCallback}
                />
            </div>
            {!paginationDetail && (
                <div className="ml-3 flex items-center justify-end">
                    <div
                        className={classNames('flex items-center whitespace-nowrap text-xs text-blue-800', {
                            'mr-4 self-stretch border-r border-blue-200/60 pr-4': pageCount > 1,
                        })}
                    >
                        {commonT('paging.totalPages', { pageCount })}
                    </div>

                    <PageJumper id="projectsDetails" onChange={onPageChangeCallback} pageCount={pageCount} />
                </div>
            )}
        </div>
    );
};

export { Pagination };
