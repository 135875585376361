import { Chart, LegendItem, Plugin } from 'chart.js';

import { createLegendIcon, createLegendList, createLegendText, HtmlLegend, LegendOptions } from '../charts';

import { chartBackgroundColor, chartTextColor, MultivalueChartWithLegendColor } from './domain';

import { CoInnovationFilter, getHorizonNames } from '@features/coInnovationFunds/shared';

export const htmlLegend: HtmlLegend = {
    containerID: 'legendContainer',
};

export const legendOptions: LegendOptions = {
    display: false,
};

const createDeviationDiv = (item: LegendItem, horizonData: MultivalueChartWithLegendColor[]): HTMLDivElement => {
    const deviationContainer = document.createElement('div');
    const itemRawData = horizonData.find(f => f.legend === item.text);
    const thresholdDeviationStatus = itemRawData ? itemRawData?.thresholdDeviationStatus : '';
    const thresholdDeviationValue = itemRawData ? itemRawData?.thresholdDeviationValue : 0;

    const backgroundColor = chartBackgroundColor[thresholdDeviationStatus];
    deviationContainer.style.backgroundColor = backgroundColor ? backgroundColor : '';
    deviationContainer.className = 'w-auto rounded px-1';
    const spanText = document.createElement('span');
    const textColor = chartTextColor[thresholdDeviationStatus];
    spanText.style.color = textColor ? textColor : '';
    spanText.className = 'font-bold text-sm';
    const deviationText = document.createTextNode(
        thresholdDeviationValue > 0
            ? `+ ${thresholdDeviationValue.toString()}%`
            : `${thresholdDeviationValue.toString()}%`
    );
    spanText.appendChild(deviationText);
    deviationContainer.appendChild(spanText);
    return deviationContainer;
};

export const htmlLegendPlugin = (
    horizonData: MultivalueChartWithLegendColor[],
    legendId: string,
    legendListId: string,
    filter: CoInnovationFilter,
    applyFilterCallback?: (data: CoInnovationFilter) => void
): Plugin => ({
    id: 'htmlLegend',
    afterUpdate(chart: Chart<'doughnut'>) {
        const ul = createLegendList(legendId, 'chartByHorizonUl');

        while (ul.firstChild) {
            ul.firstChild.remove();
        }
        ul.className = 'mt-10 flex flex-col items-start justify-center w-max mx-auto';

        const labels = chart.options.plugins?.legend?.labels;
        const generatedLabels = labels?.generateLabels !== undefined ? labels.generateLabels(chart) : null;
        generatedLabels?.forEach((label: LegendItem) => {
            const li = document.createElement('li');
            li.id = `${legendListId}${label.index}`;
            li.className = 'flex items-center cursor-pointer ml-3 mb-4';
            if (applyFilterCallback) {
                li.onclick = () => {
                    const horizon = label.text.split(';')[0];
                    if (horizon !== undefined) {
                        applyHorzionFilter(horizon, filter, applyFilterCallback);
                    }
                };
            }
            const legendIconDiv = createLegendIcon(label);
            const textContainer = createLegendText({ ...label, text: label.text.split(';').join(' ') });
            const deviationContainer = createDeviationDiv(label, horizonData);

            li.appendChild(legendIconDiv);
            li.appendChild(textContainer);
            li.appendChild(deviationContainer);
            ul.appendChild(li);
        });
    },
});

const applyHorzionFilter = (
    horizonName: string,
    filter: CoInnovationFilter,
    applyFilterCallback: (data: CoInnovationFilter) => void
) => {
    switch (horizonName) {
        case getHorizonNames().H1:
            filter = { ...filter, horizons: [0] };
            break;
        case getHorizonNames().H2:
            filter = { ...filter, horizons: [1] };
            break;
        case getHorizonNames().H3:
            filter = { ...filter, horizons: [2] };
            break;
        case getHorizonNames().Unknown:
            filter = { ...filter, horizons: [-1] };
            break;
    }
    applyFilterCallback(filter);
};
