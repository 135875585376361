import { CoInnovationFilter } from '../shared';

import { GetFundsRequest } from './domain';

import { convertToSimpleUTCStringWithoutTime } from '@shared/generic-functions';

export const convertFormStateToApiParam = ({
    partners,
    statuses,
    approvers,
    from,
    to,
}: CoInnovationFilter): GetFundsRequest => ({
    partnerIds: partners?.map(partner => partner.toString()),
    statusIds: statuses?.map(status => status.value.toString()),
    validatorIds: approvers?.map(validator => validator.value.toString()),
    submitDateFrom: from ? convertToSimpleUTCStringWithoutTime(new Date(from)) : undefined,
    submitDateTo: to ? convertToSimpleUTCStringWithoutTime(new Date(to)) : undefined,
});
