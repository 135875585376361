import { ReactElement, ReactNode, useState } from 'react';

import classNames from 'classnames';

import { Tab } from './Tab';
import { TabContentWrapper } from './TabContentWrapper';

type Props = {
    elements: {
        testid: string;
        name: string;
        content: ReactNode;
        icon?: ReactElement;
        withFallbackInContent?: boolean;
    }[];
    border?: boolean;
};

const Tabs = ({ elements, border }: Props) => {
    const [openTab, setOpenTab] = useState(0);
    return (
        <>
            <div className="flex w-full flex-1 flex-col items-start">
                <ul
                    className="mb-4 flex w-full list-none justify-start border-b border-blue-100"
                    role="tablist"
                    data-testid="tabs-label-wrapper"
                >
                    {elements.map((element, index) => (
                        <Tab
                            index={index}
                            openTab={openTab}
                            setOpenTab={setOpenTab}
                            key={element.name + index}
                            testid={element.testid}
                            name={element.name}
                            icon={element.icon}
                        />
                    ))}
                </ul>
                <div
                    className={classNames('relative flex h-full w-full break-words rounded bg-white', {
                        'border border-blue-100': border,
                    })}
                    data-testid="tabs-content-wrapper"
                >
                    <TabContentWrapper elements={elements} openTab={openTab} />
                </div>
            </div>
        </>
    );
};

export { Tabs };
