// FundDetailFooter.js or FundDetailFooter.tsx if you're using TypeScript

import { useTranslation } from 'react-i18next';

import { fundStatuses } from './constants';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { namespaces } from '@shared/constants';
import { Button } from '@shared/ui';

const canCancelFundPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];
const canValidateFundPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

type FundDetailFooterProps = {
    statusId?: number;
    handleActionClick: (actionType: 'cancel' | 'approve' | 'reject') => void;
};

export const FundDetailFooter = ({ statusId, handleActionClick }: FundDetailFooterProps) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    if (statusId !== fundStatuses.pending) {
        return null;
    }

    return (
        <footer className="mt-auto flex justify-end space-x-2 p-4" aria-label="Fund Details Actions">
            <HasPermission neededPermissions={canCancelFundPermissions}>
                <Button
                    variant="secondary"
                    type="button"
                    onClick={() => handleActionClick('cancel')}
                    aria-label="Cancel Fund"
                >
                    {t('coInnovationFunds.fundsManagement.cancelation.title')}
                </Button>
            </HasPermission>
            <HasPermission neededPermissions={canValidateFundPermissions}>
                <Button
                    variant="link"
                    type="button"
                    onClick={() => handleActionClick('reject')}
                    aria-label="Reject Fund"
                >
                    {t('coInnovationFunds.validationFundsForm.reject')}
                </Button>
            </HasPermission>
            <HasPermission neededPermissions={canValidateFundPermissions}>
                <Button
                    type="button"
                    variant="secondary"
                    onClick={() => handleActionClick('approve')}
                    aria-label="Approve Fund"
                >
                    {t('coInnovationFunds.validationFundsForm.approve')}
                </Button>
            </HasPermission>
        </footer>
    );
};
