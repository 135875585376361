import { useTranslation } from 'react-i18next';

import { PagerItem } from './PagerItem';

import { ReactComponent as ChevronLeft } from '@assets/icons/chevronLeft.svg';
import { ReactComponent as ChevronRight } from '@assets/icons/chevronRight.svg';

type PagerProps = {
    pageCount: number;
    disabled?: boolean;
    currentPage: number;
    itemsCount?: number;
    onChange: (page: number) => void;
};

const Pager = ({ currentPage, itemsCount, onChange, pageCount, disabled }: PagerProps) => {
    const { t } = useTranslation();
    if (pageCount < 2) {
        return null;
    }
    const maxItemsCount = itemsCount || 5;
    const actualItemsCount = Math.min(maxItemsCount, pageCount);
    const previousHalf = Math.floor(actualItemsCount / 2);
    const firstItem = Math.min(Math.max(currentPage - previousHalf, 1), pageCount - actualItemsCount + 1);
    return (
        <ul className="inline-flex items-center text-center">
            <PagerItem
                currentPage={currentPage}
                onChange={() => onChange(currentPage - 1)}
                disabled={currentPage === 1 || disabled}
            >
                <ChevronLeft />
                <span className="sr-only">{t('global.previous')}</span>
            </PagerItem>
            {new Array(actualItemsCount).fill(null).map((_, i) => {
                const page = firstItem + i;
                return (
                    <PagerItem
                        key={i}
                        currentPage={currentPage}
                        onChange={() => onChange(page)}
                        page={page}
                        disabled={disabled}
                    >
                        {page.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                    </PagerItem>
                );
            })}
            <PagerItem
                currentPage={currentPage}
                onChange={() => onChange(currentPage + 1)}
                disabled={currentPage === pageCount || disabled}
            >
                <ChevronRight />
                <span className="sr-only">{t('global.next')}</span>
            </PagerItem>
        </ul>
    );
};

export { Pager };
