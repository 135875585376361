import { TooltipItem } from 'chart.js';

import { MultivalueChart } from '../../shared';

import { HtmlLegend, LegendOptions } from './domain';

import { blackColor, whiteColor } from '@shared/constants';

export const getChartData = (data: MultivalueChart[]) => {
    return {
        labels: data.map(data => data.legend),
        datasets: [
            {
                data: data.map(data => data.value),
                backgroundColor: data.map(data => data.color),
                borderWidth: 1,
            },
        ],
    };
};

export const getChartOptions = ({
    data,
    legend,
    htmlLegend,
    showDataLabels,
    titleTransform,
    disableAnimation,
}: {
    disableAnimation?: boolean;
    legend: LegendOptions;
    data: MultivalueChart[];
    showDataLabels?: boolean;
    htmlLegend: HtmlLegend | null;
    titleTransform?: (label: string) => string;
}) => {
    return {
        responsive: true,
        animation: !disableAnimation && undefined,
        plugins: {
            tooltip: {
                backgroundColor: whiteColor,
                bodyColor: blackColor,
                displayColors: false,
                titleColor: blackColor,
                callbacks: {
                    label: (tooltipItem: TooltipItem<'doughnut'>) => {
                        const dataset = data.map(data => data.tooltip);
                        const index = tooltipItem.dataIndex;
                        return dataset[index] || '';
                    },
                    title: (items: TooltipItem<'doughnut'>[]) => {
                        const item = items[0];
                        const title = item?.label || '';
                        return titleTransform ? titleTransform(title) : title;
                    },
                },
            },
            htmlLegend: htmlLegend,
            legend: legend,
            datalabels: {
                color: whiteColor,
                formatter: (value: string | string[]) => {
                    if (showDataLabels) {
                        return `${value}%`;
                    }

                    return '';
                },
            },
        },
    };
};
