import { fundStatuses } from './constants';

export const getFundStatusTranslationKey = (statusId: number) => {
    switch (statusId) {
        case fundStatuses.pending:
            return 'coInnovationFunds.fundsManagement.statuses.pending';

        case fundStatuses.approved:
            return 'coInnovationFunds.fundsManagement.statuses.approved';

        case fundStatuses.rejected:
            return 'coInnovationFunds.fundsManagement.statuses.rejected';

        case fundStatuses.canceled:
            return 'coInnovationFunds.fundsManagement.statuses.canceled';

        case fundStatuses.changeRequestPending:
            return 'coInnovationFunds.fundsManagement.statuses.cRPending';

        default:
            return 'coInnovationFunds.fundsManagement.statuses.unknown';
    }
};
