import { ColumnSort, flexRender, HeaderGroup } from '@tanstack/react-table';

import { CustomColumn, HeaderCell } from './column';

type Props<T extends object> = {
    groups: HeaderGroup<T>[];
    columns: CustomColumn<T>[];
    sorting?: ColumnSort;
    sortingCallback: (sorting: ColumnSort) => void;
};

const TableHead = <T extends object>({ groups, columns, sortingCallback, sorting }: Props<T>) => {
    return (
        <thead>
            {groups.map(headerGroup => (
                <tr key={headerGroup.id} className="sticky top-0">
                    {headerGroup.headers.map(header => (
                        <HeaderCell
                            key={header.id}
                            columns={columns}
                            id={header.id}
                            sortingCallback={sortingCallback}
                            sorting={sorting}
                            width={header.column.columnDef.size}
                        >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                        </HeaderCell>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

export { TableHead };
