import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useResizeMediaQuery } from '@features/layout/useResizeMediaQuery';
import { namespaces } from '@shared/constants';

type Props = {
    report?: boolean;
};

const ByItTeamSectionHeader = ({ report }: Props) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { isMobile } = useResizeMediaQuery();

    return (
        <h3
            id="byItTeam"
            className={classNames('flex flex-row border-b p-5 text-xl', {
                'border-blue-100': !report,
                'border-gray-100': report,
            })}
        >
            <div className="flex">{t('coInnovationFunds.detail.dashboard.byItTeam.title')}</div>
            {!isMobile ? (
                <div className="flex flex-1 items-center justify-end">
                    <div
                        className="align-self m-0 inline-block h-[9px] w-[9px] rounded-2xl bg-blue-100 p-0"
                        data-testid="legend-allocated-color"
                    ></div>
                    <div className="align-self pl-3 pr-6 text-sm font-[400]" data-testid="legend-allocated-text">
                        {t('coInnovationFunds.detail.dashboard.byItTeam.legendAllocated')}
                    </div>
                    <div
                        className="align-self m-0 inline-block h-[9px] w-[9px] rounded-2xl bg-blue-900 p-0"
                        data-testid="legend-consumed-color"
                    ></div>
                    <div className="align-self pl-3 pr-6 text-sm font-[400]" data-testid="legend-consumed-text">
                        {t('coInnovationFunds.detail.dashboard.byItTeam.legendConsumed')}
                    </div>
                </div>
            ) : null}
        </h3>
    );
};

export { ByItTeamSectionHeader };
