import { setupAppInsights } from './setupAppInsights';

const startup = () => {
    if (process.env.NODE_ENV?.trim() === 'integration') {
        setupMockServiceWorker();
    }
};

const setupMockServiceWorker = () => {
    console.log('Starting mock worker ...');

    import('@mocks')
        .then(mock => {
            mock.worker.start();
            console.log('Mock worker is running');
        })
        .catch(() => 'Mock worker failed to start');
};

export { startup, setupAppInsights };
