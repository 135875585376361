import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from '@assets/icons/arrowLeft.svg';
import { namespaces } from '@shared/constants';
import { formatDate } from '@shared/formatting';
import { Button } from '@shared/ui';

export const FundsDetailHeader = ({
    navigateBackPath,
    submissionDate,
}: {
    navigateBackPath: string;
    submissionDate: string;
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const formattedSubmissionDate = submissionDate
        ? `${t('coInnovationFunds.fundsManagement.submitDate')}: ${formatDate(submissionDate)}`
        : '';

    return (
        <div role="region" aria-label="Submission Date" className="flex items-center border-b border-blue-100 p-4">
            <Button
                onClick={() => navigate(navigateBackPath)}
                className="btn-link-outline-style mr-2"
                aria-label="Go back"
            >
                <ArrowLeft className="stroke-current" />
            </Button>
            <h2 className="flex-grow text-xl">{formattedSubmissionDate}</h2>
        </div>
    );
};
