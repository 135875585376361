import { ReactNode } from 'react';

import { InfoTooltip } from '../tooltip';

type Props = {
    title: string;
    value: string;
    tooltipContent?: ReactNode;
};

const BoxItem = ({ title, value, tooltipContent }: Props) => {
    return (
        <div className="pr-3">
            <h3 className="mb-1 text-base" data-testid="box-item-title">
                {title}
            </h3>
            {tooltipContent ? (
                <>
                    <span className="mr-1 text-sm font-light" data-testid="box-item-value">
                        {value}
                    </span>
                    <InfoTooltip title={value} id={value}>
                        {tooltipContent}
                    </InfoTooltip>
                </>
            ) : (
                <span className="text-sm font-light" data-testid="box-item-value">
                    {value}
                </span>
            )}
        </div>
    );
};

export { BoxItem };
