import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import { LoadingBar } from '../loadingBar';

const GlobalLoader = () => {
    const areQueriesLoading = !!useIsFetching();
    const areMutationsLoading = !!useIsMutating();

    return <LoadingBar loading={areQueriesLoading || areMutationsLoading} />;
};

export { GlobalLoader };
