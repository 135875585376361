import { PropsWithChildren } from 'react';

import classNames from 'classnames';

type Props = PropsWithChildren<{
    page?: number;
    disabled?: boolean;
    currentPage: number;
    onChange: () => void;
}>;

const PagerItem = ({ onChange, page, currentPage, disabled, children }: Props) => {
    const active = currentPage === page;
    return (
        <li>
            <button
                onClick={onChange}
                className={classNames(
                    'mx-1 inline-flex min-h-[32px] min-w-[32px] items-center justify-center p-2 text-xs text-blue-900',
                    { 'bg-blue-200/50': active }
                )}
                disabled={active || disabled}
            >
                {children}
            </button>
        </li>
    );
};

export { PagerItem };
