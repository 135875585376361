import { useTranslation } from 'react-i18next';

import { FilterForms } from './domain';

import { Button } from '@shared/ui';
import { useStore } from '@store';

type Props = {
    formName: FilterForms;
};

const Buttons = ({ formName }: Props) => {
    const { t } = useTranslation();

    const reset = useStore(state => state.coInnovationFunds.reset);

    return (
        <>
            <div className="grid grid-cols-2 md:block">
                <Button
                    variant="secondary"
                    className="mr-4"
                    form={formName}
                    onClick={reset}
                    type="reset"
                    data-testid="resetAll"
                >
                    {t('forms.resetAll')}
                </Button>

                <Button variant="primary" form={formName} type="submit" data-testid="apply">
                    {t('forms.apply')}
                </Button>
            </div>
        </>
    );
};

export { Buttons };
