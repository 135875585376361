import { z } from 'zod';

export const summaryValueParser = z.object({
    provided: z.number().gte(0),
    allocated: z.number().gte(0),
    consumed: z.number().gte(0),
    available: z.number(),
    remaining: z.number().gte(0),
    allocatedPercentageOfAllocatedPlusAvailable: z.number().gte(0).lte(100),
    availablePercentageOfAllocatedPlusAvailable: z.number().gte(0).lte(100),
    consumedPercentageOfAllocated: z.number().gte(0).lte(100),
    remainingPercentageOfAllocated: z.number().gte(0).lte(100),
});

export type SummaryValues = z.infer<typeof summaryValueParser>;
