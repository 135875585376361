import { useQuery } from '@tanstack/react-query';

import { getFunds } from './api';
import { GetFundsParam, GetFundsRequest, coInnovationFundListResponseParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const queryKey = 'fundsList';

export const useGetFundsQuery = (param: GetFundsParam, filter: GetFundsRequest) => {
    return useQuery([queryKey, param, filter], async () => getFunds(param, filter), {
        suspense: false,
        keepPreviousData: true,
        onSuccess: data => saveToCache(queryKey, data),
        placeholderData: () => retrieveFromCache(queryKey, coInnovationFundListResponseParser.parse),
    });
};
