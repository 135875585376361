import { CoInnovationFilter, createFilterQueryString } from '../../shared';

import {
    coInnovationFundsDetailProjectsParser,
    coInnovationFundsDetailsSingleProjectParser,
    DetailProjects,
    SingleProjectDetails,
} from './domain';

import { agent } from '@shared/api';

const apiRoutes = {
    projectList: '/api/coinnovationfunds/detail/list',
    projectById: '/api/projects/byid',
};

export const getSingleProjectDetails = async (id: string) => {
    const response = await agent.get<SingleProjectDetails>(`${apiRoutes.projectById}/${id}`);
    return response ? coInnovationFundsDetailsSingleProjectParser.parseAsync(response) : null;
};

export const getProjectList = async (
    skip: number,
    take: number,
    filter?: CoInnovationFilter,
    orderBy?: string,
    desc = false
) => {
    const queryString = createFilterQueryString({
        filter,
        sorting: { id: orderBy ? orderBy : '', desc },
        pagination: { skip, take },
    });
    const response = await agent.get<DetailProjects>(`${apiRoutes.projectList}${queryString}`);

    return response ? coInnovationFundsDetailProjectsParser.parseAsync(response) : null;
};
