import { useTranslation } from 'react-i18next';

import { namespaces } from '@shared/constants';
import { formatDateWithHour } from '@shared/formatting';
import { ContactBox } from '@shared/ui';

type FundStatusFlowProps = {
    title: string;
    date: string;
    contactEmail: string;
    contactName: string;
    contactPhoto?: string;
    isPending: boolean;
    isChangeRequestPending: boolean;
    lastItem: boolean | undefined;
};

function FundStatusFlowItem({
    title,
    date,
    contactEmail,
    contactName,
    contactPhoto,
    isPending,
    isChangeRequestPending,
    lastItem,
}: FundStatusFlowProps) {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    return (
        <div className="relative pl-4 pb-4">
            {/* Circle positioned at the start */}
            {!(lastItem && isPending) && (
                <div
                    className="absolute left-0 top-0 h-4 w-4 rounded-full bg-blue-800"
                    style={{ transform: 'translate(-50%, 3px)' }}
                ></div>
            )}
            {lastItem && isPending && (
                <div
                    className="absolute left-0 top-0 h-4 w-4 rounded-full bg-white ring-2 ring-inset ring-blue-800"
                    style={{ transform: 'translate(-50%, 3px)' }}
                ></div>
            )}
            {/* Line between circles */}
            {!(lastItem && !isChangeRequestPending) && (
                <div
                    className="absolute left-0 top-0 bottom-0 w-0.5 bg-blue-800"
                    style={{ transform: 'translateY(3px)' }}
                ></div>
            )}

            <h3 className="mb-[6px] text-left text-base ">{`${t(title)} - ${formatDateWithHour(date)}`}</h3>

            <ContactBox email={contactEmail} name={contactName} photo={contactPhoto} />
        </div>
    );
}

export default FundStatusFlowItem;
