export const formatDate = (value: string | Date) => {
    const validationDate = new Date('01/01/1970');
    const result = new Date(value);
    if (result < validationDate) {
        return 'unknown';
    }
    return result.toLocaleDateString('en-GB');
};

export const formatDateMonthYear = () => {
    const date = new Date();
    const month = date.toLocaleString('en-GB', { month: 'long' });
    const year = date.getFullYear();

    return `${month} ${year}`;
};

export const formatDateWithHour = (value: string | Date) => {
    const date = new Date(value);
    const dateString = date.toLocaleDateString('en-GB'); // Adjust the locale as needed
    const timeString = date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });

    return `${dateString} - ${timeString}`;
};

export const formatUTCPlusOne = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
        timeZoneName: 'shortOffset',
        hour12: false,
    };

    const utcDate = new Date(date.getTime() + 60 * 60 * 1000);

    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
    const parts = dateTimeFormat.formatToParts(utcDate);

    const day = parts.find(part => part.type === 'day')?.value;
    const month = parts.find(part => part.type === 'month')?.value?.toUpperCase();
    const year = parts.find(part => part.type === 'year')?.value;
    const hour = parts.find(part => part.type === 'hour')?.value;
    const minute = parts.find(part => part.type === 'minute')?.value;
    const timeZoneName = '(UTC+01:00)';

    return `${day} ${month} ${year} ${hour}:${minute} ${timeZoneName}`;
};
