import { ReactNode, forwardRef } from 'react';

import classnames from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import SelectRef from 'react-select/dist/declarations/src/Select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

import { InputErrors } from '../inputErrors';
import { Label } from '../label';

const animatedComponents = makeAnimated();

type Props = StateManagerProps & {
    name: string;
    containerClassName?: string;
    label?: ReactNode | undefined;
    errors?: string[];
    dataTestId?: string;
};

const SelectControl = forwardRef<SelectRef, Props>(
    ({ components, className, classNames, containerClassName, label, errors, dataTestId, name, ...rest }) => {
        const { control } = useFormContext();
        const { field } = useController({
            name,
            control,
        });
        return (
            <div data-testid={dataTestId} className={classnames(containerClassName ? containerClassName : 'mb-[30px]')}>
                <Label id={rest.id}>{label}</Label>

                <ReactSelect
                    {...field}
                    {...rest}
                    components={components ? components : animatedComponents}
                    className={classnames(
                        className,
                        // separator
                        '[&_.separator]:bg-blue-900',

                        // dropdown
                        '[&_.dropdownIndicator]:p-[5px] [&_.dropdownIndicator]:text-blue-900',
                        '[&_.dropdownIndicator]:hover:text-blue-900',

                        // control
                        '[&_.control]:min-h-[32px] [&_.control]:border [&_.control]:border-gray-200 [&_.control]:shadow-none',
                        '[&_.control]:hover:border-blue-900',
                        // control when item is selected
                        '[&_.control.hasValue]:border-blue-900',

                        // placeholder
                        '[&_.placeHolder]:[&_.control]:hover:text-blue-900',
                        '[&_.placeHolder]:text-xs [&_.placeHolder]:text-gray-900',

                        // value container
                        '[&_.valueContainer]:py-0',

                        // selected item
                        '[&_.singleValue]:text-xs [&_.singleValue]:text-blue-900',

                        // dropdown menu
                        '[&_.menu]:m-0 [&_.menu]:border-0 [&_.menu]:shadow',
                        '[&_.option]:bg-white [&_.option]:text-xs',

                        // menuList
                        '[&_.menuList]:p-0',

                        // options
                        'hover:[&_.option]:bg-blue-900 hover:[&_.option]:text-white',
                        // selected option
                        '[&_.option.selected]:bg-blue-900 [&_.option.selected]:text-white',

                        // errors
                        {
                            '[&_.separator]:!bg-error [&_.dropdownIndicator]:!text-error [&_.control]:!border-error [&_.control.hasValue]:!border-error':
                                !!errors,
                            '[&_.dropdownIndicator]:hover:!text-error [&_.placeHolder]:!text-error [&_.singleValue]:!text-error':
                                !!errors,
                        }
                    )}
                    classNames={{
                        indicatorSeparator: () => 'separator',
                        dropdownIndicator: () => 'dropdownIndicator',
                        control: ({ hasValue }) => classnames('control', { hasValue }),
                        placeholder: () => 'placeHolder',
                        singleValue: () => 'singleValue',
                        valueContainer: () => 'valueContainer',
                        menu: () => 'menu',
                        option: state => `option${state.isSelected ? ' selected' : ''}`,
                        menuList: () => 'menuList',
                        ...classNames,
                    }}
                />

                <InputErrors errors={errors} />
            </div>
        );
    }
);
SelectControl.displayName = 'Select';

export { SelectControl };
