import { z } from 'zod';

import { IdNameParser, PaginationModel } from '@shared/CMS';

export const InviteParser = z.object({
    id: z.number().min(0),
    name: z.string(),
    url: z.string(),
});

export const eventPageParser = z.object({
    title: z.string().min(1),
    description: z.string().min(1),
});

export const eventListItemParser = z.object({
    id: z.number().min(0),
    title: z.string().min(1),
    description: z.string().min(1),
    type: IdNameParser.optional(),
    startDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    endDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    location: z.string().optional(),
    language: IdNameParser.optional(),
    format: z.string().optional(),
    categories: z.array(IdNameParser),
    eventType: IdNameParser.optional(),
    contact: z.string().optional(),
    venue: z.string().optional(),
    teamsLink: z.string().optional(),
    resourceList: z.array(InviteParser).optional(),
});
export const eventListParser = z.array(eventListItemParser);

export const eventParser = eventListItemParser.extend({
    type: IdNameParser.optional(),
    organizer: z.string().optional(),
});

export type GetEventsParam = PaginationModel & {
    name?: string;
    endDate?: string;
    startDate?: string;
    typeIds?: number[];
    categoryIds?: number[];
    location?: string;
    organizer?: string;
    languageIds?: number[];
    formatIds?: number[];
};

export const eventListResponseParser = z.object({
    total: z.number().min(0),
    page: z.number().min(0),
    pageSize: z.number().min(0),
    items: eventListParser,
});

export type CalendarEvent = {
    id: number;
    title: string;
    location: string;
    language: string;
    format: string;
    eventType: string;
    categories: string[];
    start: Date;
    end: Date;
};

export type Event = z.infer<typeof eventParser>;

export type EventListItem = z.infer<typeof eventListItemParser>;

export type EventList = z.infer<typeof eventListParser>;

export type EventPageResponse = z.infer<typeof eventPageParser>;

export type EventListResponse = z.infer<typeof eventListResponseParser>;
