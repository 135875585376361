import classNames from 'classnames';

type Section = {
    title: string;
    value: string;
    subValue?: string;
    titleTestId: string;
    valueTestId: string;
};

type Props = {
    sections: Section[];
};

export const FundKpis = ({ sections }: Props) => {
    return (
        <div
            className="mb-4 flex w-full flex-wrap gap-3 md:flex-nowrap md:justify-between"
            aria-label="Fund Key Performance Indicators"
        >
            {sections.map((section, index) => (
                <div
                    key={index}
                    className={classNames(
                        'flex flex-grow justify-between rounded-lg border-2 border-blue-100 py-4 px-5 text-left md:flex-auto',
                        {
                            'bg-blue-50': index === 0,
                        }
                    )}
                >
                    <div>
                        <p className="mb-[6px] text-base font-normal" data-testid={section.titleTestId}>
                            {section.title}
                        </p>
                        <p className="text-2xl font-light" data-testid={section.valueTestId}>
                            {section.value}
                        </p>
                        {section.subValue && <p className="text-xs font-normal text-gray-800">{section.subValue}</p>}
                    </div>
                </div>
            ))}
        </div>
    );
};
