import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as ShareIcon } from '@assets/icons/share.svg';
import { namespaces } from '@shared/constants';
import { Button } from '@shared/ui';

const ShareEvent = () => {
    const { t } = useTranslation(namespaces.features.events);

    const [locationCopied, setLocationCopied] = useState(false);
    const handleClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setLocationCopied(true);
        setTimeout(() => {
            alert(t('share.success'));
        }, 1);
        setTimeout(() => {
            setLocationCopied(false);
        }, 3000);
    };

    return (
        <Button
            title="Share"
            aria-label="Share"
            onClick={handleClick}
            className="border-blue-accessible text-blue-accessible flex rounded border-2 px-3 py-2 text-sm font-normal"
            disabled={locationCopied}
        >
            {locationCopied ? (
                <div className="mr-1">&#10004;</div>
            ) : (
                <ShareIcon className="stroke-blue-accessible mr-1" />
            )}
            {locationCopied ? t('share.linkCopied') : t('share.button')}
        </Button>
    );
};

export { ShareEvent };
