import { Dispatch, SetStateAction, Suspense, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { fundStatuses } from './constants';
import { SelectedFundState } from './Funds';
import { FundSingleView } from './FundSingleView';

import { HasPermission, permissions } from '@features/authorization';
import { namespaces } from '@shared/constants';
import { formatDate } from '@shared/formatting';
import { Button, DialogBody, DialogContainer, DialogFooter, DialogHeader, Spinner } from '@shared/ui';

type Props = {
    submissionDate?: string;
    statusId?: number;
    selectedFundId?: string;
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
};

const canCancelFundPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];

const canValidateFundPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

export const FundSingleDialog = ({ setSelectedFundState, selectedFundId, submissionDate, statusId }: Props) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const setShowDialog = useCallback(
        (show: boolean) => {
            if (!show) {
                setSelectedFundState(undefined);
            }
        },
        [setSelectedFundState]
    );

    return (
        <DialogContainer setShowDialog={setShowDialog} dialogPosition={'right'} showDialog={!!selectedFundId}>
            <DialogHeader onCloseButtonClick={() => setSelectedFundState(undefined)}>
                {submissionDate
                    ? `${t('coInnovationFunds.fundsManagement.submitDate')}: ${formatDate(submissionDate)}`
                    : null}
            </DialogHeader>

            <DialogBody>
                <Suspense fallback={<Spinner />}>
                    {selectedFundId ? <FundSingleView fundId={selectedFundId} /> : null}
                </Suspense>
            </DialogBody>

            {statusId === fundStatuses.pending ? (
                <DialogFooter>
                    <HasPermission neededPermissions={canCancelFundPermissions}>
                        <Button
                            variant="primary"
                            className="mr-2"
                            type="button"
                            onClick={() =>
                                setSelectedFundState(previousState => ({
                                    id: selectedFundId || '',
                                    types: previousState ? [...previousState.types, 'cancel'] : ['cancel'],
                                }))
                            }
                        >
                            {t('coInnovationFunds.fundsManagement.cancelation.title')}
                        </Button>
                    </HasPermission>
                    <HasPermission neededPermissions={canValidateFundPermissions}>
                        <Button
                            variant="secondary"
                            className="mr-2"
                            type="button"
                            onClick={() =>
                                setSelectedFundState(previousState => ({
                                    id: selectedFundId || '',
                                    types: previousState ? [...previousState.types, 'reject'] : ['reject'],
                                }))
                            }
                        >
                            {t('coInnovationFunds.validationFundsForm.reject')}
                        </Button>
                    </HasPermission>
                    <HasPermission neededPermissions={canValidateFundPermissions}>
                        <Button
                            variant="primary"
                            type="button"
                            onClick={() =>
                                setSelectedFundState(previousState => ({
                                    id: selectedFundId || '',
                                    types: previousState ? [...previousState.types, 'approve'] : ['approve'],
                                }))
                            }
                        >
                            {t('coInnovationFunds.validationFundsForm.approve')}
                        </Button>
                    </HasPermission>
                </DialogFooter>
            ) : null}
        </DialogContainer>
    );
};
