import { PaginationState } from '@tanstack/react-table';

import { Pagination } from '../pagination';

type Props = {
    pagination: PaginationState;
    totalItems: number;
    paginationCallback: (pagination: PaginationState) => void;
    paginationDetail?: boolean;
};

const TableFooter = (props: Props) => {
    return <Pagination {...props} />;
};

export { TableFooter };
