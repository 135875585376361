import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxFormList, MultiSelectForm } from '../formItems';
import { useGetAllDetailFilters } from '../shared/filtering/useFilterMasterDataQuery';

import { ReactComponent as FaIcon } from '@assets/icons/faIcon.svg';
import { namespaces } from '@shared/constants';
import { Spinner } from '@shared/ui';
import { useStore } from '@store';

const checkIfLoadingWithoutData = (states: Array<{ data: unknown; isFetching: boolean }>) =>
    !!states.find(({ isFetching, data }) => isFetching && !data);

const DetailFilters = () => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const { control } = useFormContext();
    const { field } = useController({
        name: 'projectName',
        control,
    });
    const allQueryStates = useGetAllDetailFilters();

    const filter = useStore(state => state.coInnovationFunds.filter);

    // suspense is not working with multiple queries (useQueries)
    // this takes care of the loading until it's fixed in the @tanstack/react-query library
    if (checkIfLoadingWithoutData(allQueryStates)) {
        return (
            <div className="col-span-2">
                <Spinner />
            </div>
        );
    }

    const [
        { data: horizonFilters },
        { data: globalTeamsFilters },
        { data: phasesFilter },
        { data: partnersFilters },
        { data: teamsFilters },
        { data: yearsFilter },
    ] = allQueryStates;

    const formattedYears = yearsFilter?.map(item => ({
        id: String(item.year),
        name: String(item.year),
    }));

    const formattedPartners =
        partnersFilters?.map(item => ({
            id: item.id,
            name: item.name,
            group: item.parentPartnerName,
        })) || [];

    return (
        <>
            <div className="flex flex-col gap-10 md:flex-row">
                <div className="flex-1">
                    <div className="row-span-2 w-full space-y-6">
                        <div data-testid="filter-year" className="w-full">
                            <h3 className="pb-3">{t('coInnovationFunds.filters.years')}</h3>
                            <MultiSelectForm
                                name="years"
                                items={formattedYears || []}
                                selectedItems={filter.years.map(year => year.toString())}
                            />
                        </div>
                        <div data-testid="filter-partner" className="w-full">
                            <h3 className="pb-3">{t('coInnovationFunds.filters.partners')}</h3>

                            <MultiSelectForm
                                name="partners"
                                items={formattedPartners}
                                selectedItems={filter.partners}
                                includeCheckboxes={true}
                            />
                        </div>

                        <div data-testid="filter-project-name" className="relative mt-4">
                            <h3 className="pb-3">{t('coInnovationFunds.filters.projectName')}</h3>

                            <input
                                placeholder={t('coInnovationFunds.filters.projectPlaceholder')}
                                type="text"
                                {...field}
                                className="w-full rounded border border-blue-500 p-2 focus:border-blue-700 focus:outline-none"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 mt-8 flex items-center pr-2 text-blue-500">
                                <FaIcon className="border-blue-500 text-blue-500"></FaIcon>
                            </div>
                        </div>

                        <div data-testid="filter-team" className="w-full">
                            <h3 className="pb-3">{t('coInnovationFunds.filters.teams')}</h3>

                            <MultiSelectForm name="teams" items={teamsFilters || []} selectedItems={filter.teams} />
                        </div>
                    </div>
                </div>

                <div className="flex-1">
                    <div className="row-span-2 space-y-2 md:space-y-6">
                        <div data-testid="filter-horizon">
                            <h3>{t('coInnovationFunds.filters.horizons')}</h3>

                            <div className="flex flex-wrap gap-3 py-3">
                                <CheckboxFormList
                                    items={horizonFilters || []}
                                    name="horizons"
                                    selectedItems={filter.horizons}
                                />
                            </div>
                        </div>

                        <div data-testid="filter-phase">
                            <h3>{t('coInnovationFunds.filters.phases')}</h3>

                            <div className="flex flex-wrap gap-3 py-3">
                                <CheckboxFormList
                                    items={phasesFilter || []}
                                    name="phases"
                                    selectedItems={filter.phases}
                                />
                            </div>
                        </div>

                        <div data-testid="filter-global-team">
                            <h3>{t('coInnovationFunds.filters.globalTeams')}</h3>

                            <div className="flex flex-wrap gap-3 py-3">
                                <CheckboxFormList
                                    items={globalTeamsFilters || []}
                                    name="globalTeams"
                                    selectedItems={filter.globalTeams}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { DetailFilters };
