import { useMutation } from '@tanstack/react-query';

import { postChangeRequest } from './api';
import { CoInnovationFundChangeRequestParam } from './domain';

type Params = {
    changeRequest: CoInnovationFundChangeRequestParam;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export const useChangeRequestMutation = ({ changeRequest, onSuccess, onError }: Params) =>
    useMutation(async () => postChangeRequest(changeRequest), {
        onSuccess,
        onError: onError ? (error: Error) => onError(error) : undefined,
    });
