import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const setupAppInsights = (connectionString?: string) => {
    const reactPlugin = new ReactPlugin();

    if (connectionString) {
        const appInsights = new ApplicationInsights({
            config: {
                connectionString,
                extensions: [reactPlugin],
                extensionConfig: {},
                enableAutoRouteTracking: true,
                disableAjaxTracking: false,
                autoTrackPageVisitTime: true,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
            },
        });

        appInsights.loadAppInsights();
    }

    return reactPlugin;
};

export { setupAppInsights };
