import { matchPath } from 'react-router-dom';

import { joinPaths } from './joinPaths';

type RouteData = {
    path: string;
    name?: string;
    permission?: string;
    routes?: Record<string, RouteData>;
};

type ExtendedRouteData = RouteData & { parentPath: string };

export const getRouteData = (
    path: string,
    routeDataObject: Record<string, RouteData>,
    parentPath = ''
): ExtendedRouteData | undefined => {
    for (const prop in routeDataObject) {
        const routeData = routeDataObject[prop];
        const routePath = joinPaths([parentPath, routeData!.path]);
        const fixedPath = path.slice(-1) === '/' && path.length > 1 ? path.slice(0, -1) : path;

        if (matchPath(routePath, fixedPath)) {
            return { ...routeData!, parentPath: parentPath };
        }

        if (routeData!.routes) {
            const foundRouteData = getRouteData(fixedPath, routeData!.routes, routePath);

            if (foundRouteData) {
                return foundRouteData;
            }
        }
    }

    return undefined;
};
