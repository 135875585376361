import { useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './PageJumperTooltip.css';

type Props = {
    id: string;
    pageCount: number;
    disabled?: boolean;
    onChange: (page: number) => void;
};

const PageJumper = ({ onChange, pageCount, disabled, id }: Props) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLInputElement>(null);
    const [errorMessage, setErrorMessage] = useState('');

    if (pageCount < 2) {
        return <></>;
    }
    return (
        <div>
            {errorMessage && (
                <div className="message-wrapper">
                    <div className="validation-message-container">
                        <span className="info-icon"></span>
                        <div className="validation-message">{errorMessage}</div>
                    </div>
                </div>
            )}
            <form
                aria-label="form"
                className="inline-flex items-center"
                noValidate
                onSubmit={e => {
                    e.preventDefault();
                    const value = ref.current?.value;
                    if (!(value && Number.isSafeInteger(+value))) {
                        return;
                    }
                    if (value && +value > pageCount) {
                        setErrorMessage(`Value must be less than or equal to ${pageCount}.`);
                        return;
                    }
                    setErrorMessage('');
                    onChange(+value);
                    ref.current.value = '';
                }}
            >
                <label htmlFor={id} className="mr-4 inline-block whitespace-nowrap text-xs text-gray-900">
                    {t('global.goToPage')}
                </label>

                <div
                    className={classNames(
                        'inline-flex',
                        '[&_button.btn-outline]:rounded-none [&_button.btn-outline]:rounded-r [&_button.btn-outline]:border',
                        '[&_button.btn-outline]:p-[8.5px] [&_button.btn-outline]:text-xs'
                    )}
                >
                    <input
                        id={id}
                        min={1}
                        ref={ref}
                        type="number"
                        max={pageCount}
                        disabled={disabled}
                        className="spin-button-none min-w-[60px] rounded-l border p-1 text-sm outline-none"
                    />
                    <button type="submit" className="btn-outline" disabled={disabled}>
                        {t('global.go')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export { PageJumper };
