import { z } from 'zod';

import { MultivalueChart } from '../../shared';

export type MultivalueChartWithPercent = MultivalueChart & {
    percent: number;
};

export const detailByItTeam = z.array(
    z.object({
        teamName: z.string().min(1),
        import: z.number().min(1),
        percent: z.number().min(0),
        projectCount: z.number(),
    })
);

export const coInnovationFundsDetailsByItTeamDashboardSectionParser = z.array(
    z.object({
        teamName: z.string().min(1),
        allocated: z.number().min(0),
        consumedPercent: z.number().min(0).max(100),
        projectCount: z.number(),
    })
);

export type DetailByItTeamItemValue = z.infer<typeof coInnovationFundsDetailsByItTeamDashboardSectionParser>;
