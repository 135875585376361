import { useEffect, useRef, useState } from 'react';

import { CellContext, ColumnMeta } from '@tanstack/react-table';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { InfoTooltip } from '@shared/ui/tooltip';

type Props<T extends object> = {
    text: string;
    context: CellContext<T, unknown>;
    meta?: ColumnMeta<T, unknown>;
    alignContent?: 'right' | 'left';
    warningMessage?: string;
    multiLineData?: string;
};

const useHasOverflow = (ref: React.RefObject<HTMLDivElement>) => {
    const [hasOverflow, setHasOverflow] = useState(false);

    useEffect(() => {
        if (ref.current) {
            setHasOverflow(ref.current.offsetWidth < ref.current.scrollWidth);
        }
    }, [ref]);

    return hasOverflow;
};

const getTooltipContent = (hasOverflow: boolean, text: string, multiLineData?: string) => {
    if (!hasOverflow) {
        return null;
    }
    return (
        <ReactTooltip id={text} className="tooltip" noArrow offset={2}>
            {multiLineData ? `${text} ${multiLineData}` : text}
        </ReactTooltip>
    );
};

const WarningIcon = ({
    meta,
    context,
    warningMessage,
}: {
    meta?: ColumnMeta<any, unknown>;
    context: CellContext<any, unknown>;
    warningMessage?: string;
}) => {
    const hasWarningIcon = !!meta?.getCellProps(context).hasWarningIcon;
    if (!hasWarningIcon) {
        return null;
    }

    const warningMessageMeta = meta?.getCellProps(context).warningMessage;

    return (
        <InfoTooltip
            placement="top"
            className={`pt-[2.5px] pr-2.5 [&_>svg]:h-6 [&_>svg]:w-6 ${
                meta?.getCellProps(context).warningColor ? 'text-warning' : ''
            }`}
            id={context.cell.id}
        >
            <p>{warningMessageMeta || warningMessage || ''}</p>
        </InfoTooltip>
    );
};

const CellContent = ({
    text,
    multiLineData,
    hasOverflow,
}: {
    text: string;
    multiLineData?: string;
    hasOverflow: boolean;
}) => {
    if (multiLineData && !hasOverflow) {
        return (
            <div className="flex flex-col">
                <span>{text}</span>
                <span className="text-xs text-gray-500">{multiLineData}</span>
            </div>
        );
    }
    return <>{text}</>;
};

const CellBase = <T extends object>({ text, meta, context, alignContent, warningMessage, multiLineData }: Props<T>) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const hasOverflow = useHasOverflow(ref);

    const cellClassNames = classNames(
        'flex overflow-hidden text-ellipsis whitespace-nowrap',
        meta?.getCellProps(context).className,
        {
            'hover:underline': hasOverflow,
            'pr-5 text-right': alignContent === 'right',
        },
        meta?.getCellProps(context).warningColor ? 'text-warning' : '',
        alignContent === 'right' ? 'justify-end' : 'justify-start'
    );

    return (
        <>
            <div ref={ref} className={cellClassNames} data-tooltip-id={hasOverflow ? text : undefined}>
                <WarningIcon meta={meta} context={context} warningMessage={warningMessage} />
                {meta?.getCellProps(context).warningAlignment === 'left' ? <div className="flex-grow"></div> : ''}
                <CellContent text={text} multiLineData={multiLineData} hasOverflow={hasOverflow} />
            </div>
            {getTooltipContent(hasOverflow, text, multiLineData)}
        </>
    );
};

export { CellBase };
