export const checkIfBetweenTwoDates = (startDate: Date, endDate: Date, targetDate: Date) =>
    targetDate.getTime() <= endDate.getTime() && targetDate.getTime() >= startDate.getTime();

export const convertToUTCWithoutTime = (date: Date): Date =>
    new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

export const convertToSimpleUTCStringWithoutTime = (date: Date): string => {
    const utc = convertToUTCWithoutTime(date);
    return `${utc.getFullYear()}-${('0' + (utc.getMonth() + 1)).slice(-2)}-${('0' + utc.getDate()).slice(-2)}`;
};

const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];
export const getMonth = (monthNumber: number) => months[monthNumber];
