import { useTranslation } from 'react-i18next';

import { ReactComponent as NoData } from '@assets/images/noData.svg';
import { ReactComponent as NoFilteredData } from '@assets/images/noFilteredData.svg';

type Props = {
    isFiltered?: boolean;
};

const NoDataContent = ({ isFiltered }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="flex h-full w-full flex-col items-center justify-center gap-4" data-testid="noDataContainer">
            {isFiltered ? <NoFilteredData /> : <NoData />}
            <span className="block text-xs font-light leading-tight">
                {isFiltered ? t('errors.noFilteredData') : t('errors.noData')}
            </span>
        </div>
    );
};

export { NoDataContent };
