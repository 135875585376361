import { Dispatch, SetStateAction, useCallback } from 'react';

import { PrioritiesMobileDetails } from './PrioritiesMobileDetails';

import { ReactComponent as ChevronDown } from '@assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from '@assets/icons/chevronUp.svg';
import { Spinner } from '@shared/ui';

type Props = {
    partners: { name: string; id: string }[] | null | undefined;
    selectedPartner: string | null;
    setSelectedPartner: Dispatch<SetStateAction<string | null>>;
};

export const PrioritiesMobileTable = ({ partners, selectedPartner, setSelectedPartner }: Props) => {
    const toggleSelectedPartner = useCallback(
        (partnerId: string) => {
            if (selectedPartner === partnerId) {
                setSelectedPartner(null);
            } else {
                setSelectedPartner(partnerId);
            }
        },
        [selectedPartner, setSelectedPartner]
    );

    const SpinnerWrapper = () => (
        <div className="mt-8 flex w-full justify-center">
            <Spinner />
        </div>
    );
    return (
        <div className="mt-2 flex w-full flex-col border-2 border-blue-100">
            {!partners ? (
                <>
                    <SpinnerWrapper />
                </>
            ) : (
                <>
                    {partners!.map(x => (
                        <div
                            className="cursor-pointer border border-blue-100"
                            key={x.id}
                            onClick={() => toggleSelectedPartner(x.id)}
                        >
                            {x.id == selectedPartner ? (
                                <div className="flex flex-col ">
                                    <div className="text-lg flex flex-row justify-between bg-blue-100 py-3 px-2">
                                        <div className=""> {x.name}</div>
                                        <ChevronUp />
                                    </div>
                                    <PrioritiesMobileDetails partnerId={selectedPartner} />
                                </div>
                            ) : (
                                <div className="text-lg flex flex-row justify-between py-3  px-2">
                                    <span> {x.name}</span>
                                    <ChevronDown />
                                </div>
                            )}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};
