import { NavigationClient, NavigationOptions } from '@azure/msal-browser';
import { NavigateFunction } from 'react-router-dom';

export class CustomNavigationClient extends NavigationClient {
    navigate: NavigateFunction;
    constructor(navigate: NavigateFunction) {
        super();
        this.navigate = navigate;
    }

    navigateInternal(url: string, options: NavigationOptions) {
        const relativePath = url.replace(window.location.origin, '');
        if (options.noHistory) {
            this.navigate(relativePath, { replace: true });
        } else {
            this.navigate(relativePath);
        }

        return Promise.resolve(false);
    }
}
