import { Chart, LegendItem, Plugin } from 'chart.js';

import { createChartColors, createLegendIcon, createLegendText, HtmlLegend, LegendOptions } from '../charts';

import {
    CoInnovationFilter,
    DetailByGlobalTeam,
    getGlobalTeamNames,
    SummaryType,
    toValueType,
} from '@features/coInnovationFunds/shared';
import { formatNumber, FormatNumberOptions } from '@shared/formatting';

export const formatDetailByGlobalTeamData = (
    detailByGlobalTeamData: DetailByGlobalTeam,
    summaryType: SummaryType,
    unitText: string
) => {
    const colors = createChartColors();
    return detailByGlobalTeamData?.map((detailByGlobalTeam, i) => {
        const formatNumberOptions: FormatNumberOptions = {
            valueType: toValueType(summaryType),
            simplify: false,
            unitText,
        };
        return {
            text: `${detailByGlobalTeam.percent}%`,
            tooltip: `${formatNumber(detailByGlobalTeam.import, formatNumberOptions)} (${detailByGlobalTeam.percent}%)`,
            value: detailByGlobalTeam.percent,
            color: colors[i] as string,
            legend: detailByGlobalTeam.teamName,
        };
    });
};

const legendOptions: LegendOptions = {
    display: false,
};

const htmlLegend: HtmlLegend = {
    containerID: 'legendContainer',
};

const htmlLegendPlugin: (
    legendId: string,
    containerId: string,
    applyFilterCallback: (data: CoInnovationFilter) => void,
    filter: CoInnovationFilter
) => Plugin = (legendId, containerId, applyFilterCallback, filter) => ({
    id: 'legendContainer',
    afterUpdate(chart: Chart<'doughnut'>) {
        const legendContainer = document.getElementById(legendId);
        let listContainer = legendContainer?.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            legendContainer?.appendChild(listContainer);
            listContainer.id = containerId;
        }

        const ul = listContainer;
        while (ul.firstChild) {
            ul.firstChild.remove();
        }
        ul.className = 'mt-10 flex flex-col items-start justify-center w-max mx-auto';

        const labels = chart.options.plugins?.legend?.labels;
        const generatedLabels = labels?.generateLabels !== undefined ? labels.generateLabels(chart) : null;
        generatedLabels?.map((label: LegendItem) => {
            const li = document.createElement('li');
            li.className = 'flex items-center cursor-pointer ml-3 mb-4';

            li.onclick = () => {
                const globalTeam = label.text.split(';')[0];
                if (globalTeam !== undefined) {
                    applyGlobalTeamFilter(globalTeam, filter, applyFilterCallback);
                }
            };

            const legendIconDiv = createLegendIcon(label);
            const textContainer = createLegendText(label);

            li.appendChild(legendIconDiv);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    },
});

const applyGlobalTeamFilter = (
    globalTeamName: string,
    filter: CoInnovationFilter,
    applyFilterCallback: (data: CoInnovationFilter) => void
) => {
    switch (globalTeamName) {
        case getGlobalTeamNames().TechStream:
            filter = { ...filter, globalTeams: [0] };
            break;
        case getGlobalTeamNames().FunctionalTechStream:
            filter = { ...filter, globalTeams: [1] };
            break;
        case getGlobalTeamNames().OCIO:
            filter = { ...filter, globalTeams: [2] };
            break;
        case getGlobalTeamNames().RegionMarkets:
            filter = { ...filter, globalTeams: [3] };
            break;
        case getGlobalTeamNames().Unknown:
            filter = { ...filter, globalTeams: [-1] };
            break;
    }
    applyFilterCallback(filter);
};

export { legendOptions, htmlLegend, htmlLegendPlugin };
