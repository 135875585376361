import { CoInnovationFilter, createFilterQueryString } from '../../../shared';

import { summaryValueParser, SummaryValues } from './domain';

import { agent } from '@shared/api';

const apiRoutes = {
    summaryMoney: '/api/coinnovationfunds/money',
};

export const getMoney = async (filter?: CoInnovationFilter, isDownloading = false) => {
    const queryString = createFilterQueryString({ filter, isDownloading });
    const response = await agent.get<SummaryValues>(`${apiRoutes.summaryMoney}${queryString}`);
    return response ? summaryValueParser.parseAsync(response) : null;
};
