import { FundsCharts } from '../../charts';
import { ByPartnerTable } from '../ByPartnerTable';

import { SummaryType } from '@features/coInnovationFunds/shared';

const ByPartnerTab = () => {
    return (
        <div className="flex h-full w-full flex-col lg:grid lg:grid-cols-3 lg:flex-col lg:gap-x-6 ">
            <FundsCharts summaryType={SummaryType.Money} />

            <div className="w-full sm:col-span-2">
                <ByPartnerTable showSubPartners={false} />
            </div>
        </div>
    );
};

export { ByPartnerTab };
