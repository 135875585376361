import { emptyFilterValue } from './domain';

import { DownloadContentType, useDownloadContext } from '@shared/ui/download';
import { useStore } from '@store';

export const useFilter = () => {
    const actualFilter = useStore(state => state.coInnovationFunds.filter);
    const context = useDownloadContext();

    return {
        isDownloading: !!context,
        filter: context?.content === DownloadContentType.All ? emptyFilterValue : actualFilter,
    };
};
