import { PropsWithChildren } from 'react';

import { useIsAuthenticated } from '@azure/msal-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

import { useResizeMediaQuery } from '../useResizeMediaQuery';

import { ReactComponent as Logo } from '@assets/images/nestleItLogo.svg';

const Header = ({ children }: PropsWithChildren) => {
    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();
    const { isMobile } = useResizeMediaQuery();

    return (
        <header
            className={classNames(
                'fixed left-0 top-0 z-[100] flex min-h-[72px] w-full items-center bg-white px-4 md:py-2 [&_.container]:px-0 md:[&_.container.container]:px-6',
                {
                    'shadow lg:bg-white': isAuthenticated,
                    'lg:bg-transparent': !isAuthenticated,
                }
            )}
            id="mainHeader"
        >
            <div className={classNames('flex items-center', isAuthenticated ? 'container' : 'md:pl-6')}>
                <div className="relative z-20 mr-4 flex-shrink [&_svg]:max-w-full">
                    <h1 className="sr-only">{t('brand.companyName')}</h1>
                    <div
                        className={classNames('flex items-center', {
                            '[&_svg_path]:fill-white ': !isAuthenticated && !isMobile,
                        })}
                    >
                        <HashLink to="/#" className="inline-block" data-testid="logo-link">
                            <Logo />
                        </HashLink>
                    </div>
                </div>

                <div className="ml-auto">{children}</div>
            </div>
        </header>
    );
};

export { Header };
