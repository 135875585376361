import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake.min';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

export type CanvasImageProps = {
    image: string;
};

export enum DownloadContentType {
    All = 0,
    Filtered = 1,
}

export enum DownloadFormatType {
    PDF = 0,
}

export type DownloadFormState = {
    content: DownloadContentType;
    format: DownloadFormatType;
};

export const createImages = async (elementsToPrint: string[]) =>
    elementsToPrint.map(async elementId => {
        const element = document.querySelector(`#${elementId}`) as HTMLElement;
        const canvas = await html2canvas(element, {
            removeContainer: true,
            scale: 3,
        });

        return {
            image: canvas.toDataURL('image/jpg'),
            width: 1920,
        };
    });

export type DocumentProps = {
    imageDataURLs: CanvasImageProps[];
    landscape: boolean;
};

export const createDocument = ({ landscape, imageDataURLs }: DocumentProps) => {
    const documentDefinition: TDocumentDefinitions = {
        pageSize: getPageSize(landscape),
        pageOrientation: landscape ? 'landscape' : 'portrait',
        content: imageDataURLs,
        pageMargins: 0,
    };

    return pdfMake.createPdf(documentDefinition);
};

const getPageSize = (landscape: boolean) => (landscape ? { width: 1920, height: 1180 } : { width: 1080, height: 1920 });
