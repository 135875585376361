import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useResizeMediaQuery } from '@features/layout';
import { namespaces } from '@shared/constants';

interface ReportStatusStepProps {
    currentStep: number;
}

const ReportStatusStep: FC<ReportStatusStepProps> = ({ currentStep }) => {
    const steps = ['', 'conceptValidation', 'pilot', 'industrialization', 'productionAtScale'];
    const { isMobile } = useResizeMediaQuery();
    const { t } = useTranslation(namespaces.features.genai);

    return (
        <div className="flex w-full items-center justify-between">
            {steps.map((step, index) => (
                <>
                    {index !== 0 && (
                        <div key={index} className="flex flex-1 flex-col items-center gap-5">
                            <span className="mb-2 h-4 text-[10px] sm:pl-1 sm:text-sm ">
                                {isMobile ? t(`reportStepper.mobile.${step}`) : t(`reportStepper.${step}`)}
                            </span>
                            <div className="flex w-full items-center">
                                {index === 1 ? (
                                    <div className="flex-grow" />
                                ) : (
                                    <div
                                        className={`bg-blue-accessible flex-grow ${
                                            index <= currentStep ? 'h-1' : 'h-px'
                                        }`}
                                    ></div>
                                )}
                                <div
                                    className={`flex h-4 w-4 items-center justify-center rounded-full ${
                                        index <= currentStep
                                            ? 'bg-blue-accessible'
                                            : 'border-blue-accessible border bg-white'
                                    }`}
                                >
                                    {index < currentStep && <div className="texst-xs p-1 text-white">&#10003;</div>}
                                    {index === currentStep && <div className="h-2 w-2 rounded-full bg-white"></div>}
                                </div>
                                {index === steps.length - 1 ? (
                                    <div className="flex-grow" />
                                ) : (
                                    <div
                                        className={`bg-blue-accessible flex-grow ${
                                            index < currentStep ? 'h-1' : 'h-px'
                                        }`}
                                    ></div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            ))}
        </div>
    );
};

export { ReportStatusStep };
