import { useTranslation } from 'react-i18next';

import { MultiSelectForm } from '../formItems';
import { usePartnersDataQuery } from '../shared/filtering/useFilterMasterDataQuery';

import { fundStatuses } from './constants';
import { getFundStatusTranslationKey } from './getFundStatusTranslationKey';
import { useValidatorsQuery } from './useValidatorsQuery';

import { namespaces } from '@shared/constants';
import { StyledDatePickerControl } from '@shared/form/datePickerControl/DatePickerControl';
import { SelectControl } from '@shared/form/selectControl/SelectControl';
import { useStore } from '@store';

export const FundFilterForm = () => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { filter } = useStore(state => state.coInnovationFunds);
    const { data: validatorsData } = useValidatorsQuery();
    const { data: partnersData } = usePartnersDataQuery(true, false);

    const formattedPartners =
        partnersData?.map(item => ({
            id: item.id,
            name: item.name,
            group: item.parentPartnerName,
        })) || [];

    return (
        <>
            <h3 className="pb-3">{t('coInnovationFunds.fundsManagement.filters.submitDate')}</h3>
            <div className="flex [&>div]:grow">
                <div className="mr-2">
                    <StyledDatePickerControl
                        dateFormat="dd/MM/yyyy"
                        id="fromField"
                        label={t('coInnovationFunds.fundsManagement.filters.fromDateLabel')}
                        name="from"
                        selected={filter?.from ? new Date(filter.from) : null}
                    />
                </div>

                <StyledDatePickerControl
                    dateFormat="dd/MM/yyyy"
                    id="toField"
                    label={t('coInnovationFunds.fundsManagement.filters.toDateLabel')}
                    name="to"
                    selected={filter?.to ? new Date(filter.to) : null}
                />
            </div>

            <div className=" mb-[30px]">
                <div className=" mb-3">
                    <label>{t('coInnovationFunds.fundsManagement.partner')}</label>
                </div>
                <MultiSelectForm
                    name="partners"
                    items={formattedPartners}
                    selectedItems={filter.partners}
                    includeCheckboxes={true}
                />
            </div>

            <SelectControl
                label={t('coInnovationFunds.fundsManagement.approver')}
                isMulti
                name="approvers"
                id="approvers"
                options={validatorsData?.items.map(({ id, name }) => ({ value: id, label: name }))}
                isDisabled={!validatorsData}
                menuPosition="fixed"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
            />

            <SelectControl
                label={t('coInnovationFunds.fundsManagement.status')}
                isMulti
                name="statuses"
                id="statuses"
                options={Object.keys(fundStatuses).map(key => {
                    const id = fundStatuses[key as keyof typeof fundStatuses];
                    return {
                        value: id,
                        label: t(getFundStatusTranslationKey(id)),
                    };
                })}
                menuPosition="fixed"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
            />
        </>
    );
};
