import { permissions } from '@features/authorization';

export const routeDataObject = {
    landing: { name: 'landing page', path: '/' },
    blog: {
        name: 'Blog page',
        path: '/get-inspired',
        routes: {
            single: { name: 'Single blog page', path: ':blogUrl' },
        },
    },
    report: {
        name: 'GenAI Use Cases',
        path: '/genai-use-cases',
    },
    reportDetail: {
        name: 'GenAI detail page',
        path: 'genai-use-cases/:genAiId',
    },
    events: {
        name: 'Events page',
        path: 'events',
    },
    eventDetail: {
        name: 'Event detail page',
        path: 'events/:eventId',
    },
    coInnovationFunds: {
        name: 'Co-innovation summary page',
        path: '/co-innovation-funds',
        routes: {
            details: { name: 'Co-innovation details page', path: 'details' },
            fundsManagement: { name: 'Co-innovation funds-management log page', path: 'funds-management' },
            fundsManagementDetail: {
                name: 'Co-innovation funds-management detail page',
                path: 'funds-management/:fundId',
            },
            priorities: {
                name: 'Co-innovation priorities page',
                path: 'priorities',
            },
            requests: {
                name: 'Co-innovation requests page',
                path: 'requests',
                permission: permissions.coInnovationFunds.allReports.view,
            },
        },
    },
    innovationEcosystem: { name: 'Innovation ecosystem page', path: '/innovation-ecosystem' },
};
