import produce from 'immer';
import { StateCreator } from 'zustand';

export type Slice = {
    serviceNow: {
        createUrl: string;
        supportUrl: string;
        setCreateUrl: (url: string) => void;
        setSupportUrl: (url: string) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    serviceNow: {
        createUrl: '',
        setCreateUrl: (url: string) =>
            set(state =>
                produce(state, draft => {
                    draft.serviceNow.createUrl = url;
                })
            ),
        supportUrl: '',
        setSupportUrl: (url: string) =>
            set(state =>
                produce(state, draft => {
                    draft.serviceNow.supportUrl = url;
                })
            ),
    },
});
