import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../formatting';
import { CellProps } from '../domain';

import { CellBase } from './base';

const DateCell = <T extends object>({ value, context, meta, alignContent }: CellProps<T>) => {
    const { t } = useTranslation();

    return <CellBase text={t(formatDate(value))} context={context} meta={meta} alignContent={alignContent} />;
};

export { DateCell };
