import { PropsWithChildren } from 'react';

type Props = {
    id?: string;
};

const Label = ({ children, id }: PropsWithChildren<Props>) => {
    if (!children || !id) {
        return null;
    }

    return (
        <label htmlFor={id} className="pointer-events-none mb-3 block text-base font-medium text-gray-900">
            {children}
        </label>
    );
};

export { Label };
