import { z } from 'zod';

const envVariables = z.object({
    VITE_CLIENT_ID: z.union([z.string(), z.undefined()]),
    VITE_AUTHORITY: z.union([z.string(), z.undefined()]),
    VITE_REDIRECT_URI: z.union([z.string(), z.undefined()]),
    VITE_POST_LOGOUT_REDIRECT_URI: z.union([z.string(), z.undefined()]),
    VITE_BFF_URI: z.union([z.string(), z.undefined()]),
});

envVariables.parse(import.meta.env);

declare global {
    /* eslint-disable @typescript-eslint/no-empty-interface */
    interface ImportMetaEnv extends z.infer<typeof envVariables> {}
}
