import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { getByHorizon } from './api';
import {
    coInnovationFundsDetailsByHorizonDashboardSectionParser,
    formatData,
    MultivalueChartWithLegendColor,
} from './domain';

import { anyFilter, getTranslationKeyForUnitsOf, SummaryType } from '@features/coInnovationFunds/shared';
import { useFilter } from '@features/coInnovationFunds/shared/filtering/useFilter';
import { retrieveFromCache, saveToCache } from '@shared/caching';
import { namespaces } from '@shared/constants';

const queryKey = 'detailByHorizon';

export const useDetailByHorizonQuery = () => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { filter, isDownloading } = useFilter();

    const query = useQuery<MultivalueChartWithLegendColor[] | null>(
        [queryKey, filter, { isDownloading }],
        async () => {
            const response = await getByHorizon(filter, isDownloading);

            if (!isDownloading && !anyFilter(filter)) {
                saveToCache(queryKey, response);
            }

            return response != null
                ? formatData(response, SummaryType.Money, t(getTranslationKeyForUnitsOf(SummaryType.Money)))
                : null;
        },
        {
            placeholderData: () => {
                if (!isDownloading && !anyFilter(filter)) {
                    const cacheResponse = retrieveFromCache(
                        queryKey,
                        coInnovationFundsDetailsByHorizonDashboardSectionParser.parse
                    );

                    if (cacheResponse) {
                        return formatData(
                            cacheResponse,
                            SummaryType.Money,
                            t(getTranslationKeyForUnitsOf(SummaryType.Money))
                        );
                    }
                }

                return undefined;
            },
        }
    );

    return { query, filter };
};
