type FundDetailSectionProps = {
    title: string;
    children: React.ReactNode;
};

export const FundDetailSection = ({ title, children }: FundDetailSectionProps) => {
    return (
        <div className=" flex-1 rounded-lg border border-blue-100 p-4 text-center md:border-l">
            <div className="flex max-h-[65vh] flex-col gap-6">
                <h3 className="border-b border-blue-100 p-5 text-left text-xl">{title}</h3>
                {children}
            </div>
        </div>
    );
};
