import { PropsWithChildren } from 'react';

import { ColumnSort } from '@tanstack/react-table';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SortingArrows } from '@assets/icons/sortingArrows.svg';

type Props = {
    id: string;
    sortingCallback: (sorting: ColumnSort) => void;
    sorting?: ColumnSort;
    alignContent: 'right' | 'left';
};

const Sorting = ({ id, children, sortingCallback, sorting, alignContent }: PropsWithChildren<Props>) => {
    const { t: commonT } = useTranslation();
    const isSortingActive = sorting?.id === id;
    const desc = isSortingActive ? sorting.desc : true;

    return (
        <button
            title={commonT('sorting.button')}
            onClick={() => sortingCallback({ id: id, desc: !desc })}
            data-testid="sorting-button"
            className={classNames('group flex w-full items-center ', {
                'justify-between': alignContent === 'left',
                'justify-end': alignContent === 'right',
            })}
        >
            <span style={{ textAlign: 'right' }}>{children}</span>
            <i
                className={classNames('text-gray-400 group-active:text-gray-900', {
                    'first:[&_svg_path]:fill-accessible-hover': isSortingActive && !desc,
                    'last:[&_svg_path]:fill-accessible-hover': isSortingActive && desc,
                })}
                id="sorting-icon"
                data-html2canvas-ignore
            >
                <SortingArrows data-testid="sorting-arrows" />
            </i>
        </button>
    );
};

export { Sorting };
