import { Dispatch, SetStateAction } from 'react';

import { ChangeRequestValidationDialog } from '../summary/funds/ChangeRequestValidationDialog';

import { CoInnovationFund } from './domain';
import { FundChangeRequestCancelDialog } from './FundChangeRequestCancelDialog';
import { SelectedFundState } from './Funds';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';

const canCancelFundPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];
const canValidateChangeRequestPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

type FundChangeRequestDialogsProps = {
    selectedFundState: SelectedFundState | undefined;
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
    data: CoInnovationFund | undefined;
    onClose: () => void;
};

export const FundChangeRequestDetailDialog = ({
    setSelectedFundState,
    selectedFundState,
    data,
    onClose,
}: FundChangeRequestDialogsProps) => {
    return (
        <>
            <HasPermission neededPermissions={canCancelFundPermissions}>
                <FundChangeRequestCancelDialog
                    setSelectedFundState={setSelectedFundState} // Ensure setSelectedFundState is passed down correctly
                    selectedFund={selectedFundState?.types.includes('cancelCR') ? data : undefined}
                />
            </HasPermission>
            <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                <ChangeRequestValidationDialog
                    isOpen={!!selectedFundState?.types.find(type => ['approveCR', 'rejectCR'].includes(type))}
                    id={selectedFundState?.id || ''}
                    modalType={selectedFundState?.types.includes('approveCR') ? 'approve' : 'reject'}
                    selectedFund={data}
                    onClose={onClose}
                />
            </HasPermission>
        </>
    );
};
