import { Conjunction } from './domain';

export const checkPermission = (
    permissions?: string[],
    neededPermissions?: string[],
    logicalConnective: Conjunction = 'Disjunction'
) => {
    if (!permissions || permissions.length === 0) {
        return false;
    }

    if (!neededPermissions || neededPermissions.length === 0) {
        return true;
    }

    const logicalConnectives: Record<Conjunction, () => boolean> = {
        Disjunction: () => neededPermissions.some(permission => permissions.includes(permission)),
        Conjunction: () => neededPermissions.every(permission => permissions.includes(permission)),
    };

    return logicalConnectives[logicalConnective]();
};
