import { PropsWithChildren, Suspense } from 'react';

import { useIsAuthenticated } from '@azure/msal-react';
import { ErrorBoundary } from 'react-error-boundary';

import { useExecuteAuthenticationFlow } from '@features/authentication';
import { Header, GlobalLoader, Navigation } from '@features/layout';

const Layout = ({ children }: PropsWithChildren) => {
    const isAuthenticated = useIsAuthenticated();

    useExecuteAuthenticationFlow();

    return (
        <div className="flex min-h-screen flex-col">
            <GlobalLoader />
            <Header>
                {isAuthenticated ? (
                    <ErrorBoundary fallback={<></>}>
                        <Suspense fallback={<></>}>
                            <Navigation />
                        </Suspense>
                    </ErrorBoundary>
                ) : null}
            </Header>
            <main className="flex-grow">{children}</main>
        </div>
    );
};

export { Layout };
