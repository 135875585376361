import { useEffect } from 'react';

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { useStore } from '@store';

export const useExecuteAuthenticationFlow = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const dispatchActiveAccount = useStore(state => state.auth.dispatchActiveAccount);
    const dispatchAccessToken = useStore(state => state.auth.dispatchAccessToken);
    const authRequest = useStore(state => state.auth.authRequest);

    useEffect(() => {
        const executeTokenAdquisition = async () =>
            await instance
                .acquireTokenSilent({ account: instance.getActiveAccount()!, scopes: authRequest.scopes })
                .then(result => {
                    dispatchActiveAccount(result.account!);
                    dispatchAccessToken(result.accessToken!);
                })
                .catch(error => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.loginRedirect(authRequest);
                    } else {
                        console.warn(`Unable to adquire the token. Reason: ${error}`);
                    }
                });

        if (isAuthenticated) {
            executeTokenAdquisition();
        }
    }, [isAuthenticated]);

    return;
};
