import { PagerProps } from '../ui/pagination';
import { SortingProps } from '../ui/sorting';

export function queryStringComposer() {
    const currentQueryString: string[] = [];
    return {
        addQueryStringValueFor: function <T>(queryParameterName: string, elements?: T[]) {
            if (elements && elements.length > 0 && queryParameterName) {
                let queryParameters;
                if (Array.isArray(elements[0])) {
                    queryParameters = elements
                        .map((value, index) => `filters[$or][${index}][${queryParameterName}][id][$eq]=${value}`)
                        .join('&');
                } else {
                    queryParameters = elements.map(value => `${queryParameterName}=${value}`).join('&');
                }
                currentQueryString.push(queryParameters);
            }

            return this;
        },
        addQueryStringSortingFor: function (sorting?: SortingProps) {
            sorting && sorting.id && currentQueryString.push(`orderBy=${sorting.id}&orderDescendant=${sorting.desc}`);

            return this;
        },
        addQueryStringPaginationFor: function (pagination?: PagerProps) {
            pagination && currentQueryString.push(`skip=${pagination.skip}&pageSize=${pagination.take}`);

            return this;
        },
        compose: function () {
            const queryParameters = currentQueryString.join('&');
            return queryParameters ? `?${queryParameters}` : '';
        },
    };
}
