import { PropsWithChildren } from 'react';

import { Button } from '../button';

import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';

type Props = {
    onCloseButtonClick: () => void;
};

const DialogHeader = ({ children, onCloseButtonClick }: PropsWithChildren<Props>) => {
    return (
        <header
            className="flex justify-between border-b border-blue-100 p-5"
            id="header"
            data-testid="dialog-header"
            aria-label="Dialog Title"
        >
            <h2 className="text-xl">{children}</h2>

            <Button className="m-1" onClick={onCloseButtonClick} data-testid="closeDialog" aria-label="Close Dialog">
                <i className="text-accessible-default hover:text-accessible-hover">
                    <CloseIcon className="h-5 w-5" />
                </i>
            </Button>
        </header>
    );
};

export { DialogHeader };
