import { useTranslation } from 'react-i18next';

import { MultiSelectForm } from '../formItems';
import { usePartnersDataQuery, useYearsDataQuery } from '../shared/filtering/useFilterMasterDataQuery';

import { namespaces } from '@shared/constants';
import { useStore } from '@store';

const SummaryFilters = () => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { filter } = useStore(state => state.coInnovationFunds);

    const { data: partners } = usePartnersDataQuery(true);
    const { data: years } = useYearsDataQuery(true);

    const formattedYears =
        years?.map(item => ({
            id: item.year.toString(),
            name: item.year.toString(),
            group: undefined,
        })) || [];

    const formattedPartners =
        partners?.map(item => ({
            id: item.id,
            name: item.name,
            group: item.parentPartnerName,
        })) || [];

    return (
        <div className={'col-span-2 grid gap-3'}>
            <div data-testid="filter-year" className="w-full pb-3">
                <h3 className="pb-3">{t('coInnovationFunds.filters.years')}</h3>
                <MultiSelectForm
                    name="years"
                    items={formattedYears}
                    selectedItems={filter.years.map(year => year.toString())}
                />
            </div>
            <div data-testid="filter-partner" className="w-full">
                <h3 className="pb-3">{t('coInnovationFunds.filters.partners')}</h3>
                <MultiSelectForm
                    name="partners"
                    items={formattedPartners}
                    selectedItems={filter.partners}
                    includeCheckboxes={true}
                />
            </div>
        </div>
    );
};

export { SummaryFilters };
