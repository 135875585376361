import { ChangeEvent, useEffect, useState } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { CheckboxTag } from '@shared/ui';

type Props = {
    items: { id: number; name: string }[];
    selectedItems?: number[];
    name: string;
};

const CheckboxFormList = ({ items, selectedItems, name }: Props) => {
    const [values, setValues] = useState<number[]>(selectedItems || []);

    const { control } = useFormContext();
    const { field } = useController({
        name: name,
        control,
    });

    useEffect(() => {
        field.onChange(selectedItems);
    }, [selectedItems]);

    const onChangeCallback = (event: ChangeEvent<HTMLInputElement>) => {
        const valueCopy = [...values];

        if (event.target.checked) {
            valueCopy.push(parseInt(event.target.value));
        } else {
            const objWithIdIndex = valueCopy.findIndex(obj => obj === parseInt(event.target.value));

            if (objWithIdIndex > -1) {
                valueCopy.splice(objWithIdIndex, 1);
            }
        }

        field.onChange(valueCopy);
        setValues(valueCopy);
    };

    return (
        <>
            {items.map((item: { id: number; name: string }, index: number) => {
                return (
                    <CheckboxTag
                        key={index}
                        label={item.name}
                        id={`${name}${item.name}`}
                        onChangeCallback={onChangeCallback}
                        value={item.id}
                        isDefaultChecked={values.some(s => s === item.id)}
                    />
                );
            })}
        </>
    );
};

export { CheckboxFormList };
