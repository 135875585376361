import { useQuery } from '@tanstack/react-query';

import { getSingleProjectDetails } from './api';
import { SingleProjectDetails, coInnovationFundsDetailsSingleProjectParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const queryKey = 'singleProjectsDetails';

export const useSingleProjectDetailsQuery = (id: string) => {
    return useQuery<SingleProjectDetails | null>(
        [queryKey, { id }],
        async () => {
            const response = await getSingleProjectDetails(id);
            saveToCache(`${queryKey}-${id}`, response);
            return response;
        },
        {
            placeholderData: () =>
                retrieveFromCache(`${queryKey}-${id}`, coInnovationFundsDetailsSingleProjectParser.parse),
        }
    );
};
