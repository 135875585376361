import { useId } from 'react';

import classNames from 'classnames';
import { Doughnut } from 'react-chartjs-2';
import { Trans, useTranslation } from 'react-i18next';

import { getChartData, getChartOptions } from '../charts';

import { htmlLegend, htmlLegendPlugin, legendOptions } from './chartConfiguration';
import { useDetailByHorizonQuery } from './useDetailByHorizonQuery';

import { CoInnovationFilter, anyFilter } from '@features/coInnovationFunds/shared';
import { namespaces } from '@shared/constants';
import { NoDataContent } from '@shared/ui';
import { useDownloadContext } from '@shared/ui/download';

type Props = {
    applyFilterCallback?: (data: CoInnovationFilter) => void;
};

const ByHorizonSection = ({ applyFilterCallback }: Props) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const {
        query: { data: detailByHorizonData },
        filter,
    } = useDetailByHorizonQuery();
    const isFiltered = anyFilter(filter);
    const isDownloading = !!useDownloadContext();

    const uniqueId = useId();
    const legendId = `chartByHorizonLegend${uniqueId}`;
    const legendListId = `chartByHorizonLi${uniqueId}`;

    return (
        <div className="h-[calc(100%-67px)] p-5">
            {detailByHorizonData ? (
                <div
                    id="chartByHorizonContainer"
                    className={classNames(
                        'flex h-full items-center justify-center',
                        isDownloading ? 'mx-auto max-w-[300px] flex-row border-0' : 'flex-col'
                    )}
                >
                    <Doughnut
                        data={getChartData(detailByHorizonData)}
                        options={getChartOptions({
                            data: detailByHorizonData,
                            legend: legendOptions,
                            htmlLegend,
                            showDataLabels: isDownloading,
                            disableAnimation: isDownloading,
                            titleTransform: label => label.split(';')[0] || '',
                        })}
                        id="chartByHorizon"
                        className="mx-auto my-0"
                        plugins={[
                            htmlLegendPlugin(detailByHorizonData, legendId, legendListId, filter, applyFilterCallback),
                        ]}
                        redraw={true}
                    />
                    <div className="flex-col">
                        <div id={legendId}></div>
                        {isDownloading ? (
                            <div>
                                <div className="col-span-2 mx-3 flex-col bg-gray-100 p-4 text-2xs">
                                    <p>
                                        <Trans
                                            t={t}
                                            i18nKey="coInnovationFunds.detail.dashboard.byHorizon.description"
                                        />
                                    </p>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <NoDataContent isFiltered={isFiltered} />
            )}
        </div>
    );
};

export { ByHorizonSection };
